<template>
  <div>
    <v-toolbar
      dense
      @click="SET_value({ list: null, id: 'editPlaylistIndex' })"
    >
      <v-btn icon>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="text--secondary">Edit Playlist</v-toolbar-title>
    </v-toolbar>    
    <div v-if="localCopy != null">
      <v-card class="d-flex align-center flex-column pb-5">
        <v-card-text>
          <v-container align="center">
            <v-row class="my-3">
              <v-text-field
                readonly
                disabled
                label="Playlist Name"
                v-model="localCopy.name"
                required
                dense
                outlined
                append-icon="mdi-lock"
                @update:error="
                  $store.state.parameters.inputErrorEditPlaylist1=$event
                "
                :rules="[
                  rulesName.required,
                  rulesName.counterMax,
                  rulesName.counterMin,
                  rulesName.name,
                ]"
              ></v-text-field>
            </v-row>
            <v-row class="mt-3">
              <v-textarea
                auto-grow
                counter
                label="Description"
                v-model="localCopy.description"
                rows="3"
                append-icon="mdi-pencil"
                @update:error="
                  $store.state.parameters.inputErrorEditPlaylist2=$event
                "
                outlined
                hint="A brief description of the playlist"
                :rules="[
                  rulesDescription.required,
                  rulesDescription.counterMin,
                  rulesDescription.counterMax,
                ]"
              ></v-textarea>
            </v-row>
            <v-row justify="space-around">
    <v-progress-circular
      :size="50"
      color="warning"
      indeterminate
      v-if="saveLoader"
    ></v-progress-circular>
    </v-row>
          </v-container>
        </v-card-text>
        <div v-if="localCopyLoaded">
          <v-row>
            <v-col>
              <v-textarea
                class="rounded-xl mx-5"
                auto-grow
                background-color="#952175"
                dark
                label="Opening comment"
                v-model="localCopy.opening"
                dense
                rows="1"
                solo
                append-icon="mdi-comment-plus-outline"
                :rules="[rulesComment.counterMax]"
              ></v-textarea>
            </v-col>
          </v-row>
          <div v-for="(midx, m) in localCopy.mantras" :key="m" class="px-5">
            <v-card
              class="my-3 mx-5 py-1 pr-5 pl-3 rounded-lg"
              color="#1F7087"
              dark
            >
              <!-- <v-row class="ma-2">                                     -->
              <v-textarea
                v-if="localCopy.commentsON[m] == 1"
                auto-grow
                :label="'Optional comment: Mantra ' + (m + 1)"
                v-model="localCopy.comments[m]"
                rows="1"
                solo
                :rules="[rulesComment.counterMax]"
              ></v-textarea>
              <!-- </v-row> -->
              <v-row>
                <v-col
                  cols="1"
                  grow
                  class="caption pa-0 pl-1 align-self-center"
                >
                  {{ m + 1 }}
                </v-col>
                <v-col cols="1" class="font-weight-light pl-1 ml-0 mr-2">
                  <span>
                    <v-btn icon x-small @click="toggleComment(m)">
                      <v-icon v-if="localCopy.commentsON[m] == 0">
                        mdi-comment-plus</v-icon
                      >
                      <v-icon v-if="localCopy.commentsON[m] == 1">
                        mdi-comment-off</v-icon
                      >
                    </v-btn>
                  </span>
                  <br />

                  <!-- add the delete button with the dialog -->
                  <deleteDialog
                    :index="m"
                    v-on:deleteConfirmed="deleteMantra($event)"
                  ></deleteDialog>
                </v-col>
                <v-col>
                  <span class="font-weight-medium">
                    {{ mantras[localCopy.mantras[m] - 1].Name }}
                  </span>
                  <br />
                  <span class="">
                    {{
                      convert(
                        mantras[localCopy.mantras[m] - 1].mantra[0].split(
                          ";"
                        )[0],
                        "primary"
                      )
                    }}
                  </span>
                </v-col>
                <v-col cols="1">
                  <v-btn
                    icon
                    x-small
                    raised
                    elevation="1"
                    v-if="m > 0"
                    @click="moveUp(m)"
                  >
                    <v-icon>mdi-transfer-up</v-icon>
                  </v-btn>
                  <br />
                  <v-btn
                    icon
                    x-small
                    raised
                    elevation="1"
                    v-if="m < localCopy.mantras.length - 1"
                    @click="moveDown(m)"
                  >
                    <v-icon>mdi-transfer-down</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </div>
          <v-row>
            <v-col>
              <v-textarea
                class="rounded-xl mx-5 my-5"
                auto-grow
                dense
                background-color="#952175"
                dark
                label="Closing comment"
                v-model="localCopy.closing"
                rows="1"
                solo
                append-icon="mdi-comment-plus-outline"
                :rules="[rulesComment.counterMax]"
              ></v-textarea>
            </v-col>
          </v-row>
        </div>
        <!-- <div :style="{ whiteSpace: 'pre-line' }">
          {{ localCopy.description }}
        </div>         -->
      </v-card>
      <!-- {{$store.state.parameters.inputErrorEditPlaylist1}} -->
    </div>

    <div>
      <v-dialog v-model="updateDialog" max-width="250px">
        <template v-slot:activator="{ on, attrs }">
          <v-fab-transition>
            <v-btn
              :disabled="(inputErrorEditPlaylist1 || inputErrorEditPlaylist2)"
              class="moveUp"
              v-bind="attrs"
              fab
              fixed
              bottom
              left
              small
              dark
              color="info darken-2"
              @click="updateDialog = true"
            >
              <v-icon>mdi-exit-to-app</v-icon>
            </v-btn>
          </v-fab-transition>
        </template>
        <v-card outlined rounded ref="form">
          <v-card-text>
            <v-container>
              <v-row justify="space-between" align="center">
                <span class="text-subtitle-1"> Power Mantra </span>
                <v-btn icon @click="updateDialog = false" dark>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-row>
              <div class="text-subtitle-1 mt-5">
                Save and Exit if done editing this playlist?
              </div>
              <v-card-actions>
                <v-btn
                  color="green darken-1"
                  @click="updateDialog = false"
                  dark
                >
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-btn
                  color="red darken-1"
                  dark
                  v-on:click="saveOwnedPlaylist()"
                >
                  OK
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Sanscript from "sanscript";
import deleteDialog from "./subcomponents/delete-dialogue.vue";
import { auth, db } from "@/main.js";

export default {
  props: {},
  components: {    
    deleteDialog,
  },
  data() {
    return {      
      saveLoader: false,
      updateDialog: false,
      localCopyLoaded: false,
      changeDetected: false,
      rulesComment: {
        counterMax: (value) =>
          value.trim().length <= 250 || "Max 250 characters",
      },
      rulesDescription: {
        required: (value) => !!value || "Required.",
        counterMin: (value) => value.trim().length > 50 || "Min 50 characters",
        counterMax: (value) =>
          value.trim().length <= 250 || "Max 250 characters",
      },
      rulesName: {
        required: (value) => !!value || "Required.",
        counterMin: (value) => value.trim().length >= 3 || "Min 3 characters",
        counterMax: (value) => value.trim().length <= 35 || "Max 35 characters",
        name: (value) =>
          this.takenPlaylistNames.length == 0 ||
          this.takenPlaylistNames.includes(value.trim()) == false ||
          "Already exists",
      },
    };
  },
  mounted() {
    setTimeout(() => {
      let temp = this.ownedPlaylists[this.editPlaylistIndex];
      console.log(temp)
      if (this.localCopy == null) {
        this.$store.state.parameters.localCopy = JSON.parse(
          JSON.stringify(temp)
        );
        if (this.localCopy.comments === undefined) {
          this.$store.state.parameters.localCopy.comments = Array.from(" ".repeat(temp.mantras.length)).map(a => a.trim())          
        }
        if (this.localCopy.commentsON === undefined) {
          this.$store.state.parameters.localCopy.commentsON = Array.from(
            "0".repeat(temp.mantras.length)
          ).map((a) => parseInt(a));
        }
        if (this.localCopy.opening === undefined) {
          this.$store.state.parameters.localCopy.opening = "";
        }
        if (this.localCopy.closing === undefined) {
          this.$store.state.parameters.localCopy.closing = "";
        }
        console.log(this.localCopy);
      }
      this.localCopyLoaded = true;      
    }, 100);
  },
  computed: {
    ...mapState("parameters", [
      "script",
      "editPlaylistIndex",
      "localCopy",
      "inputErrorEditPlaylist1",
      "inputErrorEditPlaylist2",
    ]),
    ...mapState("firestoreSelf", ["ownedPlaylists"]),
    ...mapState("coretext", ["mantras"]),
    takenPlaylistNames() {      
      let x = this.ownedPlaylists.map((a) => a.name);      
      return x.filter((xx) => xx !== x[this.editPlaylistIndex]);
    },
  },
  methods: {
    ...mapMutations("parameters", ["SET_value"]),
    saveOwnedPlaylist() {      
      this.detectChange()
      console.log(this.changeDetected)
      let dt = new Date();
      let endTime = Math.ceil(dt.getTime());      
      this.localCopy.timestamp = endTime
      console.log(this.localCopy)
      db.collection("userdata")
        .doc(auth.currentUser.uid)
        .collection("playlists")
        .doc(this.ownedPlaylists[this.editPlaylistIndex].idLocal)
        .update(this.localCopy)
      // if(this.ownedPlaylists.length==1) {            
      //       this.$store.state.firestoreSelf.ownedPlaylists.splice(this.editPlaylistIndex, 1)
      //       }  
      this.updateDialog = false 
      this.saveLoader = true
      setTimeout(() => { 
        this.saveLoader = false
        this.SET_value({ list: null, id: 'editPlaylistIndex' })
        }, 500)   
    },
    detectChange() {
      let temp = this.ownedPlaylists[this.editPlaylistIndex]
      let nameChanged = (temp.name != this.localCopy.name)
      let descriptionChanged =  (temp.description != this.localCopy.description)      
      if(temp.mantras.length != this.localCopy.mantras.length) { // mantras removed
      this.changeDetected = true
      return  
      } else if (nameChanged) {
      this.changeDetected = true
      return  
      } else if (descriptionChanged) {
        this.changeDetected = true
      return  
      } else {
       // check if order has changed and if yes then return
        for (let index1 = 0; index1 < temp.mantras.length; index1++) {
          if(temp.mantras[index1]!=this.localCopy.mantras[index1]) {
            this.changeDetected = true
            return
          }        
      }
      let noCommentsExists = this.localCopy.opening == '' && this.localCopy.closing == '' && this.localCopy.comments.findIndex(a => a!='')==-1
      let noCommentsAdded = noCommentsExists
      // let noCommentsAdded = !(temp.hasOwnProperty('opening') || temp.hasOwnProperty('closing') || temp.hasOwnProperty('comments')) && noCommentsExists
      if(noCommentsAdded) { 
        this.changeDetected = false
        return 
        } else {
          let openingChanged = (temp.opening != this.localCopy.opening)
          let closingChanged = (temp.closing != this.localCopy.closing)      
          if (openingChanged || closingChanged) {
            this.changeDetected = true
            return
            } else {
              for (let index = 0; index < temp.comments.length; index++) {
                if(this.localCopy.comments[index] != temp.comments[index]) {
                  this.changeDetected = true
                  return  
                }
              }                              
            }
        }
      }
    },                        
    deleteMantra(index) {
      this.localCopyLoaded = false;
      this.$store.state.parameters.localCopy.mantras.splice(index, 1);
      this.$store.state.parameters.localCopy.comments.splice(index, 1);
      this.$store.state.parameters.localCopy.commentsON.splice(index, 1);
      this.localCopyLoaded = true;
    },
    toggleComment(m) {
      this.localCopyLoaded = false;
      this.localCopy.commentsON[m] == 1
        ? (this.$store.state.parameters.localCopy.commentsON[m] = 0)
        : (this.$store.state.parameters.localCopy.commentsON[m] = 1);
      if (this.localCopy.commentsON[m] == 0)
        this.$store.state.parameters.localCopy.comments[m] == "";
      this.localCopyLoaded = true;
    },
    moveUp(m) {
      console.log(this.takenPlaylistNames);
      this.localCopyLoaded = false;
      console.log("I am in moveUp", m);
      let tempMantra = this.localCopy.mantras[m - 1];
      let tempComment = this.localCopy.comments[m - 1];
      let tempCommentON = this.localCopy.commentsON[m - 1];
      this.$store.state.parameters.localCopy.mantras[m - 1] =
        this.localCopy.mantras[m];
      this.$store.state.parameters.localCopy.comments[m - 1] =
        this.localCopy.comments[m];
      this.$store.state.parameters.localCopy.commentsON[m - 1] =
        this.localCopy.commentsON[m];
      this.$store.state.parameters.localCopy.mantras[m] = tempMantra;
      this.$store.state.parameters.localCopy.comments[m] = tempComment;
      this.$store.state.parameters.localCopy.commentsON[m] = tempCommentON;
      console.log(this.localCopy);
      this.localCopyLoaded = true;
    },
    moveDown(m) {
      this.localCopyLoaded = false;
      let tempMantra = this.localCopy.mantras[m + 1];
      let tempComment = this.localCopy.comments[m + 1];
      let tempCommentON = this.localCopy.commentsON[m + 1];
      this.$store.state.parameters.localCopy.mantras[m + 1] =
        this.localCopy.mantras[m];
      this.$store.state.parameters.localCopy.comments[m + 1] =
        this.localCopy.comments[m];
      this.$store.state.parameters.localCopy.commentsON[m + 1] =
        this.localCopy.commentsON[m];
      this.$store.state.parameters.localCopy.mantras[m] = tempMantra;
      this.$store.state.parameters.localCopy.comments[m] = tempComment;
      this.$store.state.parameters.localCopy.commentsON[m] = tempCommentON;
      this.localCopyLoaded = true;
    },
    convert(myinput, option) {
      if (option === "primary") {
        return Sanscript.t(myinput, "itrans", this.script);
      } else {
        let secondScript = "iast";
        if (this.script === "iast" || this.script === "itrans") {
          secondScript = "devanagari";
        }
        return Sanscript.t(myinput, "itrans", secondScript);
      }
    },
    sharingInfo(item) {
      let playlistId = item.tag;
      let myTempPath =
        "https://powermantra.web.app" +
        "/" +
        "Library" +
        "/" +
        "pl=" +
        playlistId;
      return {
        url: myTempPath,
        title: item.name,
        description: item.description,
        quote:
          "Mantrams are collection of phrases, words and sounds which by virtue of rhythmic effect achieve results that would not be possible apart from them.. - Master Djwhal Khul",
        hashtags: "mantra, meditation, yoga",
      };
    },
    viewPlaylist(item) {
      let playlistId = item.tag;
      let myTempPath =
        // "https://powermantra.web.app" +
        "/" + "Library" + "/" + "pl=" + playlistId;
      this.$router.push(myTempPath);
    },
  },
};
</script>
 <style lang="scss" scoped>
.v-btn--fixed.v-btn--bottom {
  bottom: 70px;
}
.v-btn--fixed.v-btn--right {
  right: 10px;
}
.v-btn--fixed.v-btn--left {
  left: 10px;
}
</style>