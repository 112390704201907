import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  addItemVisited: false, //keep track of if the addItem path is visited and if yes then show button to reach that path
  addItem: 0, //to access add component through Explorer component
  authenticated: false,
  photoURL: 'not singed in',
  userName: '',
  path: '/explore',
  userClaims: {},
  mainItem: 'explore',
  themeDark: true,
  coloredText: true,
  language: 'english',
  script: 'iast',
  fsize: 'medium',
  fabShow: true,
  isDeveloper: false,
  localCopy: null,  
  searchSelect: [{ mantra_id: 3, verse_id: 0 },
  { mantra_id: 4, verse_id: 0 },
  { mantra_id: 5, verse_id: 0 }],
  filterSelected: null,
  nextFilterOptions: [],
  nextFilter: null,
  nextNextFilterOptions: [],
  nextNextFilter: null,
  browseBy: false,
  ce: false, //chanting on for explore
  cl: false, //chanting on for library
  pl: null, //pl is passed through url for the library
  plUnauth: null, //for unauthenticated scenario store a temparory pl that can be reused
  mn: null, //mantra number in an encoded string format, name.subset$mantra_id$verse_id, 
  editPlaylistIndex: null, //if null then the regular view is rendered in Library component,
  inputErrorEditPlaylist1: false, //keep track of input erros for the text fields when switching between tabs
  inputErrorEditPlaylist2: false, //keep track of input erros for the text fields when switching between tabs
  translateON: true, //for chanting mode, playlist level control
  transliterateON: true, //for chanting mode, playlist level control
  zoomText: 1, //for chanting mode, playlist level control
  parallelViewON: true, //for chanting mode, playlist level control
  
  playON: false, //flag for play ON in the chanting mode  
  currentChantIndex: 0, //keep track of how far the chanting has progressed  
  myTimeStart: 0, //time when restart or start was pressed. not updated when resume is pressed. 
  myTime0: null, //start of the clock
  myTime1: null, //current clock time since reset of playON=true
  myTime1Offset: 0, //clock time offset from the previous run before playON=false, actual time = myTime1 + myTime1Offset
  myTime: [], //keep track of the time by mantra/verse in chanting / play mode,
  myItemTracker: [], //store ids of the verses rendered in this playlist  
  myItemTrackerSave: [], //store ids of the verses rendered in this playlist at the mantra level as user record
  chantTimes: 1, //how many times want to chant the playlist
  chantTimesDone: 0, //keep track of how many times already done
  resultReady: false, //when resultReady=true then take the action and reset it to false,

  playONExplore: false, //flag for play ON in the explore mode
  currentChantIndexExplore: 0, //keep track of how far the chanting has progressed
  myTimeStartExplore: 0, //time when restart or start was pressed. not updated when resume is pressed. 
  myTime0Explore: null, //start of the clock
  myTime1Explore: null, //current clock time since reset of playON=true
  myTime1OffsetExplore: 0, //clock time offset from the previous run before playON=false, actual time = myTime1 + myTime1Offset
  myTimeExplore: [], //keep track of the time by mantra/verse in chanting / play mode,
  myItemTrackerExplore: [], //store ids of the verses rendered in this playlist  
  myItemTrackerSaveExplore: [], //store ids of the verses rendered in this playlist at the mantra level as user record
  chantTimesExplore: 11, //how many times want to chant the mantra in /Explore
  chantTimesDoneExplore: 0, //keep track of how many times already done
  resultReadyExplore: false, //when resultReady=true then take the action and reset it to false,
  selectedMantra: '', //selected Mantra for chanting in the explore mode
  selectedMantraIndex: 0, //selected Mantra Index for chanting in the explore mode
  
  speechAnalysisResults: [], // the variable to store the results from speech analysis using the myWorker.js

  //for the sanskrit alphabet
  alphabetSelect: {
    column: {
      vowel: true,
      short: true,
      long: false,
      consonant: true,
      stop: true,
      nasal: true,
      semivowel: true,
      sibilant: true,
      unvoiced: true,
      voiced: true,
      unaspirated: true,
      aspirated: true
    },
    row: {
      velar: false,
      palatal: false,
      retroflex: false,
      dental: true,
      labial: true,
      palatovelar: true,
      labiovelar: true,
      nasalization: true,
      discharge: true
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
