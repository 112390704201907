<template>
<div class="myMainMain">
  <div  class="myWrapper"></div>
</div>
</template>

<script>
import anime from "animejs/lib/anime.es.js";
import { mapState } from "vuex";
export default {
  props: {
    // numberOfEls: Number,    
    // currentCount: Number,
    duration: Number,
  },
  data() {
    return {
      tl: null,
      wrapperEl: null,
    };
  },
  components: {},
  mounted() {
    setTimeout(()=>{
    this.createMala()
    this.wrapperEl.children[this.chantTimesDoneExplore].classList.add("elClassActive")
      }, 100)    
    // setInterval(()=>{this.tl.play()}, 10000)
  },
  computed: {
    ...mapState("parameters", [
      "chantTimesExplore",
      "chantTimesDoneExplore"
    ])
  },
  methods: {
    removeAllChildNodes(parent) {
    while (parent.firstChild) {
        parent.removeChild(parent.firstChild);
    }
},
    createMala() {
      console.log('I am in createmala')
      this.wrapperEl = document.querySelector(".myWrapper");
      // this.removeAllChildNodes(this.wrapperEl)
      const delay = this.duration / this.chantTimesExplore;

      this.tl = anime.timeline({
        duration: delay,
        // complete: function () {
        //   tl.restart();
        // },
      });
      function createEl(i, tl, numberOfEls, wrapperEl, duration) {
        let el = document.createElement("div");
        const rotate = (360 / numberOfEls) * i;
        const translateY = -50;
        const hue = Math.round((360 / numberOfEls) * i);
        el.classList.add("elClass");
        el.style.backgroundColor = "hsl(" + hue + ", 40%, 60%)";
        el.style.transform =
          "rotate(" + rotate + "deg) translateY(" + translateY + "%)";
        tl.add({
          begin: function () {
            anime({
              targets: el,
              backgroundColor: [
                "hsl(" + hue + ", 40%, 60%)",
                "hsl(" + hue + ", 60%, 80%)",
              ],
              rotate: [rotate + "deg", rotate + 10 + "deg"],
              translateY: [translateY + "%", translateY + 10 + "%"],
              scale: [1, 1.25],
              easing: "easeInOutSine",
              direction: "alternate",
              duration: duration * 0.1,
            });
          },
        });
        wrapperEl.appendChild(el);
      }
      for (let i = 0; i < this.chantTimesExplore; i++) {
        createEl(i, this.tl, this.chantTimesExplore, this.wrapperEl, this.duration);
      }
      // console.log(this.wrapperEl.children.length)
      // wrapperEl.children[10].style.backgroundColor = "red"
      // this.wrapperEl.children[this.chantTimesDoneExplore].classList.add("elClassActive")
      // this.wrapperEl.children[10].classList.remove("elClassActive")
      // this.wrapperEl.children[30].classList.add("elClassActive")
      // console.log(this.tl)
    },
  },
  watch: {
    chantTimesDoneExplore: function(){
      console.log(this.chantTimesDoneExplore)
      if(this.chantTimesDoneExplore>0) {      
        this.wrapperEl.children[this.chantTimesDoneExplore-1].classList.remove("elClassActive")
        this.wrapperEl.children[this.chantTimesDoneExplore].classList.add("elClassActive")
        this.tl.play()
      }
      if(this.chantTimesDoneExplore==0) {
        this.wrapperEl.children.forEach(element => {
          element.classList.remove("elClassActive")
        })
        this.wrapperEl.children[0].classList.add("elClassActive")
      }
    },
    chantTimesExplore: function(){
      console.log('I am here in chantTimesExplore')
      console.log(this.chantTimesExplore)
      this.removeAllChildNodes(this.wrapperEl)
      this.createMala()
      this.wrapperEl.children[0].classList.add("elClassActive")
    }
  }
};
</script>
 <style lang="scss">
.myMainMain {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 40vh;
    }
    .myWrapper {
      position: relative;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 1px;
      height: 50vh;
    }
    .elClass {
      position: absolute;
      opacity: 1;
      width: 2px;
      height: 10vh;
      margin-top: -12vh;
      transform-origin: 50% 100%;
      background: white;
    }
    .elClassActive {
      border-style: ridge;
      border-color: rgba(255, 0, 43, 0.79);
    }
</style>