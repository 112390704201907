<template>
  <div v-if="loaded">    
    <loadPlaylistBar></loadPlaylistBar>
    <v-divider class="my-5"></v-divider>    
    <div v-if="ownedPlaylists != null">
      <div v-if="ownedPlaylists.length > 0">
        <div class="text-subtitle-1" align="center">My Playlists </div>
        <v-card
          v-for="(item, ix) in ownedPlaylists"
          :key="item.tag + 'owned'"
          class="pa-4 mx-0 my-2"
        >
          <v-row>
            <v-col cols="9">
              <span> {{ item.name }} </span> <v-icon x-small>mdi-pencil</v-icon>
              <div class="body-2 text--secondary">
                {{ item.owner }} &#183; {{ item.mantras.length }}
                {{ item.mantras.length > 1 ? "mantras" : "mantra" }} &#183;
                {{ extractDHMS(item.timestamp) }}
              </div>
            </v-col>
            <v-col align="right">
              <playlistInfo
                :item="item"
                :mantras="mantras"
                :listIndex="ix"
                :deleteON="ownedPlaylists.length>2"
              ></playlistInfo>
            </v-col>
          </v-row>
        </v-card>
      </div>
      <div v-else @click="loadMyLists()">
        <v-btn> my playlists </v-btn>
      </div>
    </div>

    <div v-if="sharedPlaylists != null">
      <div v-if="sharedPlaylists.length > 0">
        <v-card
          v-for="item in sharedPlaylists"
          :key="item.tag + 'shared'"
          class="pa-4 mx-0 my-2"
        >
          <v-row>
            <v-col cols="9">
              <span> {{ item.name }} </span>
              <div class="body-2 text--secondary">
                {{ item.owner }} &#183; {{ item.mantras.length }}
                {{ item.mantras.length > 1 ? "mantras" : "mantra" }} &#183;
                {{ extractDHMS(item.timestamp) }}
              </div>
            </v-col>
            <v-col align="right">
              <playlistInfo
                :item="item"
                :mantras="mantras"
                sharedPlaylist
              ></playlistInfo>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
  </div>
</template>


<script>
// import { auth } from "@/main.js";
import { mapState, mapMutations } from "vuex";
// import socialSharing from "./subcomponents/social-sharing.vue";
import playlistInfo from "./subcomponents/playlist-info.vue";
import { auth } from "@/main.js";
import loadPlaylistBar from "./load-playlist-bar.vue"
export default {
  props: {
    ownedPlaylists: Array,
    sharedPlaylists: Array,
    mySharedList: Object,
  },
  data() {
    return {
      loaded: false
    };
  },
  components: {
    // socialSharing,
    playlistInfo,
    // playlistEdit
    loadPlaylistBar
  },
  computed: {
    // ...mapState("firestore", ["ownedPlaylists", "sharedPlaylists", "mySharedList"]),
    ...mapState("coretext", ["mantras"]),
    ...mapState("parameters", ["authenticated", "pl", "plUnauth"]),
  },
  mounted(){
    setTimeout(() => {
      this.loaded = true
    }, 100) 
  },
  methods: {
    ...mapMutations("parameters", ["SET_value"]),
    loadMyLists() {
      this.$store.state.firestore.ownedPlaylists = null
        this.$store.state.firestore.sharedPlaylists = null
        this.$store.state.firestore.mySharedList = null
        this.$store.state.firestore.ownedRecords = null
        this.$store.state.firestore.selfURL = false
        this.$store.state.firestore.selfURLHistory = false  
        this.bindToFirestoreOwned("ownedPlaylists");
    },
    bindToFirestoreOwned(value) {       
      // this.$store.dispatch("firestore/unbindOwnedPlaylists")
      // console.log(this.ownedPlaylists)
      if (this[value] == null) {
        console.log("I am in bindToFireStore " + value);        
        this.$store.dispatch("firestore/bindUserdata", {
          path: "/userdata/" + auth.currentUser.uid + "/playlists",
          type: "collection",
          whereToBind: value,
        })
      } else {
        console.log(value + " already bound");
      }
    },
    loadSelectedPlaylist(item) {
      let playlistId = item.tag;
      let myTempPath = "/" + "Library" + "/" + "pl=" + playlistId;
      this.$router.push(myTempPath);
    },
    extractDHMS(millsecs) {
      let a = new Date(millsecs);
      a = a.toLocaleString().split(",");
      let b = new Date();
      b = b.toLocaleString().split(",");
      if (a[0] == b[0]) {
        let temp1 = a[1].trim().split(" ");
        let temp2 = temp1[0].split(":");
        return temp2[0] + ":" + temp2[1] + " " + temp1[1];
      } else {
        return a[0];
      }
    },
    viewPlaylist(item) {
      this.$store.state.firestore.selfURL = true;
      let playlistId = item.tag;
      let myTempPath =
        // "https://powermantra.web.app" +
        "/" + "Library" + "/" + "pl=" + playlistId;
      this.$router.push(myTempPath);
    },
  },
};
</script>
 <style lang="scss" scoped>
</style>