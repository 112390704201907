<template>
  <div >
    <prounounceSanskrit> </prounounceSanskrit>
  </div>
</template>


<script>
import prounounceSanskrit from "./sanskrit-pronounce.vue";
export default {
  data() {
    return {      
    };
  },
  components: { prounounceSanskrit},
  mounted() {    
  },
  computed: {
    
  },
  methods: {
      },
};
</script>
 <style lang="scss" scoped>
</style>
