<template>
      <div id="audioProcessDiv" ref="audioProcessDiv" class="ml-2">
        <!-- Samples Received: {{ numAudioSamples }} <br/>
        {{myResults}} -->
      </div>
</template>

<script>
import RecorderService from "@/components/library/subcomponents/audiorecorder/shared/RecorderService";
import Worker from "worker-loader!./myWorker.js";

export default {
  name: "Test1",  
  props: {
    currentVerse: Number,
    doRecord: Boolean,
  },
  data() {
    return {
      recordingInProgress: false,      
      recordings: [],      
      numAudioSamples: 0,
      myData: new Float32Array(2048 * 1000),   
      // myResults: null   
    };
  },
  created() {
    this.recorderSrvc = new RecorderService();
    // console.log(this.recorderSrvc)
    this.recorderSrvc.em.addEventListener("recording", (evt) =>
      this.onNewRecording(evt)
    );
    this.recorderSrvc.em.addEventListener("onaudioprocess", (evt) =>
      this.onAudioProcess(evt)
    );
    this.recorderSrvc.config.broadcastAudioProcessEvents = true;
  },
  mounted() {},
  methods: {    
    startRecording() {
      //this.start()
      this.numAudioSamples = 0;
      this.recorderSrvc
        .startRecording()
        .then(() => {
          this.recordingInProgress = true;
        })
        .catch((error) => {
          console.error("Exception while start recording: " + error);
          alert("Exception while start recording: " + error.message);
        });
    },
    stopRecording() {
      //this.end()
      this.recorderSrvc.stopRecording();
      this.recordingInProgress = false;
    },
    onAudioProcess(e) {
      this.numAudioSamples++;
      let inputBuffer = e.detail.inputBuffer;
      let outputBuffer = e.detail.outputBuffer;
      // let inputData = null
      // let outputData = null
      for (
        let channel = 0;
        channel < outputBuffer.numberOfChannels;
        channel++
      ) {
        let inputData = inputBuffer.getChannelData(channel);
        // let outputData = outputBuffer.getChannelData(channel)
        for (let i = 0; i < 2048; i = i + 1) {
          this.myData[(this.numAudioSamples - 1) * 2048 + i] = inputData[i];
        }
      }
    },
    onNewRecording(evt) {
      // this.recordings.push(evt.detail.recording);
      this.recordings = evt.detail.recording
    },
    processSegment() {
      console.log("I just sent my signal to the pitch worker");
      const myDataFinal = this.myData.slice(0,this.numAudioSamples*2048)
    //   const myDataFinal = this.myData.slice(0, 43 * 232); //for speech recognition
      const worker = new Worker();
      this.numAudioSamples = 0;
      const config = {
        // tempo: 60, // in BPM, defaults to 120
        quantization: 4, // samples per beat, defaults to 4 (i.e. 16th notes)
        // sampleRate: 48000,
        sampleRate: 44100,
      };
      worker.postMessage({ myDataFinal: myDataFinal, config: config });
      worker.addEventListener("message", (event) => {
        console.log(event.data.summary);
        // console.log(event.data.summary.rms);
        // this.myResults = event.data.summary
        event.data.summary.id = this.currentVerse
        this.$store.state.parameters.speechAnalysisResults.push(event.data.summary)
        console.log(this.$store.state.parameters.speechAnalysisResults)
      });
    },
  },
  watch: {
    doRecord: function () {
      if (this.doRecord) {
        this.startRecording();
      } else {
        this.stopRecording();
      }
    },
    currentVerse: function () {
      if (this.doRecord) {
        this.processSegment();
      }      
    },
  },
};
</script>
<style lang="scss">
</style>