<template>
  <div :style="cssProps">
    
    

 <v-row justify="center" align="center" class="ma-2 text-h6 info--text">
   <v-col class="text-h6 info--text ma-3"> Edit Item </v-col>
<v-col><v-btn @click="downloadFile(editHolder, true)">download current version</v-btn></v-col>
        <!-- add the download dialogue -->
        <v-col>
          <v-dialog v-model="downloadDialog" max-width="325px">
            <template v-slot:activator="{ attrs }">
              <v-fab-transition>
                <v-btn v-bind="attrs" @click="downloadDialog = true">
                  download from cloud
                </v-btn>
              </v-fab-transition>
            </template>
            <v-card outlined rounded ref="form">
              <v-card-text>
                <v-container>
                  <v-row justify="space-between" align="center">
                    <span class="text-subtitle-1"> Download From Cloud </span>
                    <v-btn icon @click="downloadDialog = false" dark>
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-row>
                  <v-row class="mt-5">
                    <v-text-field
                      v-model="cloudName"
                      outlined
                      label="Mantra Name"
                    ></v-text-field>
                    <v-text-field
                      v-model="cloudAuthor"
                      outlined
                      label="Author Name"
                    ></v-text-field>
                  </v-row>
                  <v-row justify="space-around">
                    <v-card-actions>
                      <v-btn
                        color="green darken-1"
                        @click="downloadDialog = false"
                        dark
                      >
                        Cancel
                      </v-btn>
                    </v-card-actions>
                    <v-card-actions>
                      <v-btn
                        color="red darken-1"
                        dark
                        v-on:click="downloadFromCloud()"
                      >
                        Download
                      </v-btn>
                    </v-card-actions>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    <v-row align="center" class="ma-5">            
      <!-- add basic fields -->

      <v-row justify="start" class="text-h6 info--text">                
        <!-- edit the local copy -->
        <v-col>
          <v-select
              class="caption"
              :items="myMantraNames"
              label="Select Mantra Local"
              dense
              outlined
              v-model="whichMantra"
            ></v-select>
          <v-btn @click="loadTemplate('local')" :color="whichMode=='local' ? 'red' : ''">Load Power Mantra Copy</v-btn>
          </v-col>        
<!-- edit the firestore copy -->
<div v-if="loadedFirestore">
  <v-col>
          <v-select
             class="caption"
              :items="myAddedItems"
              label="Select Mantra Cloud"
              dense
              outlined
              v-model="whichFirestoreItem"
            ></v-select>
          <v-btn @click="loadTemplate('firestore')" :color="whichMode=='firestore' ? 'red' : ''">Load My Copy from Cloud</v-btn>
          </v-col>        
</div>
 </v-row>     

      

<v-row>
      <v-col v-if="editHolder != null">
        <v-row v-for="item in myFields1" :key="item">
          <v-text-field
            dense
            outlined
            v-model="editHolder[item]"
            :label="item"
            :placeholder="item"
            :rules="[rulesField.required]"
          ></v-text-field>
        </v-row>
        <!-- add youtube links -->
        <!-- <v-row v-for="(yy, index) in editHolder.Youtube" :key="'YT' + index">
          <v-text-field
            dense
            outlined
            v-model="yy"
            :label="'Youtube Link' + index"
            :placeholder="'Youtube Link' + index"
            :rules="[rulesField.required]"
          ></v-text-field> -->

          <!-- <v-btn
            class="ml-5"
            small
            v-if="index == myYTLinks"
            @click="myYTLinks += 1"
            ><v-icon>mdi-plus</v-icon></v-btn
          > -->
        <!-- </v-row> -->
        <!-- add descriptive fields -->
        <v-row
          v-for="(item, myf2) in myFields2"
          :key="'myfiled2' + myf2"
          cols="12"
        >
          <v-textarea
            outlined
            v-model="editHolder[item]"
            :label="item"
            :placeholder="item"
            :rows="item == 'Benefits' ? 2 : 7"
            :rules="[rulesArea.required]"
          ></v-textarea>
        </v-row>

        <v-row justify="center" class="ma-5" id="topOfScreen"> Edit Mantra Below </v-row>

        <!-- add mantra -->
        <!-- add menu for script selection   -->
        <!-- <v-row align="center">
          <v-col class="d-flex" cols="12" sm="6">
            <v-select
              :items="myScripts"
              label="Input Script"
              dense
              outlined
              v-model="primaryScript"
            ></v-select>
          </v-col>

          <v-col class="d-flex" cols="12" sm="6">
            <v-select
              :items="myScripts"
              outlined
              label="Output Script"
              dense
              v-model="secondaryScript"
            ></v-select>
          </v-col>
        </v-row> -->
        <v-row v-for="(item, vv) in editHolderVerses" :key="'verse' + vv">
          Important: Always use । or ।।. Always have white-space before । and ।।.
          <v-col cols="12" class="addBorder my-3">
            <v-row justify="end" class="mx-1 success--text text-h6">
              verse {{ vv + 1 }}
            </v-row>
            <editMantra                          
              :verse="item"
              :verseIndex="vv"
              :primaryScript="'itrans'"
              :secondaryScript="script"
              class="pa-2"
            ></editMantra>
          </v-col>
          <!-- <v-col cols="12">
            <v-btn
              color="red darken-4"
              dark
              v-if="vv + 1 == addHolderVerses.length"
              @click="addOnemoreVerse()"
              ><v-icon>mdi-plus</v-icon></v-btn
            >
          </v-col> -->
        </v-row>
      </v-col>
    </v-row>
    </v-row>
    <v-row justify="center" id="middleOfScreen">
      <v-btn @click="refreshRenderer()"
        >Mantra Refresh<v-icon>mdi-refresh</v-icon></v-btn
      >
    </v-row>
    
    <div v-if="showRendering">
    <renderItem            
      :mantra="editHolder"
      :script="script"      
      itemIndexOFF      
      skipLinkto
    >
    </renderItem>
    <!-- parallelON -->
    </div>
    <div align="center" id="bottomOfScreen">THE END</div>
    <!-- add the upload dialogue -->
    <div>
      <v-dialog v-model="updateDialog" max-width="250px">
        <template v-slot:activator="{ attrs }">
          <v-fab-transition>
            <v-btn
              v-bind="attrs"
              fab
              fixed
              bottom
              left
              small
              dark
              color="info darken-2"
              @click="updateDialog = true"
            >
              <v-icon>mdi-cloud-upload</v-icon>
            </v-btn>
          </v-fab-transition>
        </template>
        <v-card outlined rounded ref="form">
          <v-card-text>
            <v-container>
              <v-row justify="space-between" align="center">
                <span class="text-subtitle-1"> Power Mantra </span>
                <v-btn icon @click="updateDialog = false" dark>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-row>
              <div class="text-subtitle-1 mt-5">
                Upload changes to the cloud?
              </div>
              <v-card-actions>
                <v-btn
                  color="green darken-1"
                  @click="updateDialog = false"
                  dark
                >
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" dark v-on:click="saveItem()">
                  OK
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <!-- add the scroll buttons -->    
            <v-btn              
              fab              
              fixed              
              bottom
              right
              small
              dark              
              @click="$vuetify.goTo('#middleOfScreen')"
              class="moveUP1"
            >
              <v-icon>mdi-format-align-middle</v-icon>
            </v-btn>
                        <v-btn              
              fab              
              fixed              
              bottom
              right
              small
              dark              
              @click="$vuetify.goTo('#topOfScreen')"
              class="moveUP2"
            >
              <v-icon>mdi-arrow-collapse-up</v-icon>
            </v-btn>
                <v-btn              
              fab              
              fixed              
              bottom
              right
              small
              dark              
              @click="$vuetify.goTo('#bottomOfScreen')"              
            >
              <v-icon>mdi-arrow-collapse-down</v-icon>
            </v-btn>          
  </div>
</template>


<script>
import { mapState, mapMutations } from "vuex";
import editMantra from "./edit-mantra.vue";
import renderItem from "@/components/explore/render-item";
import { db, auth } from "@/main.js";
export default {
  data() {
    return {
      // whichMantra: 'OM',          
      loadedFirestore: false,  
      showRendering: true,
      myFields1: [
        "Name",
        "Source",
        "Category",
        "Quality",
        "Deity",
        "Application",
        "Audio",
      ],
      myFields2: ["Benefits", "Significance", "How to chant"],
      myFields3: ["Youtube"],
      myScripts: ["itrans", "devanagari", "iast"],
      updateDialog: false,
      downloadDialog: false,
      cloudName: "",
      cloudAuthor: "",
      rulesField: {
        required: (value) => !!value || "Required."
      },
      rulesArea: {
        required: (value) => !!value || "Required."
      }
    };
  },
  components: { 
    editMantra, 
  renderItem },
  mounted() {
    this.SET_value({ list: true, id: "addItemVisited" });
    setTimeout(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        console.log("explore/additem");
        //authenticated
        //bind owned records
        this.bindToFirestoreAddedItems('addedItems')
      } else {
        console.log("explore/additem/nouser");
        //unauthenticated                              
      }
    })}, 50)    
  
  },
  computed: {
    ...mapState("addContent", ["editHolder", "addHolder", "addHolderVerses", "editHolderVerses", "whichMantra", "whichFirestoreItem", "whichMode"]),
    ...mapState("coretext", ["mantras"]),
    ...mapState("parameters", ["script"]),
    ...mapState("firestore", ["addedItems"]),
    whichMantra: {
get() {
        return this.$store.state.addContent.whichMantra;
      },
      set(value) {
        this.$store.state.addContent.whichMantra = value;
      },
    },
    whichFirestoreItem: {
get() {
        return this.$store.state.addContent.whichFirestoreItem;
      },
      set(value) {
        this.$store.state.addContent.whichFirestoreItem = value;
      },
    },
    myMantraNames() {
      return this.mantras.map(a => a.Functionality==2 ? null : a.Name).filter(b => b != null)
    },
    myAddedItems() {
      // if(this.loadedFirestore) {
      // return this.addedItems.map(a => a.Name)
      // } else {
      //   return null
      // }
      if(this.loadedFirestore) {
      return this.addedItems.map(a => a.Name + ' | ' + a.Author + ' | ' + 
      this.extractHM(a.timestamp) + ' (' + this.extractDate(a.timestamp) + ')'  )
      } else {
        return null
      }
    },
    cssProps() {
      // console.log(this.myPlaylistLocal);
      return {
        "--offset":
          this.$vuetify.breakpoint.width < 1050
            ? "5px"
            : (this.$vuetify.breakpoint.width - 1050) / 2 + "px",
        "--center": this.$vuetify.breakpoint.width / 2 - 5 + "px",
      };
    },
    myYTLinks: {
      get() {
        return this.$store.state.addContent.myYTLinks;
      },
      set(value) {
        this.$store.state.addContent.myYTLinks = value;
      },
    },
  },
  methods: {
    ...mapMutations("parameters", ["SET_value"]),
    extractDate(milsecs) {
      let aDateObject = new Date(milsecs);
      let aDate = aDateObject.toLocaleString().split(",")[0];
      let a = aDate.split('/')      
      let aYear = a[2].split('')
      console.log(aYear)
      if(aYear[0]=='2' & aYear[1]=='0') {
        aYear = aYear[2] + aYear[3]
      } else {
        aYear = a[2]
      }
      return a[0] + '/' + a[1] + '/' + aYear;
    },
    extractHM(milsecs) {
      let a = new Date(milsecs);
      a = a.toLocaleString().split(",");
      let temp1 = a[1].trim().split(" ");
      let temp2 = temp1[0].split(":");
      return temp2[0] + ":" + temp2[1] + temp1[1];
    },
    downloadFile(val, addTimestamp = false) {      
      if(addTimestamp) {
      let dt = new Date();
      let timestamp = Math.ceil(dt.getTime());
      val.timestamp = timestamp;
      }
      const ordered = Object.keys(val).sort().reduce(
        (obj, key) => { 
          obj[key] = val[key]; 
          return obj;
          }, 
          {});
      const data = JSON.stringify(ordered, undefined, 2);
      const blob = new Blob([data], { type: "text/plain" });
      const e = document.createEvent("MouseEvents"),
        a = document.createElement("a");
      a.download = val.Name + ".json";
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ["text/json", a.download, a.href].join(":");
      e.initEvent(
        "click",
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
      a.dispatchEvent(e);
    },
    downloadFromCloud() {
      db.collectionGroup("items")
        .where("Name", "==", this.cloudName)
        .where("Author", "==", this.cloudAuthor)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            this.downloadFile(doc.data());
          });
        });
      this.downloadDialog = false;
    },
    saveItem() {
      let dt = new Date();
      let timestamp = Math.ceil(dt.getTime());
      this.editHolder.timestamp = timestamp;
      this.editHolder.Author = auth.currentUser.displayName;
      db.collection("additem")
        .doc(auth.currentUser.uid)
        .collection("items")
        .doc(this.editHolder.Name)
        .set(this.editHolder);
      this.updateDialog = false;
    },
    refreshRenderer() {
      this.showRendering = false;
      setTimeout(() => {
        this.showRendering = true;
      }, 10);
    },    
    bindToFirestoreAddedItems(value) {      
        console.log("I am in bindToFireStoreSharedUnauth");                
          this.$store.dispatch("firestore/bindUserdata", {
            path: "items",
            type: "collectionGroup",
            query: ["timestamp", ">", 0],
            whereToBind: value,
          }).then(()=>{
            this.loadedFirestore=true
            // console.log(this.myAddedItems)
            console.log(this.addedItems.map(a=>a.id))
            })                   
    },
      addOnemoreVerse(val1, val2, val1Split, val3, val4, val5) {
      this.$store.state.addContent.editHolderVerses.push({
        myMantra: val1,
        myMantraSplit: val1Split,
        myMnatraSplitTranslate: val2,
        myMantraSplitWord: val3,
        myMantraSplitWordEdit: val3,
        myMantraSplitWordEditTrans: val4,
        myMantraSplitWordEditTransFinal: val5,
      })      
    },
    loadTemplate(myMode) {                 
      if(myMode=='local') {        
      let temp = this.mantras.findIndex(a => a.Name==this.whichMantra)    
      //make a copy of the item to be edited        
      this.$store.state.addContent.editHolder = JSON.parse(JSON.stringify(this.mantras[temp]))      
      this.$store.state.addContent.whichMode = 'local'
      }
      if(myMode=='firestore') {
        let temp = this.myAddedItems.findIndex(a => a==this.whichFirestoreItem)    
        this.$store.state.addContent.editHolder = JSON.parse(JSON.stringify(this.addedItems[temp]))      
        this.$store.state.addContent.whichMode = 'firestore'
      }
      // JSON.parse(xxx) takes time to finish. It must finish before we can execture the below code else there is issue with rendering and functioning.
      setTimeout( () => {
      //place holder for the verses of this mantra
      this.$store.state.addContent.editHolderVerses = [];
      //run through all the verses of this mantra and populate the original text / translation / word-breakdown fields
      for (let index = 0; index < this.editHolder.mantra.length; index++) {        
        //pass the original text in the \n format to be consistent with the text-field expected input
        //edit-mantra will internally convert it to the standar ,/; format (, for word and ; for the sentence)        
        let val1 = this.editHolder.mantra[index].replaceAll(',','').replaceAll(';','\n')        
        //when there is no translation available or no translation for this particular verse the just pass and empty array
        let val2 = []
        if(this.editHolder.mantraenglish[index]==undefined || this.editHolder.mantraenglish[index]=="") {          
          val2 = []
        } else {
          val2 = this.editHolder.mantraenglish[index].split('/').map(a => a.trim())
        }                
        if(val2.length==0){          
         this.$store.state.addContent.editHolder.mantraenglish[index] = ""
         }  
        //when val2 is [] then there is nothing to split for the translation holder, otherwise split using mantra[index] via ; tag 
        let val1Split = val2.length==0 ? [] : this.editHolder.mantra[index].replaceAll(',',' ').split(";").map(a => a.trim())
        //if no word breakdown available in general or for this specific verse then return empty array
        let valTemp1 = []
        let valTemp2 = []
        let valTemp3 = []
        if(this.editHolder.wordenglish[index]==undefined || this.editHolder.wordenglish[index]=="") {          
         valTemp1 = []
         valTemp2 = []
         valTemp3 = []
        } else {
          valTemp1 = this.editHolder.wordenglish[index].split(";").map(
        (a) => a.split(",").map((b) => b.split('/').map(c => c.split('=')[0].trim()).join(' ')))
        valTemp2 = this.editHolder.wordenglish[index].split(";").map(
        (a) => a.split(",").map((b) => b.split('/').map(c => c.split('=')[1])))
        valTemp3 = this.editHolder.wordenglish[index].split(";").map(
        (a) => a.split(",").map((b) => b.split('/').map(c => c.trim())))
        }                    
      if(valTemp3.length==0){        
        this.$store.state.addContent.editHolder.wordenglish[index] = ""
        }
        this.addOnemoreVerse(val1,val2, val1Split, valTemp1, valTemp2, valTemp3)        
      }
      }, 100)              
    },
  },
};
</script>
 <style lang="scss" scoped>
.addBorder {
  border: 0.5px solid rgb(236, 54, 8);
}
.v-btn--fixed.v-btn--bottom {
  bottom: 70px;
}
.v-btn--fixed.v-btn--bottom.moveUP1 {
  bottom: 120px;
}
.v-btn--fixed.v-btn--bottom.moveUP2 {
  bottom: 170px;
}
.v-btn--fixed.v-btn--right {
  right: var(--offset);
}
.v-btn--fixed.v-btn--left {
  left: var(--offset);
}
</style>
