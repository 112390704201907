import axios from 'axios';

const loadText = function ({ commit }) {
  const ax = axios.create({
    baseURL: '/assets/text/json/mantras/'
    //baseURL: 'https://powermantra.web.app/assets/text/json/mantras/'
  })
  
  ax.get('myFilesToLoad.json', { crossdomain: true }).then((response) => {
    commit('setText', { list: response.data, id: "myFilesToLoad" })
  }, (err) => {
    console.log(err)
  }).then(() => {
    let tmp = this.state.coretext.myFilesToLoad
    let i;
    for (i = 0; i < tmp.totalMantras; i++) {
      ax.get('onebyone/mymantra_'+(i+1)+'.json', { crossdomain: true }).then((response) => {        
          this.state.coretext.mantras.push(response.data)                  
      }
      )
    }
  }).then(() => {              
    ax.get('sanskrit_alphabet.json', { crossdomain: true}).then((response) => {
      commit('setText', { list: response.data,  id: "sanskritAlphabet"})
    }, (err) => {
      console.log(err)
    });
  }).then(() => {              
    ax.get('sanskrit_alphabet_duration.json', { crossdomain: true}).then((response) => {
      commit('setText', { list: response.data,  id: "sanskritAlphabetDuration"})
    }, (err) => {
      console.log(err)
    });
  })
};

export default {
  loadText,
};
