<template>
  <div>
    <!-- add mantra -->
    <v-row>
      <v-col cols="5">
        <v-textarea
        class="body-2"
          v-model="verse.myMantra"
          label="Mantra"
          placeholder="Mantra"
          auto-grow          
          @input="splitByLine($event)"
        ></v-textarea>
        
      </v-col>
      <v-col cols="7">
        <v-textarea
        class="body-2"
          v-for="(line, lx) in verse.myMantraSplit"
          :key="line + lx"
          v-model="verse.myMnatraSplitTranslate[lx]"
          dense
          :label="convert(line, 'itrans', secondaryScript)"
          @input="updateMantraenglish()"          
          rows="1"
          auto-grow
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-btn @click="splitMantra()" class="mb-5">Split Mantra</v-btn>
    </v-row>
    <div
      v-for="(line, ll) in verse.myMantraSplitWord"
      :key="line + ll"
      class="my-2"
    >
      <v-row v-for="(word, ww) in line.filter(a => !(a=='|' || a=='||' || a=='।' || a=='।।'))" :key="word + ww">
        <v-text-field
        class="body-2"
          v-model="verse.myMantraSplitWordEdit[ll][ww]"
          @input="
            (verse.myMantraSplitWordEditTrans[ll][ww] = $event.split(/\s+/)),
            (verse.myMantraSplitWordEditTransFinal[ll][ww] =
                $event.split(/\s+/))              
          "
          dense          
        ></v-text-field>
        
        <div class="align-self-end"> 
          <v-btn small text color="success"><v-icon>mdi-arrow-left-right</v-icon></v-btn>
          </div>
        <v-text-field
        class="body-2 mx-1"
          v-for="(wordSplit, ss) in verse.myMantraSplitWordEdit[ll][ww].split(
            /\s+/
          ).filter(a => !(a=='|' || a=='||' || a=='।' || a=='।।'))"
          v-model="verse.myMantraSplitWordEditTrans[ll][ww][ss]"
          :label="convert(wordSplit, 'itrans', secondaryScript)"
          :key="wordSplit + ss"          
          dense
          @input="updateWordenglish(wordSplit, $event, ll, ww, ss)"                              
        ></v-text-field>
        <!-- @input="verse.myMantraSplitWordEditTransFinal[ll][ww][ss] = wordSplit + '=' + $event" -->
      </v-row>
    </div>
  </div>
</template>


<script>
import Sanscript from "sanscript";
export default {
  props: {
    verse: Object,
    verseIndex: Number,
    primaryScript: String,
    secondaryScript: String,
  },
  data() {
    return {   
      rulesField: {
        required: (value) => !!value || "Required."
      }   
    };
  },
  components: {},
  mounted() {    
  },
  computed: {
    prepareMantra() {      
      return this.verse.myMantraSplit
        .map((a) => a.split(/\s+/))
        .map((a) => a.join(", "))
        .join("; ")        
        .replaceAll(/,(?=\s+(\||।)+)/g,'')                                                                                      
    },
  },
  methods: {
    convert(myinput, script1, script2) {
      return Sanscript.t(myinput, script1, script2);
    },
    splitByLine(val) {      
      this.verse.myMantraSplit = val
        .split("\n")                
        .map((a) => this.primaryScript!="itrans" ? this.convert(a.trim(), this.primaryScript, "itrans") : a.trim());        
    },
    splitMantra() {
      this.verse.myMantraSplitWord = this.verse.myMantraSplit.map((a) =>
        a.split(/\s+/)
      );
      this.verse.myMantraSplitWordEdit = this.verse.myMantraSplit.map((a) =>
        a.split(/\s+/)
      );
      this.verse.myMantraSplitWordEditTrans = this.verse.myMantraSplit.map(
        (a) => a.split(/\s+/).map((b) => b.split(/\s+/))
      );
      this.verse.myMantraSplitWordEditTransFinal = this.verse.myMantraSplit.map(
        (a) => a.split(/\s+/).map((b) => b.split(/\s+/))
      );
    },
    updateMantraenglish() {      
      let prepareMantraenglish = this.verse.myMnatraSplitTranslate.join(" / ");      
      this.$store.state.addContent.editHolder.mantraenglish[this.verseIndex] =
        prepareMantraenglish;
        // console.log(prepareMantraenglish)
    },
    updateWordenglish(wordSplit, val, ll, ww, ss) {      
      this.verse.myMantraSplitWordEditTransFinal[ll][ww][ss] =
        wordSplit + "=" + val;
      let prepareWordenglish = this.verse.myMantraSplitWordEditTransFinal
        .map((a) => a.map((b) => b.join(" / ")).join(", "))
        .join("; ");
      this.$store.state.addContent.editHolder.wordenglish[this.verseIndex] =   
      prepareWordenglish.replaceAll(/,\s+(\||।)/g,'')                              
      // console.log('I am in prepareWordenglish')
    },
  },
  watch: {
    prepareMantra: function () {
      this.$store.state.addContent.editHolder.mantra[this.verseIndex] = this.prepareMantra          
      // console.log(this.prepareMantra)        
    },
  },
};
</script>
 <style lang="scss" scoped>
</style>
