<template>
  <v-container class="mb-3">
    <v-row>
      <v-col class="text-center">
        <v-btn
          v-for="(item1, i) in filterByOptions"
          :key="i"
          outlined
          rounded
          text
          class="text-none text-primary ma-2"
          @click="whatInfo = i"
          :class="whatInfo == i ? 'info' : ''"
        >
          {{ item1 }}</v-btn
        >
      </v-col>
    </v-row>
    <v-row class="px-2">
      <v-col>                
        <div v-if="whatInfo == 0">
          <v-expansion-panels>
            <v-expansion-panel v-for="(item, i) in userGuide" :key="i">
              <v-expansion-panel-header>
                {{ item.question }}
              </v-expansion-panel-header>
              <v-expansion-panel-content class="font-weight-light">
                {{item.answer}}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div v-if="whatInfo == 1">
          <div class="ma-2">
            Our goal is to organize popular sanskrit mantras and make them easy
            to study and recite. Whether you are a curious reader or you already
            perform daily study (Svadhyaya) or recitation (Patha) of sanskrit
            texts and mantras, you will find Power Mantra helpful.
          </div>
          <div class="ma-2">
            Power Mantra lets you customize language and theme, launch an
            immersive recitation interface, and track your progress. Refer to
            the
            <span @click="whatInfo = 0" class="info--text">User Guide</span> for
            more details.
          </div>
          <div class="ma-2">
            Power Mantra can be access through any standard internet browser.
            When prompted, if you choose, you can also install Power Mantra as a
            standalone app and enjoy a superior web experience. On Apple devices
            use the Add to Home Screen <v-icon>mdi-plus-box</v-icon> option to
            install it as an app. Power Mantra is built using the latest
            progressive web app (PWA) technology.
          </div>
        </div>
        <div v-if="whatInfo == 2">
          <div class="ma-2">
            The content of this website is for informational purposes only and
            does not constitue professional advice. For prologned advanced
            practices, seeking the guidance of an expert is strongly advised.
          </div>
        </div>
        <!-- <div v-if="whatInfo == 4">
          <div class="ma-2">
            Any misuse of the information on this website or any upload of obscene or harmful content is stricly prohibited. Power Mantra reserves the right to block or remove a user if found in violation of our usage policy. 
          </div>
        </div> -->
        <div v-if="whatInfo == 3">
          <div class="ma-2">
            If you opt to sign in, then a small amount of data to track your
            progress will be saved for your future reference. None of your
            personal history will be shared with others. Any uploaded content
            that is public in nature may be shared with others.
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  components: {    
  },
  data() {
    return {
      filterByOptions: [        
        "User Guide",
        "About Power Mantra",        
        "Disclaimer",
        // "Usage policy",
        "Privacy",
      ],
      whatInfo: 0,
      userGuide: [
        {
          question: "Why we built Power Mantra?",
          answer: "Yoga and meditation have become a key tool for health and wellness management around the globe. With that Hatha Yoga has emerged as a popular system of Yoga today due to its instant physical health and relexation benefits. But not know to many that there are complementary systems of Yoga which are even more powerful and beneficial as one advances in her yoga journey. Mantra Yoga is one such system. Arguably, there is enough information out there on the web to learn about Mantra Yoga, but truly immersive tools that support a regular practice are far and few. With that recognition, we wanted to build a web app that's' not only immersive and easy-to-use but also authentic and accurate so that casual to advanced users alike can benefit from it. And that's how the idea of Power Mantra took shape.",
        },
        {
          question: "Am I required to Sign In to use Power Mantra?",
          answer: "No, you do not require it for the basic usage. But yes you do in order to save your recitation history. If you want to create and share your own playlists then also you do need to Sing In. If you plan to use Power Mantra regularly or if you are interested in tracking your progress, then you should definitely consider signing in. Another benefit of signing in is that your account will be synced across all your devices. To Sign In, click the orange button located on the upper right corner of your screen.",
        },
        { question: "What is a Mantra? ", answer: "Mantra is a sound formula used to conentrate, energize or relax the mind and the body. Most wisdom traditions across the world use mantras. Power Mantra exclusively covers the sanskrit mantras used in Hindu and Buddhist traditions. There is a rich history and science behind the Sanskrit Mantras. These mantras can be super short, for example Om, or super long as what we find in Vedas."},
        { question: "What is the difference between Mantra, Shloka and Sutra?", answer: "In Mantra the sound vibration is primary and the meaning is secondary. In Shloka both sound vibration and meaning are primary. In Sutra meaning is primary and the sound vibration is secondary. Historically, the texts that focused on worship, meditation, and rituals consisted of Mantras, for example Vedas. The texts that focused on ideas, philosophy and wisdom teachings consisted of Shlokas, for example Bhagavad Gita, Mahabharata, Ramayana. The texts that focused on instructions, spiritual practices, and technical definitions consisted of Sutras, for example Yoga Sutras, Brahma Sutras, Dhammapada. Both Mantras and Shlokas tend to follow a pre-specified rhythm called Chhanda (meter). Sutras are compact statements that are easy to memorize and quick to recall. In modern times Mantra and Shloka are often used interchangably."},
        { question: "What are the benefits of reciting Mantras?", answer: "Mantra Shakti (the power embedded in mantra sounds) can have profound effect on physical, mental, and spiritual well-being. Mantras can be recited audibly or silently. Initially the audible recitation would be more beneficial and, if done correctly, can also improve the breathing quality. Benefits accrued by reciting short mantras multiple times versus very long mantras in a single sitting can also be very different. Mantra recitation is a powerful technique to concentrate and energize the mind. It's believed that reciting Mantras can also bring about material benefits by manifesting wealth, prosperity, favorable conditions, oppertunities, etc. Refer to this article by Master Sivananda on Mantra Yoga if you want to learn more. (https://www.dlshq.org/teachings/japa-yoga/)"},
        { question: "How can I recite a Mantra?", answer: "Go to explore tab and find the mantra you like to recite. Once you have found your mantra, click the purple button with audio icon to your left to launch the recitation interface. You can change the settings by clicking the red button with finger icon to your right. Press the purple play button to launch the recitation counter and select the number of repetition. Your browser may ask your permission to access the microphone. Power Mantra doesn't record your voice but only monitors it for pitch, tempo, and volume variation to determine the quality, consistency, and rhythm of your recitation. Once started, press the yellow-orange button with fire icon to proceed with the recitation. As your recitation proceeds the counter in the upper toolbar will show the information including a timer. At the end of your recitation you will receive a score and information on how your recitation went and how to review your record later on." },
        { question: "What is the difference between Japa (repitation), Patha (recitation), and Svadhyaya (study)?", answer: "Japa is the repetition of short Mantra, for example Om Namo Bhagavate Vasudevayah, at a set time on a daily basis. Japa can be audible or silent and can be done with eyes open or closed. A mala is often used to keep track of the number of repetition. If applied correctly, then Japa can be a very powerful meditation technique. Patha is the audible recitation of longer Mantras or sanskrit texts. Japa has a danger of becoming mechanical because of the lack of variety, but Patha can remain rather challenging and engaging if done properly. Svadhyaya is the study of spiritual texts at a set time on a daily basis. It's common to follow Patha with some sort of Svadhyaya and then perform some sort of Japa meditation. Power Mantra is designed to let you perform all three. You may refer to this article by Master Sivananda for more info on Japa, Patha, and Svadhyaya. (https://www.dlshq.org/teachings/japa-yoga/)"},
        { question: "What languages does Power Mantra support? Do I need to know Sanskrit?", answer: "To be clear, sanskrit is the language and devanagari is the script that's often used to write sanskrit texts. You can even use english to write sanskrit texts (referred to as transliteration). Power Mantra supports multiple scripts including devanagari and english. Honestly, if you have a good ear, then you don't need to formally learn sanskrit for an effective recitation. But for most practitioners, learning to read devanagari should help their recitation. Knowing sanskrit grammer is optional unless you desire to understand the texts in their original form. That being said, correctly learning the sanskrit alphabet is crucial for an effective recitation. You may visit the sanskrit tab at the bottom of Power Mantra and systematically learn the sanskrit alphabet."},
        { question: "What translation Power Mantra uses? How can I access the word by word translation?", answer: "To be clear, we are talking about translation and not the transliteration. So, currently Power Mantra supports english translation. This translation aims to resemble the original sanskrit prosody and also to capture its literal meaning. There are plans to have Hindi track available in the near future. The key feature of Power Mantra is the word by word translation, which you can access by clicking on any phrase."},
        { question: "How can I find a specific Mantra I am looking for?", answer: "The best way is to go to the search bar and type the first few letters of the words of the mantra or the name of the mantra. You can also browse the mantras. For longer texts with multiple chapters there is link at the bottom to jump to different chapters. Note that our focus is on popular spiritual mantras of major deities and if you think the mantra you are interested would fit in here then let us know and we will try and add it to our database provded its consistent with our theme." },
        {
          question: "How can I switch the script, theme, and font size?",
          answer: "Go to Settings and choose your preferred script. Underneath you will find options to switch to the dark or light background and color or no-color options. You can change the font size only in the recitation mode. To do so, when you are in the recitation mode, press the red button at the bottom right corner with the finger icon and the options to increase or decrease the font size will appear.",
        },
        {
          question: "What is a playlist? How can I create, edit and share a playlist?",
          answer: "Playlist is a collection of mantras. Traditionally, a handful of mantras from variety of sources are recited together in a single session and you can create your own playlist to do so. To create a playlist or add a mantra to an existing playlist, in Explore go to the mantra of your interest and press the SAVE button with plus icon right above that mantra and a popup will guide you through. Often, when you want to share a playlist with others, you also want to add some opening and closing comments as well as mantra specific comments to help the recitation. You can do so by going to your playlist in Library and loading the playlist in the EDIT mode. In the Library if you click the button with three vertical dots, then you will find the option to share the playlist.",
        },        
        {
          question: "How can I recite a Playlist? How can I recite a Playlist created by someone else?",
          answer: "Reciting a playlist is very similar to reciting a single mantra. Once you have loaded the playlist, click the purple button on the left with audio icon to load the recitation interface. You can change the settings by clicking the red button on the right with finger icon. Press the purple button with play icon to launch the recitation counter. You can select the number of times you like to recite. You can also change the mantra from the dropdown menu. You can resume or restart the recitation. Your browser may ask your permission to access the microphone. We recommend you grant the permission. For your information, Power Mantra doesn't record your voice but only monitors it for pitch and tempo variation to determine the quality, consistency, and rhythm of your recitation. Once started, press the yellow-orange button with fire animation to proceed with your recitation. As your recitation proceeds the counter in the upper toolbar will show the information including a timer. At the end of your recitation you will receive a score and information on how your recitation went and how to review your record later on.",
        },        
        { question: "How can I browse my recitation history?", answer: "Go to the History tab and click on the specific record you want to review. You can also press purple button to your left for overall summary of your recitation history so far." },
        // { question: "What else can I do with Power Mantra?", answer: "xxx" },
      ],
    };
  },
  computed: {},
  methods: {},
};
</script>
 <style lang="scss" scoped>
</style>
