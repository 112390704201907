<template>
  <div >
    <settings isScript isTheme isColor noPopup></settings>
    <v-divider></v-divider>            
        <aboutUs></aboutUs>
  </div>
</template>


<script>
import aboutUs from './about-us.vue'
import settings from './settings-popup.vue'
export default {
  data() {
    return {
      
    };
  },
  components: { 
    aboutUs,
    settings
  },
  mounted() {
    
  },
  computed: {
    
  },
  methods: {
      },
};
</script>
 <style lang="scss" scoped>
</style>
