<template>
  <div>
    <!-- <v-card class="text-justify pa-2" flat :class="{addActiveBorder: isAddActiveBorder}"> -->
      <!-- v-ripple -->
    <div      v-if="!skipInfo">  
    <v-row
      justify="space-between"
      align="center"
      class="ma-3"
    >
      <div class="info--text">Sanskrit with translation</div>
      <div v-if="!itemIndexOFF">
        <savePlaylist
          :whichMantra="mantra.id"
          v-on:saveSuccess="saveSuccessMsgAlert"
          v-if="mainItem=='Explore'"
        ></savePlaylist>
      </div>
    </v-row>
    <v-row
      justify="start"      
      class="mx-5 mb-1"
      v-if="!parallelON"
    >
              <v-btn fab small class="font-weight-bold">
                <v-icon
                  @click="addSecondScript = false"
                  v-if="addSecondScript"
                  color="success"
                  >mdi-translate</v-icon
                >
                <v-icon
                  @click="addSecondScript = true"
                  v-if="!addSecondScript"
                  color="error"
                >
                  mdi-translate-off</v-icon
                >
              </v-btn>                      
    </v-row>
   
    <v-alert
      v-model="saveSuccessMsg"
      border="left"
      close-text="Close Alert"
      color="success"
      dark
      dismissible
      class="body-2"
      dense
    >
      <strong> {{ mantra.name }} </strong> was added to
      <strong> {{ saveSuccessInfo }} </strong>. Go to
      <strong> Library </strong> for your playlists.
    </v-alert>
    </div>
    <!-- <div v-for="(item, i) in mantra.mantra" :key="myKey(i)"> -->
      <div v-for="(item, i) in mantra.mantra" :key="mantra.Name + i" :id="myIdChantMode(i)"
      :class="
        (myIdChantMode(i) == myItemTracker[currentChantIndex]) &
          (mainItem == 'Library') &
          cl ||
        (myIdChantMode(i) == myItemTrackerExplore[currentChantIndexExplore]) &
          (mainItem == 'Explore') &
          ce
          ? 'addActiveBorder'
          : false
      ">
    <v-card      
      class="pa-2"
      flat               
      :ripple="mantra.mantra.length==1 && ((ce || cl) && (playON || playONExplore))"         
    >
      <!-- <div> -->
        <!-- <span id="myChantButtonShadow" class="error--text child">{{convert('svāhā')}}</span>        
        <span id="myChantButtonShadow" class="warning--text child">{{convert('oṃ')}}</span>         -->
        <v-btn
          v-if="animationON"
          text
          class="overlap text-none"
          :x-large="$vuetify.breakpoint.width > 960 ? true : false"
        >
          <div class="parent">            
        <span id="myChantButtonShadow" class="warning--text child">{{
              convert("svAhA", "secondary")
            }}</span>              
            <span id="myChantButtonShadow" class="success--text child">{{
              convert("svAhA", "primary")
            }}</span>            
          </div>
        </v-btn>
        <v-btn
          id="myChantButton"
          class="overlap"
          :x-large="$vuetify.breakpoint.width > 960 ? true : false"
          fab
          plain
          :disabled="animationON"
          @click="proceedChant(i)"
          v-if="
            (myIdChantMode(i) == myItemTracker[currentChantIndex]) & playON ||
            (myIdChantMode(i) ==
              myItemTrackerExplore[currentChantIndexExplore]) &
              playONExplore
          "
        >
          <!-- <v-img width="40" src="/img/gif/hawan2_tiny.gif"></v-img> -->
          <v-img width="40" src="/img/gif/hawan2_1024x1024.gif"></v-img>
        </v-btn>
      <!-- </div> -->
      <renderOneVerse
        :id="myId(i)"
        :mantra="mantra"
        :whichVerse="i"
        :script="script"
        :skipInfo="skipInfo"
        :secondScriptON="secondScriptON"
        :translationON="translationON"
        :zoomON="zoomON"
        :parallelON="parallelON"
        :searchSelect="searchSelect"
      :themeDark="themeDark"
      :coloredText="coloredText"
      :translateON="translateON"
      :transliterateON="transliterateON"
      :zoomText="zoomText"
      :parallelViewON="parallelViewON"
      :cssProps="cssProps"
      :myWidth="myWidth"
      :addSecondScript="addSecondScript"      
      >      
      </renderOneVerse>
      <!-- {{i}}
{{myItemTracker[currentChantIndex]}}
{{myIdChantMode(i)}}
{{(myIdChantMode(i) === myItemTracker[currentChantIndex])}} -->
    </v-card>
    </div>
    <!-- <renderMala v-if="mainItem=='Explore' && mantra.mantra.length==1"></renderMala> -->
    <div v-if="loaded  && ce && chantTimesExplore < 109">
    <renderMala v-if="mainItem=='Explore' && mantra.mantra.length==1" :duration="3000"></renderMala>
    </div>
  </div>
</template>

<script>
import renderOneVerse from "./render-oneverse";
import renderMala from "./render-mala"
import { mapState, mapMutations } from "vuex";
import anime from "animejs/lib/anime.es.js";
import Sanscript from "sanscript";
import savePlaylist from "./save-playlist";
export default {
  props: {
    script: String,
    mantra: Object,
    mantra_id: Number,
    skipInfo: Boolean,
    secondScriptON: Boolean,
    translationON: Boolean,
    zoomON: Boolean,
    parallelON: Boolean,
    itemIndex: Number,
    itemIndexOFF: Boolean
  },
  data() {
    return {
      addSecondScript: false,      
      animationON: false,
      rippleON: true,      
      loaded: false,
      saveSuccessMsg: false,
      saveSuccessInfo: "",
    };
  },
  components: {
    renderOneVerse,
    renderMala,
    savePlaylist
  },
  mounted() {
    setTimeout(()=>{
      this.loaded=true      
      },100)
  },
  computed: {
    ...mapState("parameters", [
      "currentChantIndex",
      "myItemTracker",
      "playON",
      "myTime",
      "myTime0",
      "myTime1Offset",
      "chantTimes",
      "chantTimesDone",
      "resultReady",
      "mainItem",
      "cl",
      "currentChantIndexExplore",
      "myItemTrackerExplore",
      "playONExplore",
      "myTimeExplore",
      "myTime0Explore",
      "myTime1OffsetExplore",
      "chantTimesExplore",
      "chantTimesDoneExplore",
      "resultReadyExplore",
      "ce",
      "searchSelect",
      "themeDark",
      "coloredText",
      "translateON",
      "transliterateON",
      "zoomText",
      "parallelViewON",
    ]),
    cssProps() {
      if(this.$vuetify.breakpoint.width < 600) {
      return {
        "--myfontsize": this.zoomON ? this.zoomText + "rem" : "1.15rem",
      };
      } else {
        return {
        "--myfontsize": this.zoomON ? this.zoomText + "rem" : "1.3rem",
      };
      }
    },
    myWidth() {
      if (this.parallelON) {
        if (this.parallelViewON) {
          if (this.translateON) {
            return 6;
          } else {
            return 12;
          }
        } else {
          return 12;
        }
      } else {
        return 12;
      }
    },
  },
  methods: {
    ...mapMutations("parameters", ["SET_value"]),
    saveSuccessMsgAlert(value) {
      this.saveSuccessMsg = true;
      this.saveSuccessInfo = value;
    },
    convert(myinput, option) {
      if (option === "primary") {        
        return Sanscript.t(myinput, "itrans", this.script);
      } else {
        let secondScript = "iast";
        if (this.script === "iast" || this.script === "itrans") {
          secondScript = "devanagari";
        }        
        return Sanscript.t(myinput, "itrans", secondScript);
      }
    },
    myRipple(i) {
      let el = document.getElementById(this.myIdChantMode(i));
    let ev = new Event("mousedown")
    let offset = el.getBoundingClientRect()
    ev.clientX = offset.left + offset.width/2
    ev.clientY = offset.top + offset.height/2
    el.dispatchEvent(ev)

    setTimeout(function() {
      el.dispatchEvent(new Event("mouseup"))
    }, 300)
  },
    animateChantButton(i) {      
      // console.log(i)
      this.myRipple(i)
      // anime({
      //   targets: "#myChantButton",
      //   easing: "linear",
      //   scale: [1.5, 1],
      //   duration: 3000,
      // });
      var animation = anime({
        targets: "#myChantButtonShadow",
        translateY: -400,
        translateX: -50,
        direction: "normal",
        easing: "linear",
        scale: [1.2, 0.2],
        rotate: "3turn",
        duration: 3000,
        delay: anime.stagger(200),
      });
      animation.finished.then(() => {
        this.animationON = false;
      });
    },
    proceedChant(i) {
      //core logic of how the chant should proceed
      //handle the repition and the end of the playlist appropriately
      //also save the end result to firestore
      // this.rippleON = true      
      // this.rippleON = false
      if (this.mainItem == "Library") {
        let dt = new Date();
        this.$store.state.parameters.myTime.push(
          Math.ceil(dt.getTime()) - this.myTime0 + this.myTime1Offset
        );

        if (this.currentChantIndex == this.myItemTracker.length - 1) {
          this.$store.state.parameters.chantTimesDone += 1;
          if (this.chantTimesDone == this.chantTimes) {
            this.$store.state.parameters.chantTimesDone = 0;
            this.$store.state.parameters.currentChantIndex = 0;
            this.SET_value({ list: null, id: "myTime1" });
            this.SET_value({ list: 0, id: "myTime1Offset" });
            this.SET_value({ list: false, id: "playON" });
            this.SET_value({ list: true, id: "resultReady" });
            return;
          } else {
            this.$store.state.parameters.currentChantIndex = 0;
            return;
          }
        } else {
          this.$store.state.parameters.currentChantIndex += 1;
          return;
        }
      }

      if (this.mainItem == "Explore") {
        if (this.mantra.mantra.length == 1) {
          this.animationON = true;
          setTimeout(() => {
            this.animateChantButton(i);
          }, 10);
        }
        // console.log(i)
        let dt = new Date();
        this.$store.state.parameters.myTimeExplore.push(
          Math.ceil(dt.getTime()) -
            this.myTime0Explore +
            this.myTime1OffsetExplore
        );

        if (
          this.currentChantIndexExplore ==
          this.myItemTrackerExplore.length - 1
        ) {
          this.$store.state.parameters.chantTimesDoneExplore += 1;
          if (this.chantTimesDoneExplore == this.chantTimesExplore) {
            this.$store.state.parameters.chantTimesDoneExplore = 0;
            this.$store.state.parameters.currentChantIndexExplore = 0;
            this.SET_value({ list: null, id: "myTime1Explore" });
            this.SET_value({ list: 0, id: "myTime1OffsetExplore" });
            this.SET_value({ list: false, id: "playONExplore" });
            this.SET_value({ list: true, id: "resultReadyExplore" });
            return;
          } else {
            this.$store.state.parameters.currentChantIndexExplore = 0;
            return;
          }
        } else {
          this.$store.state.parameters.currentChantIndexExplore += 1;
          return;
        }
      }
    },
    myKey(val) {
      return "mantra" + this.mantra_id + "verse" + val;
    },
    myId(val) {
      return "ID_" + "mantra" + this.mantra_id + "verse" + val;
    },
    myIdChantMode(val) {
      if (this.itemIndex === undefined) {
        return;
      } else {
        return "ID_" + "item" + this.itemIndex + "verse" + val;
        // return "i" + this.itemIndex + "v" + val;
      }
    },
  },
};
</script>
 <style lang="scss" scoped>
.overlap {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.addActiveBorder {
  border: 0.5px solid rgb(255, 153, 0);
}
.child {
  position: absolute;
  top: 0;
  left: 0;
}
.parent {
  position: relative;
}
</style>