<template>
  <div align="center">    
    <v-btn
      small
      rounded
      outlined
      @click="goInputPlaylist()"
      class="text-none ma-3"
      :color="inputError ? 'error' : 'success'"
      :depressed="true"
      dark
    >
      <v-icon left> mdi-download </v-icon> Download new playlist
    </v-btn>
    <v-text-field      
      class="ma-0"
      v-model="myQuery"
      outlined
      clearable
      label="The playlist ID or its full URL"
      type="text"
      hint="For example: kulpdcse1KN or https://powermantra.app/Library/pl=kulpdcse1KN"
      persistent-hint
      append-icon="mdi-download"
      @click:append="goInputPlaylist()"
      @keydown.enter="goInputPlaylist()"
    >
    </v-text-field>

    <v-alert
    align="left"
      v-model="inputError"
      border="left"
      close-text="Close Alert"
      type="error"
      dark
      dismissible
      class="body-2"
      dense
      text
    >
      {{ errorMessage }}
    </v-alert>
  </div>
</template>


<script>
export default {
  data() {
    return {
      myQuery: "",
      focus: false,
      inputError: false,
      errorMessage:
        "Invalid Input: Request correct ID or URL from the sender.",
    };
  },
  computed: {},
  methods: {
    goInputPlaylist() {
      // this.$router.push('/Library/pl=' + tag)
      let myTag = ''      
      this.myQuery = this.myQuery.trim()            
      if (this.myQuery.length < 10) {
        this.inputError = true;
        this.errorMessage =
          "Invalid Input: ID too short. Request correct ID from the sender.";          
      } else if (this.myQuery.length > 48) {
        this.inputError = true;
        this.errorMessage =
          "Invalid Input: URL too long. Request correct URL from the sender.";          
      } else {
        this.inputError = false
        if (this.myQuery.length > 20) {          
          myTag = this.myQuery.split("=")[1];
        } else {
          myTag = this.myQuery;
        }
        myTag = myTag.split('.')[0]
        this.$router.push("/Library/pl=" + myTag);                
      }      
    },
  },
};
</script>
 <style lang="scss" scoped>
</style>
