<template>

  <div>
    
       <!-- load the prgress bar if not loaded still
    shoudln't get stuck, but not tested thoroughly -->
    <v-row
      v-if="!loaded"
      class="fill-height"
      align-content="center"
      justify="center"
    >
      <v-col class="subtitle-1 text-center" cols="12">
        Getting your history
      </v-col>
      <v-col cols="6">
        <v-progress-linear
          color="deep-purple accent-4"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </v-row>
<v-alert
          v-model="alertSingIn"
          border="left"
          close-text="Close Alert"
          type="info"
          dark
          dismissible
          class="body-2"
          dense
          v-if="!authenticated"
          text
        >
          Sing In to save your chanting history.
        </v-alert>


<div v-if="loaded & ownedRecordsLocal!=null">
<showByTimeline v-if="showTimeline" :ownedRecordsLocal="ownedRecordsLocal"></showByTimeline>
<showByStats v-else :totalTime="totalTime" :totalSession="totalSession" :scoreLow="70" :scoreHigh="90"></showByStats>
</div>


<!-- add the timline/dashboard button -->      
      <v-fab-transition>
        <v-btn
          color="purple darken-3"
          fab
          dark
          :small="$vuetify.breakpoint.width < 800 ? true : false"
          fixed
          bottom
          left
          @click="showTimeline=!showTimeline"
        >
          <v-icon v-if="showTimeline">mdi-view-dashboard</v-icon>
          <v-icon v-if="!showTimeline">mdi-timeline</v-icon>
          
        </v-btn>
      </v-fab-transition>      
    
</div>
  
        
</template>

<script>
import showByTimeline from "./subcomponents/show-bytimeline.vue";
// import showByMantras from "./subcomponents/show-bymantras.vue";
import showByStats from "./subcomponents/show-bystats.vue";
import { mapState } from "vuex";
import { auth } from "@/main.js";

// import firebase from "firebase/app";
// import "firebase/firestore";
// var Statistics = require("statistics.js");
export default {
  data() {
    return {
      showTimeline: true,
      loaded: false,
      alertSingIn: true
    };
  },
  components: {
    showByTimeline,
    // showByMantras,
    showByStats,
  },
  mounted() {

    setTimeout(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        console.log("history/user");
        //authenticated
        //bind owned records
        this.bindToFirestoreOwnedRecords("ownedRecords");                
      } else {
        console.log("history/no user");
        //unauthenticated                      
        this.loaded = true;
      }
    })}, 50)    
  },
  computed: {
    ...mapState("parameters", ["pl", "authenticated"]),
    ...mapState("firestore", [
      "ownedRecordsOnServer"
    ]),
    ...mapState("firestoreSelf", [
      "ownedRecords", "ownedRecordsUnauth"
    ]),
    ...mapState("coretext", ["mantras"]),
    cssProps() {      
      return {
        "--offset":
          this.$vuetify.breakpoint.width < 1050
            ? "5px"
            : (this.$vuetify.breakpoint.width - 1050) / 2 + "px",
        "--center": this.$vuetify.breakpoint.width / 2 - 5 + "px",
      };
    },
    ownedRecordsLocal() {
      if(this.authenticated) {
        return this.ownedRecords
      } else {
        return this.ownedRecordsUnauth
      }
    },
    totalTime() {
      return Math.ceil(this.ownedRecordsLocal.map(a => (a.end - a.start)).reduce((a, b) => a + b, 0))
    },
    totalSession() {
      return this.ownedRecordsLocal.length
    },
  },
  methods: {    
    bindToFirestoreOwnedRecords(value) {
      let valueLocal = value
      value = value + 'OnServer'
      if (this[value] == null) {
        console.log("I am in bindToFireStore " + value);
        let myQuery = false
        if(this[valueLocal]!=undefined) {
          if(this[valueLocal].length>0) {
            myQuery = ["end", ">", this[valueLocal][this[valueLocal].length-1]['end']]
          }
        }
        console.log(myQuery)        
        this.$store.dispatch("firestore/bindUserdata", {
          path: "/userdata/" + auth.currentUser.uid + "/records",
          type: "collection",
          query: myQuery,
          whereToBind: value,
        }).then(()=>{
          this.loaded=true        
          // console.log(this.ownedRecordsOnServer)
          })
      } else {
        this.loaded=true        
        console.log(value + " already bound");
      }
    },
  }
};
</script>

 <style lang="scss" scoped>
.v-btn--fixed.v-btn--bottom {
  bottom: 60px;
}
.v-btn--fixed.v-btn--left {
  left: var(--offset);
}

</style>