<template>
<div>
  <div class="text-xs-center" v-if="!noPopup">
    <v-dialog      
      v-model="sheet"
      hide-overlay
      scrollable
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{}">
        <v-btn icon @click="sheet = true" fab>
          <v-icon color="blue lighten-3"> mdi-format-size</v-icon>
        </v-btn>
      </template>
  

    <v-card>    
      <v-toolbar flat dark color="primary" >
        <v-btn dark icon small @click.native="sheet = false">
          <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <div class="text-xs-center subheading">
        settings
      </div>    
      <v-spacer></v-spacer>
  <!-- <div class="subheading">{{(themeDark ? "Dark" : "Light")}}</div> -->
  <div class="caption">{{convert(script)}}</div>
      </v-toolbar>

    <v-card class="ma-3 pa-3" v-if="isScript" outlined shaped>
        <strong>Select script: </strong>
        <v-btn small  class="ma-2 pa-1" v-for="(item) in options.script" @click="script=item" :key="item" 
        :outlined="script===item" :rounded="script!==item">
          <span>{{convert(item)}}
            <v-icon small v-if="script===item">mdi-check </v-icon> </span></v-btn>
      </v-card>
            <v-card class="ma-3 pa-3" v-if="isTheme" outlined shaped>
            <strong>Dark theme: </strong>
            <v-switch
              v-model="themeDark"              
              color="warning"              
            ></v-switch>            
          </v-card>
          <v-card class="ma-3 pa-3" v-if="isColor" outlined shaped>
            <strong>Colored text: </strong>
            <v-switch
              v-model="coloredText"              
              color="success"              
            ></v-switch>            
          </v-card>
</v-card>

</v-dialog>
</div>

<div class="text-xs-center ma-1 mb-3" v-if="noPopup">
    

    <v-card>    

  <v-row class="text-subtitle-1 pa-2 ma-1">
      <span> Settings: </span>
          <v-spacer></v-spacer>
          <span class="info--text"> {{convert(script)}} | {{themeDark ? 'dark' : 'light'}} | {{coloredText ? 'colored' : 'plain'}} </span>
  </v-row>      

    <v-card class="ma-3 pa-3" v-if="isScript" outlined shaped>
        <strong>Select script: </strong>
        <v-btn small  class="ma-2 pa-1" v-for="(item) in options.script" @click="script=item" :key="item" 
        :outlined="script===item" :rounded="script!==item">
          <span>{{convert(item)}}
            <v-icon small v-if="script===item">mdi-check </v-icon> </span></v-btn>
      </v-card>

<v-row class="d-flex ma-1" justify="space-around">
            <v-card class="ma-3 pa-3 " v-if="isTheme" outlined shaped>
            <strong>Dark theme: </strong>
            <v-switch
              v-model="themeDark"              
              color="warning"              
            ></v-switch>            
          </v-card>
          <v-card class="ma-3 pa-3 " v-if="isColor" outlined shaped>
            <strong>Colored text: </strong>
            <v-switch
              v-model="coloredText"              
              color="success"              
            ></v-switch>            
          </v-card>
</v-row>
</v-card>

</div>
</div>

</template>

<script>
import { mapMutations } from "vuex";
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import Sanscript from "sanscript";

export default {
  props: {
    isLanguage: Boolean,
    isScript: Boolean,
    isTheme: Boolean,
    isFsize: Boolean,
    isColor: Boolean,
    noPopup: Boolean
  },
  data: () => ({
    sheet: false,
  }),
  computed: {
    ...mapState("settings", ["options"]),
    ...mapState("parameters", ["themeDark", "script", "userdata"]),
    ...mapGetters("settings", ["GET_dark"]),
    themeDark: {
      get() {
        return this.$store.state.parameters.themeDark;
      },
      set(value) {
        this.$vuetify.theme.isDark=value                
        this.SET_themeDark(value);        
        // console.log(this.$store.state.firestore.userdata)
      },
    },
    coloredText: {
      get() {
        return this.$store.state.parameters.coloredText;
      },
      set(value) {
        this.SET_coloredText(value);        
      },
    },
    script: {
      get() {
        return this.$store.state.parameters.script;
      },
      set(value) {
        this.SET_script(value);
      },
    },
  },
  methods: {
    ...mapMutations("parameters", ["SET_themeDark", "SET_script", "SET_coloredText"]),
    convert(myinput) {
      if (myinput === "iast") {
        return "English";
      } else {
        return Sanscript.t(this.options.script1[myinput], "itrans", myinput);
      }
    },
  },
};
</script>

<style scoped>
</style>
