<template>
  <div v-if="loaded"
    style="
       {
        word-wrap: break-word;
      }
    "
    :style="cssProps"
  >
    <!-- <v-row v-if="mantra.mantra.length<10000">       -->
      <v-row justify="center">    
      <v-col :cols="myWidth == 12 ? 12 : myWidth">
        <div class="d-flex justify-center pa-2">
          <div align="start" class="breakDiv">
            <span
              v-for="(item, i) in myMantra"
              :key="item + i"
              :style="[coloredText ? { color: myTextcolors[i] } : {}]"
            >
              <span
                v-for="(item1, j) in item.split(',')"
                :key="item1 + i + j + '_primary'"
              >
                <v-tooltip bottom v-if="translationON ? (translateON && myWords!==null) : (myWords!==null)">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="fSize1"
                      >{{ convert(item1, "primary") }}
                    </span>
                  </template>
                  <span
                    v-for="item3 in addBr(myWords[i].split(',')[j])"
                    :key="item3"                    
                    >{{ convert1(item3, "primary") }}
                    <br />
                  </span>
                </v-tooltip>
                <span v-else class="fSize1">{{ convert(item1, "primary") }} </span>
              </span>
              <br />
              <span
                v-if="secondScriptON ? transliterateON : addSecondScript"
                class="text--secondary"
              >
                <span
                  v-for="(item2, k) in item.split(',')"
                  :key="item2 + i + k + '_secondary'"
                  class="fSize1"
                  >{{ convert(item2, "secondary") }}
                </span>
                <br
              /></span>
            </span>            
          </div>               
          <div>
            <div class="ml-2">
                 <div v-if="mantra.mantra.length>1">                     
              <div v-if="mantra.hasOwnProperty('skipStart')">
            <div
              class="font-weight-light caption ml-2"
              :style="{whiteSpace: 'nowrap'}" 
              v-if="mantra.Functionality == 1 && whichVerse >= mantra.skipStart && (whichVerse < mantra.skipEnd)">               
              ({{ (whichVerse - mantra.skipStart) + 1 }})
            </div>
            <div
              class="font-weight-light caption ml-2"
              :style="{whiteSpace: 'nowrap'}" 
              v-if="mantra.Functionality == 2 && (( (whichVerse - mantra.skipStart) % 10)==0)">               
              ({{ (whichVerse - mantra.skipStart) + 1 }})
            </div>
              </div>
            <div
              class="font-weight-light caption ml-2"
              :style="{whiteSpace: 'nowrap'}" 
              v-else>               
              ({{ whichVerse + 1 }})
            </div>                        
                 </div>                 
                 <div v-if="!parallelON && mantra.Functionality!=2 && myWords!==null">
            <v-btn fab x-small text class="font-weight-bold">
                <v-icon
                  @click="breakDownON = false"
                  v-if="breakDownON"
                  color="success"
                  >mdi-box-cutter</v-icon
                >
                <v-icon
                  @click="breakDownON = true"
                  v-if="!breakDownON"
                  color="error"
                >
                  mdi-box-cutter-off</v-icon
                >
              </v-btn>  
            </div>       
            </div>                        
          </div>
        </div>
      </v-col>

<div v-if="!parallelON ? breakDownON : false" >
<div v-if="translationON ? (translateON && myWords!==null) : (myWords!==null)" class="mx-4">
      <v-col :cols="12" 
      v-bind:style="[{columnCount: $vuetify.breakpoint.width < 700? 2 : (myWords.length < 4 ? 2 : 3)}, 
      {columnRule: 'solid 1px'}]">
          <div align="start">
            <div
              v-for="(item, i) in myMantra"
              :key="item + i"
              :style="[coloredText ? { color: myTextcolors[i] } : {}]"
            >
              <div
                v-for="(item1, j) in item.split(',')"
                :key="item1 + i + j + '_primary'"
              >
                  <div
                    class="mb-1 body-1 adjustLineHeight"
                    v-for="item3 in addBr(myWords[i].split(',')[j])"
                    :key="item3"                    
                    >{{ convert1(item3, "primary") }}                  
                  </div>                                 
              </div>            
              </div>
          </div>          
      </v-col>  
      </div>      
</div>
      <v-col :cols="myWidth == 12 ? 12 : 12 - myWidth" v-if="translationON ? (translateON && myTranslation!==null) : (myTranslation!==null)" align-self="center">        
          <div class="justify-center d-flex px-3 pb-3" :class="skipInfo ? 'mb-5' : false">            
          <div align="start">
            <span
              v-for="(item, i) in myTranslation"
              :key="item + i"
              :style="[coloredText ? { color: myTextcolors[i] } : {}]"
              v-html="item"
              class="fSize2"
            >
            </span>
          </div>
          </div>          
      </v-col>        
    </v-row>
    <!-- <v-row v-else>
      {{ convert(myMantra, "primary") }}
    </v-row>       -->
  </div>
</template>

<script>
// import { mapState } from "vuex";
import Sanscript from "sanscript";
// import savePlaylist from "./save-playlist";
export default {
  props: {
    mantra: Object,
    whichVerse: Number,
    script: String,
    skipInfo: Boolean,
    secondScriptON: Boolean,
    translationON: Boolean,
    zoomON: Boolean,
    parallelON: Boolean,
    searchSelect: Array,
    themeDark: Boolean,
    coloredText: Boolean,
    translateON: Boolean,
    transliterateON: Boolean,
    zoomText: Number,
    parallelViewON: Boolean,
    cssProps: Object,
    myWidth: Number,
    addSecondScript: Boolean
  },
  components: {
    // savePlaylist,
  },
  data() {
    return {
      // secondScriptOn: true,
      // saveSuccessMsg: false,
      // saveSuccessInfo: "",
      isActiveItem: false,
      loaded: true,
      breakDownON: false,

      // transOn: true,
    };
  },
  mounted() {
    // setTimeout(()=>{this.loaded=true},100)
    //  console.log(this.mantras)
    // console.log(this.mantra.mantraenglish.length)
  },
  computed: {
    // ...mapState("coretext", ["mantras"]),
    // ...mapState("parameters", [
    //   "searchSelect",
    //   "themeDark",
    //   "coloredText",
    //   "translateON",
    //   "transliterateON",
    //   "zoomText",
    //   "parallelViewON",
    // ]),
    // cssProps() {
    //   if(this.$vuetify.breakpoint.width < 600) {
    //   return {
    //     "--myfontsize": this.zoomON ? this.zoomText + "rem" : "1.15rem",
    //   };
    //   } else {
    //     return {
    //     "--myfontsize": this.zoomON ? this.zoomText + "rem" : "1.3rem",
    //   };
    //   }
    // },
    // myWidth() {
    //   if (this.parallelON) {
    //     if (this.parallelViewON) {
    //       if (this.translateON) {
    //         return 6;
    //       } else {
    //         return 12;
    //       }
    //     } else {
    //       return 12;
    //     }
    //   } else {
    //     return 12;
    //   }
    // },
    myMantra() {      
      return this.mantra.mantra[this.whichVerse].split(";");      
    },
    myWords() {      
      if(this.mantra.wordenglish.length==0) {
        return null
      } else if(this.mantra.wordenglish[this.whichVerse]=="" || this.mantra.wordenglish[this.whichVerse]==undefined) {
        return null
      } else {
      return this.mantra.wordenglish[this.whichVerse].split(";");
      }
    },
    myTranslation() {
      if(this.mantra.mantraenglish.length==0) {
        return null
      } else if(this.mantra.mantraenglish[this.whichVerse]=="" || this.mantra.mantraenglish[this.whichVerse]==undefined) {
        return null
      } else {
      return this.mantra.mantraenglish[this.whichVerse].split("/");
      }
    },
    myTextcolors() {
      if (this.themeDark) {
        return ["#00E5FF", "#FFC400", "#76FF03", "#F48FB1", "#EEFF41", "#AED4A1", "#B39DDB",  "#B39DDB", "#B39DDB", "#B39DDB", "#B39DDB", "#B39DDB"];
      } else {
        return ["#43A047", "#9C27B0", "#03A9F4", "#FF5722", "#827717", "#E127A0",  "#B39DDB", "#B39DDB", "#B39DDB", "#B39DDB", "#B39DDB", "#B39DDB"];
      }
    },
  },
  methods: {
    // saveSuccessMsgAlert(value) {
    //   this.saveSuccessMsg = true;
    //   this.saveSuccessInfo = value;
    // },
    convert(myinput, option) {
      if (option === "primary") {
        return Sanscript.t(myinput, "itrans", this.script);
      } else {
        let secondScript = "iast";
        if (this.script === "iast" || this.script === "itrans") {
          secondScript = "devanagari";
        }
        return Sanscript.t(myinput, "itrans", secondScript);
      }
    },
    addBr(val) {
      return val.split("/");
    },
    convert1(val) {
      let a = val.split("=");
      return this.convert(a[0], "primary") + " = " + a[1];
    },
  },
};
</script>
 <style lang="scss" scoped>
.v-tooltip__content {
  background: rgb(230, 74, 25, 1);
  color: #f1e9e9;
  border-radius: 6px;
  font-size: 14px;
}
span {
  font-size: 1.15rem;
}
.breakDiv {
  word-break: break-all;
}
.fSize1 {
  font-size: var(--myfontsize);
}
.fSize2 {
  font-size: var(--myfontsize);
}
.adjustLineHeight {
  line-height: 1.15em;
}
</style>