<template>
  <div>
        <loadPlaylistBar></loadPlaylistBar>
        <v-divider class="my-5"></v-divider>    
    <div v-if="sharedPlaylists!=null">
      <div v-if="sharedPlaylists.length >0">
        <div class="text-subtitle-1" align="center">My Playlists </div>
    <v-card
          v-for="item in sharedPlaylists"
          :key="item.tag + 'shared'"
          class="pa-4 mx-0 my-2"
        >
          <v-row>
            <v-col cols="9">
              <span> {{ item.name }} </span>
              <div class="body-2 text--secondary">
                {{ item.owner }} &#183; {{ item.mantras.length }}
                {{ item.mantras.length > 1 ? "mantras" : "mantra" }} &#183;
                {{ extractDHMS(item.timestamp) }}
              </div>
            </v-col>
            <v-col align="right">
              <playlistInfo
                :item="item"
                :mantras="mantras"
                sharedPlaylist
              ></playlistInfo>
            </v-col>
          </v-row>
        </v-card>
    </div>
  </div>

  

  </div>
</template>


<script>
import { mapState, mapMutations } from "vuex";
import playlistInfo from "./subcomponents/playlist-info.vue"
import loadPlaylistBar from "./load-playlist-bar.vue"

export default {
  data() {
    return {      
    };
  },
  components: {
    playlistInfo,
    loadPlaylistBar
  },
  computed: {
    ...mapState("firestore", ["sharedPlaylists"]),    
    ...mapState("coretext", ["mantras"]),
    ...mapState("parameters", ["authenticated", "pl", "plUnauth"]),
  },
  mounted() {
    console.log(this.sharedPlaylists)
    console.log('I am in render-playlist-unauth.vue')
  },
  methods: {
    ...mapMutations("parameters", ["SET_value"]),
    loadSelectedPlaylist(item) {      
      let playlistId = item.tag;
      let myTempPath = "/" + "Library" + "/" + "pl=" + playlistId;
      this.$router.push(myTempPath);      
    },    
    extractDHMS(millsecs) {
      let a = new Date(millsecs);
      a = a.toLocaleString().split(",");
      let b = new Date();
      b = b.toLocaleString().split(",");      
      if (a[0] == b[0]) {
        let temp1 = a[1].trim().split(" ");
        let temp2 = temp1[0].split(":");
        return temp2[0] + ":" + temp2[1] + " " + temp1[1];
      } else {
        return a[0];
      }
    },
    viewPlaylist(item) {
      let playlistId = item.tag;      
      let myTempPath =
        // "https://powermantra.web.app" +
        "/" +
        "Library" +
        "/" +        
        "pl=" +
        playlistId;        
        this.$router.push(myTempPath)
    }      
  },
};
</script>
 <style lang="scss" scoped>
</style>