<template>
  <div>
    <v-row>
      <v-toolbar
        dense
        @click="
          $router.push('/Explore/'), SET_value({ list: 0, id: 'addItem' })
        "
      >
        <v-btn icon>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title class="text--secondary"
          >Exit Content Editor</v-toolbar-title
        >
      </v-toolbar>
    </v-row>

    <div align="center" class="ma-5">
      <v-btn class="ma-5" @click="$store.state.addContent.mode='add'" :color="mode=='add' ? 'red' : ''">Add New</v-btn>
      <v-btn class="ma-5" @click="$store.state.addContent.mode='edit'" :color="mode=='edit' ? 'red' : ''">Edit Current</v-btn>
    </div>
    <v-divider></v-divider>
    <addNew v-if="mode=='add'"></addNew>      
    <addEdit v-if="mode=='edit'"></addEdit>      
  </div>
</template>


<script>
import { mapState, mapMutations } from "vuex";
import addNew from "./add-new.vue";
import addEdit from "./add-edit.vue";
export default {
  data() {
    return {      
    };
  },
  components: { addNew, addEdit},
  mounted() {
  
  },
  computed: {
    ...mapState("addContent", ["mode"]),    
  },
  methods: {
    ...mapMutations("parameters", ["SET_value"]),    
  },
};
</script>
 <style lang="scss" scoped>

</style>
