const SET_mainItem = (state, input) => {
  state.mainItem = input
};
const SET_authenticated = (state, input) => {
  state.authenticated = input
};
const SET_photoURL = (state, input) => {
  state.photoURL = input
};
const SET_userName = (state, input) => {
  state.userName = input
};
const SET_path = (state, input) => {
  state.path = input
};
const SET_themeDark = (state, input) => {
  state.themeDark = input
};
const SET_coloredText = (state, input) => {
  state.coloredText = input
};
const SET_script = (state, input) => {
  state.script = input
};
const SET_userClaims = (state, input) => {
  state.userClaims = input
};
function SET_value(state, { list, id }) {
  state[id] = list;
  //some tags must be handled in a special way 
  if (id == 'mn' & list!=null) {
    let myValues = list.split('$')
    // console.log(myValues)
    state.searchSelect = [{
      mantra_id: parseInt(myValues[0]),
      verse_id: parseInt(myValues[1]===undefined ? 0 : myValues[1]),
    }]    
    // console.log(state.searchSelect)
  }
}
  const SET_searchSelect = (state, input) => {
    state.searchSelect = input
  }
  const SET_alphabetSelect = (state, input) => {
    state.alphabetSelect[input[0]][input[1]] = !state.alphabetSelect[input[0]][input[1]]
  }
  // const SET_chantingON = (state, input) => {
  //   state.searchSelect = input
  // }


  export default {
    SET_mainItem,
    SET_authenticated,
    SET_photoURL,
    SET_userName,
    SET_path,
    SET_themeDark,
    SET_coloredText,
    SET_script,
    SET_userClaims,
    SET_value,
    SET_searchSelect,
    SET_alphabetSelect
  };
