
const state = {
  mode: 'add', //which mode for the conent editor
  addHolder: null, //for adding items
  myYTLinks: 1, //for keeping track of number of YT links
  primaryScript: "itrans", //for adding mantras editor
  secondaryScript: "devanagari", //for adding mantras editor      
  addHolderVerses: [{
    myMantra: "",
    myMantraSplit: [],
    myMnatraSplitTranslate: [],
    myMantraSplitWord: [],
    myMantraSplitWordEdit: [],
    myMantraSplitWordEditTrans: [],
    myMantraSplitWordEditTransFinal: [],
  }], //for adding verses
  editHolder: null, //for editing an item
  whichMantra: 'OM', //which mantra to edit
  whichFirestoreItem: 'Purnam Mantra', //which mantra on firestore to edit
  whichMode: 'local', //is it a local version or from firestore?
  editHolderVerses: [{
    myMantra: "",
    myMantraSplit: [],
    myMnatraSplitTranslate: [],
    myMantraSplitWord: [],
    myMantraSplitWordEdit: [],
    myMantraSplitWordEditTrans: [],
    myMantraSplitWordEditTransFinal: [],
  }], //for adding verses
};

export default {
  namespaced: true,
  state,
};
