var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.skipInfo)?_c('div',[_c('v-row',{staticClass:"ma-3",attrs:{"justify":"space-between","align":"center"}},[_c('div',{staticClass:"info--text"},[_vm._v("Sanskrit with translation")]),(!_vm.itemIndexOFF)?_c('div',[(_vm.mainItem=='Explore')?_c('savePlaylist',{attrs:{"whichMantra":_vm.mantra.id},on:{"saveSuccess":_vm.saveSuccessMsgAlert}}):_vm._e()],1):_vm._e()]),(!_vm.parallelON)?_c('v-row',{staticClass:"mx-5 mb-1",attrs:{"justify":"start"}},[_c('v-btn',{staticClass:"font-weight-bold",attrs:{"fab":"","small":""}},[(_vm.addSecondScript)?_c('v-icon',{attrs:{"color":"success"},on:{"click":function($event){_vm.addSecondScript = false}}},[_vm._v("mdi-translate")]):_vm._e(),(!_vm.addSecondScript)?_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){_vm.addSecondScript = true}}},[_vm._v(" mdi-translate-off")]):_vm._e()],1)],1):_vm._e(),_c('v-alert',{staticClass:"body-2",attrs:{"border":"left","close-text":"Close Alert","color":"success","dark":"","dismissible":"","dense":""},model:{value:(_vm.saveSuccessMsg),callback:function ($$v) {_vm.saveSuccessMsg=$$v},expression:"saveSuccessMsg"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.mantra.name)+" ")]),_vm._v(" was added to "),_c('strong',[_vm._v(" "+_vm._s(_vm.saveSuccessInfo)+" ")]),_vm._v(". Go to "),_c('strong',[_vm._v(" Library ")]),_vm._v(" for your playlists. ")])],1):_vm._e(),_vm._l((_vm.mantra.mantra),function(item,i){return _c('div',{key:_vm.mantra.Name + i,class:(_vm.myIdChantMode(i) == _vm.myItemTracker[_vm.currentChantIndex]) &
          (_vm.mainItem == 'Library') &
          _vm.cl ||
        (_vm.myIdChantMode(i) == _vm.myItemTrackerExplore[_vm.currentChantIndexExplore]) &
          (_vm.mainItem == 'Explore') &
          _vm.ce
          ? 'addActiveBorder'
          : false,attrs:{"id":_vm.myIdChantMode(i)}},[_c('v-card',{staticClass:"pa-2",attrs:{"flat":"","ripple":_vm.mantra.mantra.length==1 && ((_vm.ce || _vm.cl) && (_vm.playON || _vm.playONExplore))}},[(_vm.animationON)?_c('v-btn',{staticClass:"overlap text-none",attrs:{"text":"","x-large":_vm.$vuetify.breakpoint.width > 960 ? true : false}},[_c('div',{staticClass:"parent"},[_c('span',{staticClass:"warning--text child",attrs:{"id":"myChantButtonShadow"}},[_vm._v(_vm._s(_vm.convert("svAhA", "secondary")))]),_c('span',{staticClass:"success--text child",attrs:{"id":"myChantButtonShadow"}},[_vm._v(_vm._s(_vm.convert("svAhA", "primary")))])])]):_vm._e(),(
            (_vm.myIdChantMode(i) == _vm.myItemTracker[_vm.currentChantIndex]) & _vm.playON ||
            (_vm.myIdChantMode(i) ==
              _vm.myItemTrackerExplore[_vm.currentChantIndexExplore]) &
              _vm.playONExplore
          )?_c('v-btn',{staticClass:"overlap",attrs:{"id":"myChantButton","x-large":_vm.$vuetify.breakpoint.width > 960 ? true : false,"fab":"","plain":"","disabled":_vm.animationON},on:{"click":function($event){return _vm.proceedChant(i)}}},[_c('v-img',{attrs:{"width":"40","src":"/img/gif/hawan2_1024x1024.gif"}})],1):_vm._e(),_c('renderOneVerse',{attrs:{"id":_vm.myId(i),"mantra":_vm.mantra,"whichVerse":i,"script":_vm.script,"skipInfo":_vm.skipInfo,"secondScriptON":_vm.secondScriptON,"translationON":_vm.translationON,"zoomON":_vm.zoomON,"parallelON":_vm.parallelON,"searchSelect":_vm.searchSelect,"themeDark":_vm.themeDark,"coloredText":_vm.coloredText,"translateON":_vm.translateON,"transliterateON":_vm.transliterateON,"zoomText":_vm.zoomText,"parallelViewON":_vm.parallelViewON,"cssProps":_vm.cssProps,"myWidth":_vm.myWidth,"addSecondScript":_vm.addSecondScript}})],1)],1)}),(_vm.loaded  && _vm.ce && _vm.chantTimesExplore < 109)?_c('div',[(_vm.mainItem=='Explore' && _vm.mantra.mantra.length==1)?_c('renderMala',{attrs:{"duration":3000}}):_vm._e()],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }