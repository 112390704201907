<template>
  <div>
    <v-sheet min-height="150" class="fill-height" color="transparent">
      <!-- <v-sheet class="fill-height" color="transparent"> -->
      <!-- <v-lazy
      v-model="isActiveItem"
      :options="{
        threshold: 0,
      }"
    > -->

      <v-row
        v-if="!itemsLoaded"
        class="fill-height"
        align-content="center"
        justify="center"
      >
        <v-col class="subtitle-1 text-center" cols="12">
          Getting your mantras
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="deep-purple accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>

      <v-container v-if="mantra">
        <v-row
          :class="$vuetify.breakpoint.width < 600 ? 'shrinkRowMargin' : false"
        >
          <v-col>
            <v-card>
              <v-row
                v-if="!skipInfo"
                align="center"
                justify="center"
                class="ma-2 pa-2"
              >
                <span
                  class="font-weight-light"
                  :class="
                    $vuetify.breakpoint.width > 600 ? 'text-h6' : 'text-body-1'
                  "
                  >{{ mantra.Name }}</span
                >
              </v-row>
              <v-row v-if="skipInfo" class="ma-1">
                <span class="font-weight-light text--secondary ma-1"
                  >{{ !itemIndexOFF ? itemIndex + " | " : "" }}
                  {{ mantra.Name }}</span
                >
              </v-row>
              <div v-if="!skipInfo">
                <v-row align="center" justify="space-around" class="ma-1">
                  <v-btn
                    :text="!backgroundOn"
                    :dark="backgroundOn"
                    :class="{ 'info darken-1': backgroundOn }"
                    class="text-none"
                    @click="false"
                  >
                    <v-icon left> mdi-information </v-icon> Info
                  </v-btn>
                  <v-btn
                    :text="!backgroundOn"
                    :dark="backgroundOn"
                    :class="{ 'purple darken-3': backgroundOn }"
                    class="text-none"
                    @click="showAudio = !showAudio"
                  >
                    <v-icon left> mdi-waveform </v-icon> Audio
                    <v-icon small v-if="showAudio">mdi-check </v-icon>
                  </v-btn>
                  <v-btn
                    :text="!backgroundOn"
                    :dark="backgroundOn"
                    :class="{ 'red darken-1': backgroundOn }"
                    class="text-none"
                    @click="showYoutube = !showYoutube"
                  >
                    <v-icon left> mdi-youtube </v-icon> Youtube
                    <v-icon small v-if="showYoutube">mdi-check </v-icon>
                  </v-btn>
                </v-row>
                <div align="center" class="mt-2" v-if="showAudio">
                  <v-row justify="space-between" class="ma-0 px-4 mb-3">
                    <span class="info--text"> Recommended audio rendition</span>
                    <v-btn
                      small
                      text
                      @click="showAudio = false"
                      class="text--secondary"
                      ><v-icon>mdi-close</v-icon></v-btn
                    >
                  </v-row>
                  <youtube
                    :video-id="mantra.Audio.split('=')[1]"
                    ref="audio"
                    fitParent
                  ></youtube>
                </div>
                <div align="center" class="my-2" v-if="showYoutube">
                  <v-row justify="space-between" class="ma-0 px-4 mb-3">
                    <span class="info--text">
                      Check out these youtube videos</span
                    >
                    <v-btn
                      small
                      text
                      @click="showYoutube = false"
                      class="text--secondary"
                      ><v-icon>mdi-close</v-icon></v-btn
                    >
                  </v-row>
                  <v-pagination
                    v-model="videoPage"
                    :length="mantra.Youtube.length"
                  ></v-pagination>
                  <youtube
                    :video-id="mantra.Youtube[videoPage - 1].split('=')[1]"
                    ref="youtube"
                    fitParent
                  ></youtube>
                </div>

                <v-row justify="space-between" align="center" class="ma-3">
                  <span class="info--text my-5"
                    >Background on {{ mantra.Name }}</span
                  >
                  <v-btn text small rounded class="text--secondary">
                    <v-icon @click="backgroundOn = false" v-if="backgroundOn"
                      >mdi-chevron-down</v-icon
                    >
                    <v-icon @click="backgroundOn = true" v-if="!backgroundOn">
                      mdi-chevron-up</v-icon
                    >
                  </v-btn>
                </v-row>

                <v-fade-transition leave-absolute>
                  <div v-if="backgroundOn">
                    <!-- <v-card-text class="text--primary text-body-1"> -->
                    <div class="fsIncrease pa-3 font-weight-light">
                      This is {{ mantra.Category }}. The diety of this mantra is
                      {{ mantra.Deity }}. This mantra embodies the quality of
                      {{ mantra.Quality }}. The primary application of this
                      mantra is {{ mantra.Application }}. The recitation of
                      {{ mantra.Name }} will {{ mantra.Benefits }}.
                    </div>
                    <!-- </v-card-text> -->
                    <v-tabs v-model="tab" right>
                      <v-tab v-for="item in items" :key="item" class="body-1">
                        {{ item }}
                      </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                      <v-tab-item v-for="item in items" :key="item">
                        <v-card color="basil" flat>
                          <!-- <v-card-text class="text--primary body-1"> -->
                          <div class="fsIncrease pa-3 font-weight-light">
                            {{ mantra[item] }}
                          </div>
                          <!-- </v-card-text>                           -->
                        </v-card>
                      </v-tab-item>
                    </v-tabs-items>
                  </div>
                </v-fade-transition>
              </div>
              <div align="end" class="mx-4">
                <socialSharing
                  :sharing="sharingInfo()"
                  v-if="!skipInfo"
                ></socialSharing>
              </div>
              <div>
                <renderMantra
                  @hook:mounted="itemsLoaded = true"
                  v-if="loaded"
                  :mantra="mantra"
                  :script="script"
                  :mantra_id="mantra_id"
                  :skipInfo="skipInfo"
                  :secondScriptON="secondScriptON"
                  :translationON="translationON"
                  :zoomON="zoomON"
                  :parallelON="parallelON"
                  :itemIndex="itemIndex"
                  :itemIndexOFF="itemIndexOFF"
                ></renderMantra>
              </div>
              <div v-if="loaded && !itemIndexOFF">
                <v-card
                  class="pa-4 my-3"
                  outlined
                  shaped
                  v-if="!skipInfo & (mantra.linkto != null) & !skipLinkto"
                >                
                  <v-row>
                    <v-subheader class="text-body-1"
                      >You may also be interested in:</v-subheader
                    >
                    <v-chip
                      v-for="(item, i) in mantra.linkto"
                      :key="i"
                      class="ma-1"
                      outlined
                      @click="switchItem(item)"
                      >{{ mantras[item - 1].Name }}</v-chip
                    >
                  </v-row>
                </v-card>
              </div>
            </v-card>
            <v-divider></v-divider>
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-container>
      <!-- </v-lazy> -->
    </v-sheet>
  </div>
</template>


<script>
import renderMantra from "./subcomponents/render-mantra";
import socialSharing from "@/components/library/subcomponents/social-sharing.vue";
import { mapMutations } from "vuex";
export default {
  props: {
    mantra: Object,
    script: String,
    mantra_id: Number,
    skipInfo: Boolean,
    itemIndex: Number,
    secondScriptON: Boolean,
    translationON: Boolean,
    zoomON: Boolean,
    parallelON: Boolean,
    mantras: Array,
    itemIndexOFF: Boolean,
    skipLinkto: Boolean
  },
  data() {
    return {
      tab: null,
      items: ["Significance", "How to chant"],
      showAudio: false,
      showYoutube: false,
      audioPlaying: null,
      isActiveItem: false,
      backgroundOn: true,
      videoPage: 1,
      loaded: false,
      itemsLoaded: false,
    };
  },
  components: {
    renderMantra,
    socialSharing,
  },
  mounted() {
    setTimeout(() => {
      this.loaded = true;
      // this.itemsLoaded =  true
    }, 300);
  },
  computed: {},
  methods: {
    ...mapMutations("parameters", ["SET_value"]),
    loadedRenderMantra() {
      console.log("loadedRenderMantra()");
    },
    switchItem(id) {
      this.$store.state.parameters.browseBy = false
      this.SET_value({
        list: [{ mantra_id: id - 1, verse_id: 0 }],
        id: "searchSelect",
      });
      this.SET_value({
        list: this.mantras[id - 1].Name,
        id: "selectedMantra",
      });
      this.SET_value({ list: 0, id: "chantTimesDoneExplore" });
      this.SET_value({ list: 11, id: "chantTimesExplore" });
      this.SET_value({ list: 0, id: "currentChantIndexExplore" });
      this.SET_value({ list: null, id: "myTime1Explore" });
      setTimeout(() => {
        let temp = "#ID_mantra" + (id - 1) + "verse" + 0;
        this.$vuetify.goTo(temp, {
          duration: 300,
          offset: 0,
          easing: "easeInOutCubic",
        });
      }, 100);
      this.$router.push("/Explore/mn=" + (id - 1) + "$0");
    },
    sharingInfo() {
      let myTempPath =
        "https://powermantra.app" +
        "/" +
        "Explore" +
        "/" +
        "mn=" +
        this.mantra_id +
        "$0";
      return {
        url: myTempPath,
        title: this.mantra.Name,
        description:
          "The goal of Power Mantra is to organize popular sanskrit mantras and make them easy to study and recite.",
        quote:
          "Mantrams are collection of phrases, words and sounds which by virtue of rhythmic effect achieve results that would not be possible apart from them.. - Master Djwhal Khul",
        hashtags: "mantra, meditation, yoga",
      };
    },
  },
};
</script>
 <style lang="scss" scoped>
.fsIncrease {
  font-size: 1.15rem;
}
.shrinkRowMargin {
  margin-left: -28px;
  margin-right: -28px;
}
</style>