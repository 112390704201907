<template>
  <v-app>
    <!-- <v-app-bar app color="#190933" dense>       -->
    <!-- <v-app-bar app color="#1f0b40" dense>       -->
    <v-app-bar app color="#230c47" dense dark v-show="!($vuetify.breakpoint.sm ?
    $vuetify.breakpoint.width > $vuetify.breakpoint.height: false)">
      <v-app-bar-title>
        <v-avatar size="28px" tile>
          <img
            :src="'/img/icons/android-chrome-192x192.png'"
            alt="Error Loading"
          />
        </v-avatar>
        <span
          :class="$vuetify.breakpoint.xs ? 'caption' : 'h6'"
          v-if="(mainItem == 'Library' & cl) || (mainItem == 'Explore' & ce)"
        >
          <sub v-if="$vuetify.breakpoint.width > 500"> Power Mantra </sub>
        </span>
        <span class="pa-2 h6" v-else> Power Mantra</span>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <div
        class="
          #230c47
          d-flex
          flex-row
          justify-center
          align-center
          font-weight-light
        "
      >
      <div v-if="(mainItem == 'Library') & cl">      
        <span class="mr-4" 
          >{{ chantTimesDone }}
          <v-icon class="ma-0" small>mdi-repeat</v-icon>
          {{ chantTimes }}</span
        >
        <span class="text-h6">{{
          millisToHousrAndMinutesAndSeconds(myTime1)
        }}</span>
        <span class="ml-4"
          >{{ currentChantIndex + 1 }}
          <v-icon class="ma-0" small>mdi-stairs-up</v-icon>
          {{ myItemTracker.length }}</span
        >
      </div>
      <div v-if="(mainItem == 'Explore') & ce">      
        <span class="mr-4" 
          >{{ chantTimesDoneExplore }}
          <v-icon class="ma-0" small>mdi-repeat</v-icon>
          {{ chantTimesExplore }}</span
        >
        <span class="text-h6">{{
          millisToHousrAndMinutesAndSeconds(myTime1Explore)
        }}</span>
        <span class="ml-4"
          >{{ currentChantIndexExplore + 1 }}
          <v-icon class="ma-0" small>mdi-stairs-up</v-icon>
          {{ myItemTrackerExplore.length }}</span
        >
      </div>
      </div>
      <v-spacer></v-spacer>
      <v-btn :small="$vuetify.breakpoint.width < 500" icon color="#B3E5FC" @click="goToSearchExplore()" v-if="!(playON || playONExplore)"><v-icon>mdi-magnify</v-icon></v-btn>
      <settingsPopup isScript isTheme isColor v-if="!(playON || playONExplore)"></settingsPopup>      
      <div v-if="!this.authenticated" class="white--text ma-1 pa-1">
        <v-btn
          @click.native.stop="dialog = true"
          class="white--text text-body-1 pa-0 ma-1 text-none"
          color="orange darken-3"
        >
          Sing In
        </v-btn>
        <v-dialog v-model="dialog">
          <firebaseAuth></firebaseAuth>
        </v-dialog>
      </div>
      <div v-else>
        <userProfile></userProfile>
      </div>
    </v-app-bar>
    <!-- add this bar just for small screens in landscape mode only when the play is ON to show the timing information-->
<v-btn fab top left fixed plain dark
v-if="($vuetify.breakpoint.sm ?
    ($vuetify.breakpoint.width > $vuetify.breakpoint.height) && (playON || playONExplore): false)">
<div
        class="                    
          grey darken-2
          d-flex
          flex-row
          justify-center
          align-center
          font-weight-light
          vertical          
          px-2
        "
      >
      <div v-if="(mainItem == 'Library') & cl">      
        <span class="mr-4" 
          >{{ chantTimesDone }}
          <v-icon class="ma-0" small>mdi-repeat</v-icon>
          {{ chantTimes }}</span
        >
        <span class="text-h6">{{
          millisToHousrAndMinutesAndSeconds(myTime1)
        }}</span>
        <span class="ml-4"
          >{{ currentChantIndex + 1 }}
          <v-icon class="ma-0" small>mdi-stairs-up</v-icon>
          {{ myItemTracker.length }}</span
        >
      </div>
      <div v-if="(mainItem == 'Explore') & ce">      
        <span class="mr-4" 
          >{{ chantTimesDoneExplore }}
          <v-icon class="ma-0" small>mdi-repeat</v-icon>
          {{ chantTimesExplore }}</span
        >
        <span class="text-h6">{{
          millisToHousrAndMinutesAndSeconds(myTime1Explore)
        }}</span>
        <span class="ml-4"
          >{{ currentChantIndexExplore + 1 }}
          <v-icon class="ma-0" small>mdi-stairs-up</v-icon>
          {{ myItemTrackerExplore.length }}</span
        >
      </div>
      </div>
</v-btn>
    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
        <router-view></router-view>
        <!-- <span>{{lengthOfOwnedPlaylistsOnServer}}</span>
        <span>{{lengthOfOwnedRecordsOnServer}}</span> -->
      </v-container>
    </v-main>

    <!-- dynamic bottom navigation -->
    <v-bottom-navigation v-model="mainItem" app height="56" grow v-show="!($vuetify.breakpoint.sm ?
    $vuetify.breakpoint.width > $vuetify.breakpoint.height: false)">
      <v-btn
        v-for="(zz, k) in menu.mainItems"
        :key="zz"
        :value="zz"
        color="primary--text"
      >
        <span :id="`main_${zz}`">{{ zz }}</span>
        <v-icon>{{ menu.mainIcons[k] }}</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-app>
</template>






<script>
import firebaseAuth from "./firebase-auth.vue";
import userProfile from "./user-profile.vue";
import settingsPopup from "@/components/settings/settings-popup.vue";
import { mapState, mapMutations } from "vuex";
import { auth } from "@/main.js";
export default {
  data() {
    return {
      dialog: false, // open authentication dialog
      myTimer: null, // timer for the chanting/playON, to place the timer in root component is important
      myTimerExplore: null, // timer for the chanting/playON/Explore, to place the timer in root component is important
    };
  },
  name: "App",
  components: {
    firebaseAuth,
    userProfile,
    settingsPopup,
  },
  computed: {
    ...mapState("settings", ["options", "menu", "pathTag"]),
    ...mapState("firestore", [
      "ownedPlaylistsOnServer",
      "ownedRecordsOnServer",
      "myOwnedList",
    ]),
    ...mapState("firestoreSelf", ["ownedPlaylists", "ownedRecords"]),
    ...mapState("parameters", [
      "authenticated",
      "photoURL",
      "script",
      "fsize",
      "themeDark",
      "pl",
      "mn",
      "playON",
      "myTime0",
      "myTime1",
      "myTime1Offset",
      "cl",
      "currentChantIndex",
      "myItemTracker",
      "chantTimes",
      "chantTimesDone",
      "addItem",
      "playONExplore",
      "myTime0Explore",
      "myTime1Explore",
      "myTime1OffsetExplore",
      "ce",            
      "chantTimesExplore",
      "chantTimesDoneExplore",
      "currentChantIndexExplore",
      "myItemTrackerExplore",
    ]),
    // lengthOfOwnedPlaylistsOnServer() {
    // },
    // lengthOfOwnedRecordsOnServer()
    // },
    mainItem: {
      get() {
        if (this.$store.state.parameters.mainItem != "Library") {
          this.SET_value({ list: false, id: "playON" });
        }
        if (this.$store.state.parameters.mainItem != "Explore") {
          this.SET_value({ list: false, id: "playONExplore" });
        }        
        return this.$store.state.parameters.mainItem;
      },
      set(value) {        
        this.SET_mainItem(value); //sync store with the click
        //to retain the previous state within each mainItem we would need to push out the
        //correct path when we switch the mainItem
        //since mainItem is reactive this will be executed everytime we switch mainItem
        //this is a better way than adding a watcher
        //go to settings/pathTag to update the tags
        //any number of tags can be added now as long as the correct mechanism to act on the tag
        //once updated exists
        let myTempPath = "/" + this.mainItem + "/"; //beging the path handle
        for (let index = 0; index < this.pathTag.length; index++) {
          //cycle through all possible path tags and append
          if (
            //if mainItem equals the pathtag and value of the tag is non-null then append the tag
            this.mainItem == this.pathTag[index].path &&
            this[this.pathTag[index].data] != null
          ) {
            //if a tag is already appended then append &
            if (myTempPath.includes("=")) {
              myTempPath = myTempPath + "&";
            } //now append the actual tag with its current value in the store
            myTempPath =
              myTempPath +
              this.pathTag[index].data +
              "=" +
              this[this.pathTag[index].data];
          }
        }
        // console.log(myTempPath);
        //older simpler implementation
        // if(this.mainItem=='Library' & this.pl!==null) {
        //           myTempPath = myTempPath + "/pl=" + this.pl
        //         }
        //now push out the correct path
        this.$router.push(myTempPath);
      },
    },
  },
  methods: {
    ...mapMutations("parameters", [
      "SET_authenticated",
      "SET_photoURL",
      "SET_mainItem",
      "SET_userName",
      "SET_value",
    ]),
    goToSearchExplore() {
      this.SET_value({ list: false, id: "playONExplore" });
      this.SET_value({ list: false, id: "ce" });
      this.SET_mainItem("Explore")
      console.log(this.$route.path)
      if(this.$route.path!="/Explore" && this.$route.path!="/Explore/") {this.$router.push("/Explore/")}
      setTimeout(()=>{this.$vuetify.goTo(
              "#ExploreSearch",
              {
                duration: 10,
                offset: 100,
                easing: "linear",
              }
            )}, 1000)

    },
    millisToHousrAndMinutesAndSeconds(milliseconds) {
      //Get hours from milliseconds
      var hours = milliseconds / (1000 * 60 * 60);
      var absoluteHours = Math.floor(hours);
      // var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;
      var h = absoluteHours == 0 ? "" : absoluteHours + ":";

      //Get remainder from hours and convert to minutes
      var minutes = (hours - absoluteHours) * 60;
      var absoluteMinutes = Math.floor(minutes);
      // var m = absoluteMinutes > 9 ? absoluteMinutes : '0' +  absoluteMinutes;
      var m = absoluteMinutes + ":";

      //Get remainder from minutes and convert to seconds
      var seconds = (minutes - absoluteMinutes) * 60;
      var absoluteSeconds = Math.floor(seconds);
      var s = absoluteSeconds > 9 ? absoluteSeconds : "0" + absoluteSeconds;
      return h + m + s;
      // return h + ':' + m + ':' + s;
    },
  },
  mounted() {
    // this.$store.state.firestoreSelf.ownedPlaylists = null
    // console.log(this.ownedPlaylists);
    // console.log(auth)
    // auth.onAuthStateChanged((user) => {console.log(auth.currentUser.uid, user)})
    // setTimeout(()=>console.log(auth.currentUser.uid), 1000)
    // let temp = auth.currentUser.uid
    // console.log(temp)
    // this.$nextTick((auth) => console.log(auth.currentUser.uid))
    // console.log('I was here')   
    setTimeout(()=>{
      this.$store.state.coretext.mantras.sort((a, b) => a.id - b.id)
    },
    1000) 
    this.$store.state.parameters.addHolder = null;
    this.$store.state.parameters.localCopy = null;
    this.SET_value({ list: false, id: "inputErrorEditPlaylist1" });
    this.SET_value({ list: false, id: "inputErrorEditPlaylist2" });
    this.SET_value({ list: null, id: "editPlaylistIndex" });
    this.SET_value({ list: false, id: "playON" });
    this.SET_value({ list: 0, id: "currentChantIndex" });
    this.SET_value({ list: null, id: "myTime0" });
    this.SET_value({ list: null, id: "myTime1" });
    this.SET_value({ list: 0, id: "myTime1Offset" });
    this.SET_value({ list: [], id: "myTime" });
    this.SET_value({ list: [], id: "myItemTracker" });
    this.SET_value({ list: [], id: "myItemTrackerSave" });
    this.SET_value({ list: 1, id: "chantTimes" });
    this.SET_value({ list: 0, id: "chantTimesDone" });
    this.SET_value({ list: false, id: "resultReady" });
    

    auth.onAuthStateChanged((user) => {
      if (user) {
        this.SET_authenticated(true);
        this.SET_photoURL(user.photoURL);
        this.SET_userName(user.displayName);

        //synch with the localCache for this authorized user
        //this entertains multiple authorized users using the same device
        if(this.$store.state.firestoreSelf.ownedPlaylistsAuth!=null) {
          if(auth.currentUser.uid in this.$store.state.firestoreSelf.ownedPlaylistsAuth) {
        this.$store.state.firestoreSelf.ownedPlaylists = this.$store.state.firestoreSelf.ownedPlaylistsAuth[auth.currentUser.uid]
          } else {
            this.$store.state.firestoreSelf.ownedPlaylists = null
          }
        } else {
          this.$store.state.firestoreSelf.ownedPlaylists = null
        }
        //for Records
        if(this.$store.state.firestoreSelf.ownedRecordsAuth!=null) {
          if(auth.currentUser.uid in this.$store.state.firestoreSelf.ownedRecordsAuth) {
        this.$store.state.firestoreSelf.ownedRecords = this.$store.state.firestoreSelf.ownedRecordsAuth[auth.currentUser.uid]
          } else {
            this.$store.state.firestoreSelf.ownedRecords = null
          }
        } else {
          this.$store.state.firestoreSelf.ownedRecords = null
        }        
      } else {
        this.SET_authenticated(false);
        this.SET_photoURL("not signed in");
      }
    });
    this.$vuetify.theme.isDark = this.themeDark;

    document.addEventListener("visibilitychange", () => {
      if (document.hidden) this.SET_value({ list: false, id: "playON" });
    });
    document.addEventListener("visibilitychange", () => {
      if (document.hidden) this.SET_value({ list: false, id: "playONExplore" });
    });
  },
  watch: {
    playON: function () {
      if (this.playON) {
        this.myTimer = setInterval(() => {
          let delta = Date.now() - this.myTime0; // milliseconds elapsed since start
          this.SET_value({
            list: Math.ceil(delta) + this.myTime1Offset,
            id: "myTime1",
          }); // in seconds
        }, 1000); // update about every second
        return;
      } else {
        this.SET_value({ list: this.myTime1, id: "myTime1Offset" });
        clearInterval(this.myTimer);
        return;
      }
    },
    playONExplore: function () {
      if (this.playONExplore) {
        this.myTimerExplore = setInterval(() => {
          let delta = Date.now() - this.myTime0Explore; // milliseconds elapsed since start
          this.SET_value({
            list: Math.ceil(delta) + this.myTime1OffsetExplore,
            id: "myTime1Explore",
          }); // in seconds
        }, 1000); // update about every second
        return;
      } else {
        this.SET_value({ list: this.myTime1Explore, id: "myTime1OffsetExplore" });
        clearInterval(this.myTimerExplore);
        return;
      }
    },
    ownedPlaylistsOnServer: function () {
      // console.log(this.ownedPlaylistsOnServer);
      // console.log(this.ownedPlaylists);
      console.log("I am in watcher ownedPlaylistsOnServer");
      //define a computed property that reacts to any change in varOnServer
      //varOnServer is bound to firestore through vuexfire binding
      //if the binding has occured (!=null) then apply the following steps
      //1. check if the var is null or non-null
      //2. if null and varOnServer length is > 0 then copy "sorted" varOnServer to var
      //3. if non-null and varOnServer length is >0 then find the entries in varOnServer
      //that are more recent then the last entry in var and if there are entries which
      //are indeed more recent, then first sort them and then push those into var
      //this way var is always updated and sorted on timestamp
      //var will be persisted through vuex-persistence
      if (this.ownedPlaylistsOnServer != null) {
        if (this.ownedPlaylists == null) {
          if (this.ownedPlaylistsOnServer.length > 0) {
            this.$store.state.firestoreSelf.ownedPlaylists =
              this.ownedPlaylistsOnServer
                .map((el) => Object.assign(el, { idLocal: el.id }))
                .sort((a, b) => a.timestamp - b.timestamp);
          }
        } else {
          if (this.ownedPlaylistsOnServer.length > 0) {
            let myItemsToAdd = this.ownedPlaylistsOnServer
              .map((el) => Object.assign(el, { idLocal: el.id }))
              .filter((a) => {
                return (
                  a.timestamp >
                  this.ownedPlaylists[this.ownedPlaylists.length - 1].timestamp
                );
              });
            // console.log(myItemsToAdd);
            // console.log(myItemsToAdd.sort((a, b) => a.timestamp - b.timestamp));
            if (myItemsToAdd != undefined) {
              myItemsToAdd = myItemsToAdd.sort(
                (a, b) => a.timestamp - b.timestamp
              );
              let tagsMyItemsToAdd = myItemsToAdd.map((a) => a.tag);
              this.$store.state.firestoreSelf.ownedPlaylists =
                this.$store.state.firestoreSelf.ownedPlaylists.filter((a) => {
                  return !tagsMyItemsToAdd.includes(a.tag);
                });
              this.$store.state.firestoreSelf.ownedPlaylists.push(
                ...myItemsToAdd
              );
            }
          }
          // console.log("before splicing");
          // console.log(this.ownedPlaylists);
          if (this.ownedPlaylists.length == 2) {
            if (
              this.ownedPlaylists[0].idLocal == this.ownedPlaylists[1].idLocal
            ) {
              this.$store.state.firestoreSelf.ownedPlaylists[0] = Object.assign(
                this.$store.state.firestoreSelf.ownedPlaylists[1]
              );
              this.$store.state.firestoreSelf.ownedPlaylists.splice(1, 1);
            }
          }
        }
      }
      if(this.$store.state.firestoreSelf.ownedPlaylistsAuth==null) {
        this.$store.state.firestoreSelf.ownedPlaylistsAuth = {}
      } 
      this.$store.state.firestoreSelf.ownedPlaylistsAuth[auth.currentUser.uid] = this.$store.state.firestoreSelf.ownedPlaylists       
    },
    ownedRecordsOnServer: function () {
      console.log("I am in watcher ownedRecordsOnServer");
      if (this.ownedRecordsOnServer != null) {
        if (this.ownedRecords == null) {
          if (this.ownedRecordsOnServer.length > 0) {
            this.$store.state.firestoreSelf.ownedRecords =
              this.ownedRecordsOnServer
                .map((el) => Object.assign(el, { idLocal: el.id }))
                .sort((a, b) => a.end - b.end);
          }
        } else {
          if (this.ownedRecordsOnServer.length > 0) {
            let myItemsToAdd = this.ownedRecordsOnServer
              .map((el) => Object.assign(el, { idLocal: el.id }))
              .filter((a) => {
                return (
                  a.end > this.ownedRecords[this.ownedRecords.length - 1].end
                );
              });
            if (myItemsToAdd != undefined) {
              myItemsToAdd = myItemsToAdd.sort((a, b) => a.end - b.end);
              //do we really need to remove duplicates here?
              //not sure buy anyway we will do it
              let endTimesMyItemsToAdd = myItemsToAdd.map((a) => a.end);
              this.$store.state.firestoreSelf.ownedRecords =
                this.$store.state.firestoreSelf.ownedRecords.filter((a) => {
                  return !endTimesMyItemsToAdd.includes(a.end);
                });
              this.$store.state.firestoreSelf.ownedRecords.push(
                ...myItemsToAdd
              );
            }
          }
          if (this.ownedRecords.length == 2) {
            if (this.ownedRecords[0].idLocal == this.ownedRecords[1].idLocal) {
              this.$store.state.firestoreSelf.ownedRecords[0] = Object.assign(
                this.$store.state.firestoreSelf.ownedRecords[1]
              );
              this.$store.state.firestoreSelf.ownedRecords.splice(1, 1);
            }
          }
        }
      } 
      //save to the localAuth copy
      if(this.$store.state.firestoreSelf.ownedRecordsAuth==null) {
        this.$store.state.firestoreSelf.ownedRecordsAuth = {}
      }
      this.$store.state.firestoreSelf.ownedRecordsAuth[auth.currentUser.uid] = this.$store.state.firestoreSelf.ownedRecords
    },
    //will need to add watcher for deleted records for syncing with local copyy
    myOwnedList: function () {
      console.log("I am in watcher myOwnedList");
      if (this.myOwnedList != null) {
        if (this.ownedPlaylists != null) {          
          if (this.ownedPlaylists.length > 0) {
            // console.log('I am in syncOwnedPlaylistsWithServerCopy')
            //silly mistake by not including myOwnedList.tag
            //myOwnedList cannot and should not work
            // console.log(this.myOwnedList)
            let myItemsToKeep = this.ownedPlaylists.filter((a) => {
              return this.myOwnedList.tag.includes(a.tag);
            });
            this.$store.state.firestoreSelf.ownedPlaylists = myItemsToKeep;
          }
        }
      }
      if(this.$store.state.firestoreSelf.ownedPlaylistsAuth==null) {
        this.$store.state.firestoreSelf.ownedPlaylistsAuth = {}
      } 
      this.$store.state.firestoreSelf.ownedPlaylistsAuth[auth.currentUser.uid] = this.$store.state.firestoreSelf.ownedPlaylists       
    }
}     
};
</script>

<style lang="scss">
.container {
  max-width: 960px;
}
.vertical {  
  transform: rotate(270deg);  
}
.v-btn--fixed.v-btn--top {
  top: 100px;
  left: -10px;
}
</style>
