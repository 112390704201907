import { render, staticRenderFns } from "./sanskrit-pronounce.vue?vue&type=template&id=8d349088&scoped=true&"
import script from "./sanskrit-pronounce.vue?vue&type=script&lang=js&"
export * from "./sanskrit-pronounce.vue?vue&type=script&lang=js&"
import style0 from "./sanskrit-pronounce.vue?vue&type=style&index=0&id=8d349088&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d349088",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VBottomSheet,VBtn,VCard,VCol,VDivider,VFlex,VIcon,VRow,VSubheader})
