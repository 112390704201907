<template>
  <div :style="cssProps">

    <v-row align="center" class="ma-5">
      
      <!-- add basic fields -->

      <v-row justify="center" class="ma-5 text-h6 info--text">
        <v-col> Add An Item Below </v-col>
        <v-col><v-btn @click="loadTemplate()">Reset</v-btn></v-col>
        <v-col><v-btn @click="downloadFile(addHolder, true)">download</v-btn></v-col>

        <!-- add the download dialogue -->
        <v-col>
          <v-dialog v-model="downloadDialog" max-width="325px">
            <template v-slot:activator="{ attrs }">
              <v-fab-transition>
                <v-btn v-bind="attrs" @click="downloadDialog = true">
                  download from cloud
                </v-btn>
              </v-fab-transition>
            </template>
            <v-card outlined rounded ref="form">
              <v-card-text>
                <v-container>
                  <v-row justify="space-between" align="center">
                    <span class="text-subtitle-1"> Download From Cloud </span>
                    <v-btn icon @click="downloadDialog = false" dark>
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-row>
                  <v-row class="mt-5">
                    <v-text-field
                      v-model="cloudName"
                      outlined
                      label="Mantra Name"
                    ></v-text-field>
                    <v-text-field
                      v-model="cloudAuthor"
                      outlined
                      label="Author Name"
                    ></v-text-field>
                  </v-row>
                  <v-row justify="space-around">
                    <v-card-actions>
                      <v-btn
                        color="green darken-1"
                        @click="downloadDialog = false"
                        dark
                      >
                        Cancel
                      </v-btn>
                    </v-card-actions>
                    <v-card-actions>
                      <v-btn
                        color="red darken-1"
                        dark
                        v-on:click="downloadFromCloud()"
                      >
                        Download
                      </v-btn>
                    </v-card-actions>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>

      <v-col v-if="addHolder != null">
        <v-row v-for="item in myFields1" :key="item">
          <v-text-field
            dense
            outlined
            v-model="addHolder[item]"
            :label="item"
            :placeholder="item"
            :rules="[rulesField.required]"
          ></v-text-field>
        </v-row>
        <!-- add youtube links -->
        <v-row v-for="index in myYTLinks" :key="'YT' + index">
          <v-text-field
            dense
            outlined
            v-model="addHolder['Youtube'][index - 1]"
            :label="'Youtube Link' + index"
            :placeholder="'Youtube Link' + index"
            :rules="[rulesField.required]"
          ></v-text-field>

          <v-btn
            class="ml-5"
            small
            v-if="index == myYTLinks"
            @click="myYTLinks += 1"
            ><v-icon>mdi-plus</v-icon></v-btn
          >
        </v-row>
        <!-- add descriptive fields -->
        <v-row
          v-for="(item, myf2) in myFields2"
          :key="'myfiled2' + myf2"
          cols="12"
        >
          <v-textarea
            outlined
            v-model="addHolder[item]"
            :label="item"
            :placeholder="item"
            :rows="item == 'Benefits' ? 2 : 7"
            :rules="[rulesArea.required]"
          ></v-textarea>
        </v-row>

        <v-row justify="center" class="ma-5"> Input Mantra Below </v-row>

        <!-- add mantra -->
        <!-- add menu for script selection   -->
        <v-row align="center">
          <v-col class="d-flex" cols="12" sm="6">
            <v-select
              :items="myScripts"
              label="Input Script"
              dense
              outlined
              v-model="primaryScript"
            ></v-select>
          </v-col>

          <v-col class="d-flex" cols="12" sm="6">
            <v-select
              :items="myScripts"
              outlined
              label="Output Script"
              dense
              v-model="secondaryScript"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-for="(item, vv) in addHolderVerses" :key="'verse' + vv">
          Important: Always use । or ।।. Always have white-space before । and ।।.
          <v-col cols="12" class="addBorder my-3">
            <v-row justify="end" class="mx-1 success--text text-h6">
              verse {{ vv + 1 }}
            </v-row>
            <addMantra
              :verse="item"
              :verseIndex="vv"
              :primaryScript="primaryScript"
              :secondaryScript="secondaryScript"
              class="pa-2"
            ></addMantra>
          </v-col>
          <v-col cols="12">
            <v-btn
              color="red darken-4"
              dark
              v-if="vv + 1 == addHolderVerses.length"
              @click="addOnemoreVerse()"
              ><v-icon>mdi-plus</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-btn @click="refreshRenderer()"
        >Mantra Refresh<v-icon>mdi-refresh</v-icon></v-btn
      >
    </v-row>
    <renderItem
      v-if="showRendering"
      :mantra="addHolder"
      :script="secondaryScript"
      parallelON
    >
    </renderItem>

    <!-- add the upload dialogue -->
    <div>
      <v-dialog v-model="updateDialog" max-width="250px">
        <template v-slot:activator="{ attrs }">
          <v-fab-transition>
            <v-btn
              v-bind="attrs"
              fab
              fixed
              bottom
              left
              small
              dark
              color="info darken-2"
              @click="updateDialog = true"
            >
              <v-icon>mdi-cloud-upload</v-icon>
            </v-btn>
          </v-fab-transition>
        </template>
        <v-card outlined rounded ref="form">
          <v-card-text>
            <v-container>
              <v-row justify="space-between" align="center">
                <span class="text-subtitle-1"> Power Mantra </span>
                <v-btn icon @click="updateDialog = false" dark>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-row>
              <div class="text-subtitle-1 mt-5">
                Upload changes to the cloud?
              </div>
              <v-card-actions>
                <v-btn
                  color="green darken-1"
                  @click="updateDialog = false"
                  dark
                >
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" dark v-on:click="saveItem()">
                  OK
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>


<script>
import { mapState, mapMutations } from "vuex";
import addMantra from "./add-mantra.vue";
import renderItem from "@/components/explore/render-item";
import { db, auth } from "@/main.js";
export default {
  data() {
    return {
      showRendering: true,
      myFields1: [
        "Name",
        "Source",
        "Category",
        "Quality",
        "Deity",
        "Application",
        "Audio",
      ],
      myFields2: ["Benefits", "Significance", "How to chant"],
      myFields3: ["Youtube"],
      myScripts: ["itrans", "devanagari", "iast"],
      updateDialog: false,
      downloadDialog: false,
      cloudName: "",
      cloudAuthor: "",
      rulesField: {
        required: (value) => !!value || "Required."
      },
      rulesArea: {
        required: (value) => !!value || "Required."
      }
    };
  },
  components: { addMantra, renderItem },
  mounted() {
    this.SET_value({ list: true, id: "addItemVisited" });
  },
  computed: {
    ...mapState("addContent", ["addHolder", "addHolderVerses"]),
    ...mapState("coretext", ["mantras"]),
    cssProps() {
      // console.log(this.myPlaylistLocal);
      return {
        "--offset":
          this.$vuetify.breakpoint.width < 1050
            ? "5px"
            : (this.$vuetify.breakpoint.width - 1050) / 2 + "px",
        "--center": this.$vuetify.breakpoint.width / 2 - 5 + "px",
      };
    },
    primaryScript: {
      get() {
        return this.$store.state.addContent.primaryScript;
      },
      set(value) {
        this.$store.state.addContent.primaryScript = value;
      },
    },
    secondaryScript: {
      get() {
        return this.$store.state.addContent.secondaryScript;
      },
      set(value) {
        this.$store.state.addContent.secondaryScript = value;
      },
    },
    myYTLinks: {
      get() {
        return this.$store.state.addContent.myYTLinks;
      },
      set(value) {
        this.$store.state.addContent.myYTLinks = value;
      },
    },
  },
  methods: {
    ...mapMutations("parameters", ["SET_value"]),
    downloadFile(val, addTimestamp = false) {      
      if(addTimestamp) {
      let dt = new Date();
      let timestamp = Math.ceil(dt.getTime());
      val.timestamp = timestamp;
      }
      const ordered = Object.keys(val).sort().reduce(
        (obj, key) => { 
          obj[key] = val[key]; 
          return obj;
          }, 
          {});
      const data = JSON.stringify(ordered, undefined, 2);
      const blob = new Blob([data], { type: "text/plain" });
      const e = document.createEvent("MouseEvents"),
        a = document.createElement("a");
      a.download = val.Name + ".json";
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ["text/json", a.download, a.href].join(":");
      e.initEvent(
        "click",
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
      a.dispatchEvent(e);
    },
    downloadFromCloud() {
      db.collectionGroup("items")
        .where("Name", "==", this.cloudName)
        .where("Author", "==", this.cloudAuthor)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            this.downloadFile(doc.data());
          });
        });
      this.downloadDialog = false;
    },
    saveItem() {
      let dt = new Date();
      let timestamp = Math.ceil(dt.getTime());
      this.addHolder.timestamp = timestamp;
      this.addHolder.Author = auth.currentUser.displayName;
      db.collection("additem")
        .doc(auth.currentUser.uid)
        .collection("items")
        .doc(this.addHolder.Name)
        .set(this.addHolder);
      this.updateDialog = false;
    },
    refreshRenderer() {
      this.showRendering = false;
      setTimeout(() => {
        this.showRendering = true;
      }, 10);
    },
    addOnemoreVerse() {
      this.$store.state.addContent.addHolderVerses.push({
        myMantra: "",
        myMantraSplit: [],
        myMnatraSplitTranslate: [],
        myMantraSplitWord: [],
        myMantraSplitWordEdit: [],
        myMantraSplitWordEditTrans: [],
        myMantraSplitWordEditTransFinal: [],
      });
    },
    loadTemplate() {
      // for a brand new copy
      this.$store.state.addContent.addHolder = JSON.parse(
        JSON.stringify(this.mantras[0])
      );
      this.$store.state.addContent.addHolderVerses = [];
      this.addOnemoreVerse();
      this.myYTLinks = 1
    },
  },
};
</script>
 <style lang="scss" scoped>
.addBorder {
  border: 0.5px solid rgb(236, 54, 8);
}
.v-btn--fixed.v-btn--bottom {
  bottom: 70px;
}
.v-btn--fixed.v-btn--right {
  right: var(--offset);
}
.v-btn--fixed.v-btn--left {
  left: var(--offset);
}
</style>
