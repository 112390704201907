export const options = {
  script: [
    'iast',
    'devanagari',
    'gujarati',
    'tamil',
    'telugu',
    'malayalam',
    'kannada',
    'bengali',
    'gurmukhi',
    'oriya',
    'itrans',
    'slp1'
  ],
  script1: {
    iast: "English",
    devanagari: "devanaagaree",
    gujarati: "gujarAtI",
    tamil: 'taamil',
    telugu: 'telugoo',
    malayalam: 'malayaalam',
    kannada: 'kannad',
    bengali: 'bengaalee',
    gurmukhi: 'gurmukhI',
    oriya: "OrIyA" ,
    itrans: "itrans",
    slp1: "slp1"
  },
  fsize: ['small', 'medium', 'big'],
  fsizeInternal: {small: 'body-1', medium: 'subheading', big: 'title'},
  fsizeAvailable: ['caption', 'body-1', 'subheading', 'title', 'headline', 'display-1', 'display-2', 'display-3', 'display-4']
}
