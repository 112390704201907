import { firestoreAction } from 'vuexfire'
import { db } from '@/main.js'

const state = {
  sharedPlaylists: null,
  mySharedList: null,
  myOwnedList: null,
  ownedPlaylistsOnServer: null,  
  ownedRecordsOnServer: null,  
  addedItems: null
};

export default {
  namespaced: true,
  state,
  actions: {
    bindUserdata: firestoreAction(({ bindFirestoreRef }, payload) => {
      
      if(payload.type == "collection") {
        if(payload.query) {
          return bindFirestoreRef(payload.whereToBind, db.collection(payload.path)
        .where(payload.query[0], payload.query[1], payload.query[2]))
        } else {          
          return bindFirestoreRef(payload.whereToBind, db.collection(payload.path))          
        }
      }

      if(payload.type == "collectionGroup") {
        if(payload.query) {
          return bindFirestoreRef(payload.whereToBind, db.collectionGroup(payload.path)
        .where(payload.query[0], payload.query[1], payload.query[2]))
        } else {
          return undefined
        }
      }

      if(payload.type == "document") {
        if(payload.query) {
          return undefined  
        } else {
          return bindFirestoreRef(payload.whereToBind, db.doc(payload.path))
        }        
      }    
  
    }),
    unbindAllPlaylists: firestoreAction(({ unbindFirestoreRef }) => {      
      unbindFirestoreRef('sharedPlaylists')
      unbindFirestoreRef('mySharedList')      
      unbindFirestoreRef('ownedPlaylistsOnServer')
      unbindFirestoreRef('ownedRecordsOnServer')     
      unbindFirestoreRef('addedItems')     
    }),
  }
} 
