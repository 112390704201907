<template>
  <div align="center" justify="space-between" class="ma-0">

    <v-row class="pa-3">
      <v-toolbar dense @click="goBack()" v-if="!playONExplore">
        <v-btn icon>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title class="text--secondary">Exit Chanting</v-toolbar-title>
      </v-toolbar>
    </v-row>

    <!-- alert when chanting is complete -->

      <v-alert
        v-model="resultProcessed"
        border="left"
        close-text="Close Alert"
        type="success"
        dark
        dismissible
        text
      >
        You chanted <strong> {{ selectedMantra }} </strong>
        {{ chantTimesExplore == 1 ? "once." : chantTimesExplore + " times." }}
        {{ resultProcessedMsg }}
      </v-alert>
    

    <!-- :id="`chant${i + 1}`" -->
    <!-- :itemIndex="i + 1" -->
    <renderItem
      :mantra="mantras[selectedMantraIndex]"
      :mantra_id="selectedMantraIndex + 1"
      :script="script"
      :itemIndex="1"
      skipInfo
      secondScriptON
      translationON
      zoomON
      parallelON
      :mantras="mantras"
      itemIndexOFF
    >
    </renderItem>
<mediaRecorder :currentVerse="currentChantIndexExplore+chantTimesDoneExplore" :doRecord="playONExplore"></mediaRecorder>
    <v-fab-transition>
      <chantingSettings SingleItem v-if="!playONExplore"></chantingSettings>
    </v-fab-transition>
  </div>
</template>


<script>
import mediaRecorder from "@/components/library/subcomponents/media-recorder"
import renderItem from "@/components/explore/render-item";
import chantingSettings from "@/components/library/subcomponents/chanting-settings";
import { mapState, mapMutations } from "vuex";
import { db, auth } from "@/main.js";
var Statistics = require("statistics.js");
import Sanscript from "sanscript";
export default {
  data() {
    return {
      resultProcessed: false,
      resultProcessedMsg: ''
    };
  },
  components: {
    renderItem,
    chantingSettings,
    mediaRecorder
  },
  mounted() {
    setTimeout(() => {
      this.jumpToCurrentLocationExplore("byElement");
      this.myItemTrackerSaveExplore.map(el => {
        let temp = el.split('v')
        let vv = parseInt(temp[1])
        let mm = parseInt(temp[0].split('m')[1])
        this.computeDuration(this.mantras[mm].mantra[vv])
      })
    }, 500);
  },
  beforeUnmount() {},
  computed: {
    ...mapState("parameters", [
      "playONExplore",
      "myTime0Explore",
      "myTime1Explore",
      "selectedMantra",
      "selectedMantraIndex",
      "script",
      "currentChantIndexExplore",
      "resultReadyExplore",
      "myItemTrackerExplore",
      "chantTimesExplore",
      "chantTimesDoneExplore",
      "myItemTrackerSaveExplore",
      "myTimeExplore",
      "myTimeStartExplore",
      "authenticated",
      "speechAnalysisResults"
    ]),
    ...mapState("coretext", ["mantras", "sanskritAlphabetDuration"]),
  },
  methods: {
    ...mapMutations("parameters", ["SET_value"]),
    goBack() {
      this.SET_value({ list: false, id: "playONExplore" });
      this.SET_value({ list: false, id: "ce" });
      // this.SET_value({ list: 0, id: "currentChantIndex" });
      // this.SET_value({ list: null, id: "myTime0" });
      // this.SET_value({ list: null, id: "myTime1" });
      // this.SET_value({ list: 0, id: "myTime1Offset" });
      // this.SET_value({ list: [], id: "myTime" });
      // this.SET_value({ list: [], id: "myItemTracker" });
      // this.SET_value({ list: [], id: "myItemTrackerSave" });
      // this.SET_value({ list: 1, id: "chantTimes" });
      // this.SET_value({ list: 0, id: "chantTimesDone" });
      // this.SET_value({ list: false, id: "resultReady" });
      // this.$router.push("/Explore");
    },
    jumpToCurrentLocationExplore(val) {
      //we don't want to jump if index is 0
      //we jump in two modes: by element or by caculating the px numbers
      //in one-by-one clicking the latter is preffered for a smooth scrolling
      //when we are at the top of the page the former is preffered
      if (this.currentChantIndexExplore >= 1) {
        if (val == "byElement") {
          if (
            //if the scrollHeight is too large then the later elements are not rendered/identified
            //scroll to the bottom of the page in that case
            document.getElementById(
              this.myItemTrackerExplore[this.currentChantIndexExplore]
            ) == null
          ) {
            this.$vuetify.goTo(document.body.scrollHeight - 500, {
              duration: 300,
              offset: 0,
              easing: "linear",
            });
          } else {
            this.$vuetify.goTo(
              "#" + this.myItemTrackerExplore[this.currentChantIndexExplore],
              {
                duration: 300,
                offset: 100,
                easing: "linear",
              }
            );
          }
          return;
        } else {
          // if (this.currentChantIndexExplore < this.myPlaylistLocal.mantras.length) {
          if (
            this.currentChantIndexExplore < this.myItemTrackerExplore.length
          ) {
            //addOffset is the px we are not counting
            //48 is the dense toolbar height in px
            //in verse mode offset of 68 is needed
            //this was identified through "select an element" in the developers tool
            let addOffset =
              this.myItemTrackerExplore[this.currentChantIndexExplore].split(
                "verse"
              )[1] == "0"
                ? 68
                : 0;
            let myTop = window.pageYOffset;
            let el = document.getElementById(
              this.myItemTrackerExplore[this.currentChantIndexExplore]
            );
            // console.log(el)
            // console.log(this.myItemTracker)
            // console.log(this.myItemTracker[this.currentChantIndex].split('verse')[1])
            let style = window.getComputedStyle(el);
            //this was the earlier dimension requirement, but now just height is enough
            //with the addOffset
            //  let height = ["height", "padding-top", "padding-bottom", "margin-top"]
            let height = ["height"]
              .map((key) => parseInt(style.getPropertyValue(key), 10))
              .reduce((prev, cur) => prev + cur);
            //check for comment and determine the comment height
            let heightComment = 0;
            if (
              this.myItemTrackerExplore[this.currentChantIndexExplore].split(
                "verse"
              )[1] == "0"
            ) {
              let tempelIdx = this.myItemTrackerExplore[
                this.currentChantIndexExplore
              ]
                .split("verse")[0]
                .split("item")[1];
              el = document.getElementById("comment" + parseInt(tempelIdx));
              style = window.getComputedStyle(el);
              heightComment = ["height"]
                .map((key) => parseInt(style.getPropertyValue(key), 10))
                .reduce((prev, cur) => prev + cur);
              //  if(heightComment>0) {
              //  heightComment = heightComment - 8
              //  }
              // console.log(el)
              // console.log(heightComment)
            }
            this.$vuetify.goTo(
              myTop + (height + 48 + addOffset) + heightComment,
              {
                duration: 500,
                offset: 0,
                easing: "easeInOutCubic",
              }
            );
            return;
          }
        }
      } else {
        // console.log("I am here");
        this.$vuetify.goTo(0, {
          duration: 100,
          offset: 0,
          easing: "linear",
        });
        return;
      }
    },
    saveMyResult() {
      let dt = new Date();
      let endTimeExplore = Math.ceil(dt.getTime());
      let myTempRecord = {
          name: this.selectedMantra,
          tag: 'singleItem',
          verses: this.myItemTrackerSaveExplore,
          owner: this.authenticated ? auth.currentUser.displayName : '',
          timeline: this.myTimeExplore,
          start: this.myTimeStartExplore,
          end: endTimeExplore,
        }
      if(this.authenticated) {  
      db.collection("userdata")
        .doc(auth.currentUser.uid)
        .collection("records")
        .add(myTempRecord);
        } else {
          this.$store.state.firestoreSelf.ownedRecordsUnauth = [myTempRecord]
        }
      this.processMyResults();
      // this.nameBox = false;
      // this.dialog = false;
      // this.$emit("saveSuccess", this.playlistName.trim());
    },
    convert(myinput) {      
        return Sanscript.t(myinput, "itrans", 'slp1');      
    },
    computeDuration(val) {
      let mySLP1 = this.convert(val)
      let myTotal = 0
      for (let index = 0; index < mySLP1.length; index++) {
        if(mySLP1[index]=="H") {
          myTotal = myTotal + this.sanskritAlphabetDuration[mySLP1[index-1]];        
        } else {
        myTotal = myTotal + this.sanskritAlphabetDuration[mySLP1[index]];        
        }
      }
      myTotal = myTotal + 2
      // console.log(myTotal)
    },
    processMyResults() {      
      this.myItemTrackerSaveExplore.map(el => {
        let temp = el.split('v')
        let vv = parseInt(temp[1])
        let mm = parseInt(temp[0].split('m')[1])
        this.computeDuration(this.mantras[mm].mantra[vv])
      })
      let data = []
      for (let index = 0; index < (this.myTimeExplore.length-1); index++) {
        data[index] = {ID: index+1, time: this.myTimeExplore[index+1]-this.myTimeExplore[index]}
      }
// var data = [
      //   { ID: 1, age: 33 },
      //   { ID: 2, age: 42 },
      //   { ID: 3, age: 27 },
      // ];

      var columns = {
        ID: "ordinal",
        time: "interval",
      };

      var settings = {};

      var stats = new Statistics(data, columns, settings);
      let xScore = stats.standardDeviation("time")/stats.arithmeticMean("time")
      xScore = xScore >= 1? 0: (1 - xScore)*100
      xScore = Math.round(xScore)
      console.log('I am in processMyResults')
      console.log(this.speechAnalysisResults)

      // var meanAge = stats.arithmeticMean("age");
      // var stdDevAge = stats.standardDeviation("age");
      // var tmp = stats.sum("age");
      // console.log(meanAge, stdDevAge, tmp)
      // columns = {...Array(this.myTime.length / this.myItemTrackerSave.length).fill('metric')}
      // data = dataArray
      // stats = new Statistics(data, columns, settings);
      // console.log(stats.correlationCoefficient('0', '1').correlationCoefficient)
      this.resultProcessed = true;
      this.resultProcessedMsg = "Score = "+xScore+"%" //"Score=x, Speed=y.";
    },
  },
  watch: {
    currentChantIndexExplore: function () {
      this.jumpToCurrentLocationExplore();
    },
    playONExplore: function () {
      if (this.playONExplore) {
        this.jumpToCurrentLocationExplore("byElement");
      }
    },
    selectedMantra: function() {
      this.resultProcessed = false
      this.resultProcessedMsg = ''
    },
    resultReadyExplore: function () {
      if (this.resultReadyExplore) {
        console.log("I am here");
        this.saveMyResult();
        this.SET_value({ list: 0, id: "myTimeStartExplore" });
        this.SET_value({ list: false, id: "resultReadyExplore" });
      }
    },
  },
};
</script>
 <style lang="scss" scoped>
</style>
