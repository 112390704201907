<template>
  <div class="ma-2">
    by stats
    <div>
    <barChart :chart-data="datacollection" v-if="datacollection!==null"></barChart>
    <button @click="fillData()">Randomize</button>
  </div>
<!-- {{datacollection}} -->
  </div>
</template>


<script>
import barChart from './bar-chart'
export default {
  components: {
barChart
  },
  data () {
    return {
      datacollection: null
    }
  },
  mounted(){
this.fillData()
  },
  methods: {
    fillData () {
        this.datacollection = {
          labels: [this.getRandomInt(), this.getRandomInt()],
          datasets: [
            {
              label: 'Data One',
              backgroundColor: '#f87979',
              data: [this.getRandomInt(), this.getRandomInt()]
            }, {
              label: 'Data One',
              backgroundColor: '#f87979',
              data: [this.getRandomInt(), this.getRandomInt()]
            }
          ]
        }
      },
      getRandomInt () {
        return Math.floor(Math.random() * (50 - 5 + 1)) + 5
      }    
  }
}
</script>
 <style lang="scss" scoped>
</style>