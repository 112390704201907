<template>
  <div>
<!-- {{recordsIndex}}         -->
<v-fab-transition>
    <v-card class="my-5 pa-1">
       <v-row
      justify="space-between"
      align="center"
      class="ma-3"      
    >
      <div>Timeline</div>
      <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
        >
<v-icon>mdi-tune</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in mySelectOptions"
          :key="index"
          @click="mySelected=index"
        >
          <v-list-item-title>{{ item }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
      </div>
    </v-row>
      
      
      <v-timeline align-top dense>
        <div v-for="(idx, i) in recordsIndex" :key="i"  @click="dialog=!dialog">
          <v-timeline-item :color="i % 2 == 0 ? 'pink' : 'teal darken-2'" small>   
            <template v-slot:icon> 
        <span class="fSmaller1"> {{i+1}} </span>
      </template>         
            <v-row class="pt-1">              
              <v-col cols="3" class="mx-0 px-0">
                <div>{{millisToMinutes(ownedRecordsLocal[idx].end - ownedRecordsLocal[idx].start)}}<span class="text--secondary caption">min</span></div> 
                <div class="fSmaller">
                  {{ extractHM(ownedRecordsLocal[idx].start) }} 
                  {{ extractDate(ownedRecordsLocal[idx].start) }}
                </div>
              </v-col>
              <v-col>
                <v-row >
                <v-col  :class="$vuetify.breakpoint.width < 600 ? 'caption' : 'body-1'">
                <div>{{ownedRecordsLocal[idx].name}}</div>
                <div>{{ownedRecordsLocal[idx].verses.length}} items</div>
                </v-col>
                <v-col align-self="center">
                  <div>100%</div>
                <!-- <div class="fSmaller text--secondary">score</div> -->
                </v-col>
                </v-row>
              </v-col>
            </v-row>            
          </v-timeline-item>
        </div>
      </v-timeline>
    </v-card>
    </v-fab-transition>
  </div>
</template>


<script>
export default {
  props: {
    ownedRecordsLocal: Array
  },
  data() {
    return {
      mySelectOptions: ['Newest first', 'Longest first', 'Alphabetical order', 'High scores first'],
      mySelected: 0,
      dialog: false      
    };
  },
  mounted() {    
  },
  computed: {
    recordsIndex() {      
      if(this.mySelected==0) {
        return this.sortArrayIndex(this.ownedRecordsLocal.map(a => a.start), "desc") 
      } else if(this.mySelected==1) {
        return this.sortArrayIndex(this.ownedRecordsLocal.map(a => (a.end - a.start)), "desc") 
      } else if(this.mySelected==2){
        return this.sortArrayIndex(this.ownedRecordsLocal.map(a => a.start), "asce") 
      } else {
        return null
      }
    }
  },
  methods: {
    sortArrayIndex(test, mode) {
      if(mode=="desc") {
      return test.map((val, ind) => {return {ind, val}})
           .sort((a, b) => {return a.val < b.val ? 1 : a.val == b.val ? 0 : -1 })
           .map((obj) => obj.ind)
      } else {
        return test.map((val, ind) => {return {ind, val}})
           .sort((a, b) => {return a.val > b.val ? 1 : a.val == b.val ? 0 : -1 })
           .map((obj) => obj.ind)
      }
    },
    extractDate(milsecs) {
      let aDateObject = new Date(milsecs);
      let aDate = aDateObject.toLocaleString().split(",")[0];
      let a = aDate.split('/')      
      let aYear = a[2].split('')
      console.log(aYear)
      if(aYear[0]=='2' & aYear[1]=='0') {
        aYear = aYear[2] + aYear[3]
      } else {
        aYear = a[2]
      }
      return a[0] + '/' + a[1] + '/' + aYear;
    },
    extractHM(milsecs) {
      let a = new Date(milsecs);
      a = a.toLocaleString().split(",");
      let temp1 = a[1].trim().split(" ");
      let temp2 = temp1[0].split(":");
      return temp2[0] + ":" + temp2[1] + temp1[1];
    },
    millisToMinutes(millis) {
//Get hours from milliseconds
      let hours = millis / (1000 * 60 * 60)
      let absoluteHours = Math.floor(hours)      
      let h = absoluteHours == 0 ? "" : absoluteHours + ":"

      //Get remainder from hours and convert to minutes
      let minutes = (hours - absoluteHours) * 60
      let absoluteMinutes = Math.floor(minutes)
      // let m = absoluteMinutes > 9 ? absoluteMinutes : '0' +  absoluteMinutes;
      let m = absoluteMinutes+''      
      
      if(m=='0') {
        m = '<1'
      }
      return h+m;
    },
  },
};
</script>
 <style lang="scss" scoped>
.fSmaller {
  font-size: 0.75rem;
}
.fSmaller1 {
  font-size: 0.6rem;
}
</style>