<template>
  <div :style="cssProps">
    <add v-if="(addItem == 1) & authenticated"></add>

    <div v-else>            
      <!-- <recognizeSpeech> </recognizeSpeech> -->
      <div v-if="!ce">
        <searchBar id="ExploreSearch"> </searchBar>
        <v-divider class="my-5"></v-divider>        
        <renderList> </renderList>        
        <!-- show button to visit the add Item path again provided it was visited once before through a router link -->
        <div v-if="addItemVisited & authenticated">
          <v-fab-transition>
            <v-btn
              color="red darken-3"
              fab
              dark
              :small="$vuetify.breakpoint.width < 800 ? true : false"
              fixed
              bottom
              right
              @click="
                $router.push('/Explore/addItem=1'),
                  SET_value({ list: 1, id: 'addItem' })
              "
            >
              <v-icon>mdi-plus-box</v-icon>
            </v-btn>
          </v-fab-transition>
        </div>
      </div>
      <div v-if="ce">
        <chantOneMantra></chantOneMantra>
      </div>

      <!-- add the play/pause/chantIcon button -->
      <v-fab-transition>
        <v-btn
          color="purple darken-3"
          fab
          dark
          :small="$vuetify.breakpoint.width < 800 ? true : false"
          fixed
          bottom
          left
          @click="myPlayButtonFun()"
        >
          <v-icon v-if="!ce">mdi-account-voice</v-icon>
          <v-icon v-if="ce && !playONExplore">mdi-play</v-icon>
          <v-icon v-if="ce && playONExplore">mdi-pause</v-icon>
        </v-btn>
      </v-fab-transition>

      <!-- the start/restart/resume dialog with
the option to chant the number of times option
do conditional rendering of start/restart/resume -->
      <v-dialog v-model="playDialog" max-width="390px">
        <v-card outlined rounded ref="form" v-if="dialON">
          <v-card-text>
            <v-container>
              <v-row justify="space-between" align="center" class="mb-2">
                <span class="text--disabled"> Power Mantra </span>
                <v-btn icon @click="playDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-row>

              <v-row align="center" v-if="myMantraOptions">
                <v-col cols="12">
                  <v-select
                    v-if="playDialog"
                    :items="myMantraOptions()"
                    label="Select Mantra"
                    v-model="selectedMantra"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row justify="space-between" align="center">
                <span class="text-subtitle-1">
                  Chant {{ selectedMantra }}
                  <span class="info--text">{{
                    chantTimesExplore == 1
                      ? "once"
                      : chantTimesExplore + " times"
                  }}</span
                  >:</span
                >
                <v-radio-group v-model="chantTimesExplore" row class="tighter">
                  <v-radio
                    v-for="(nn, n) in chantTimesOptions"
                    :key="n"
                    :label="nn + ''"
                    :value="nn"
                  ></v-radio>
                </v-radio-group>
              </v-row>
              <v-divider></v-divider>
              <v-row class="mt-4">
                <span class="text-subtitle-1" v-if="myTime1Explore != null">
                  Resume or Restart from beginning?
                </span>
              </v-row>
              <v-row justify="end" class="mt-5">
                <v-card-actions>
                  <v-btn
                    class="mr-5"
                    color="green darken-3"
                    @click="playDialogFun('restart')"
                    dark
                  >
                    {{ myTime1Explore == null ? "Start" : "Restart" }}
                  </v-btn>
                  <v-btn
                    color="red darken-3"
                    dark
                    @click="playDialogFun('resume')"
                    v-if="myTime1Explore != null"
                  >
                    Resume
                  </v-btn>
                </v-card-actions>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import searchBar from "./search-bar";
import chantOneMantra from "./chant-one-mantra";
import renderList from "./render-list";
// import recognizeSpeech from "./subcomponents/recognize-speech";
import add from "@/components/add/add.vue";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      playDialog: false,
      myTime1: null,
      // selectedMantra: "",
      componentLoaded: false,
      dialON: true,      
    };
  },
  components: {
    // recognizeSpeech
    searchBar,
    renderList,
    add,
    chantOneMantra    
  },
  mounted() {
    setTimeout(() => {
      this.componentLoaded = true;
      this.$store.state.parameters.selectedMantra = this.mantras[this.searchSelect[0].mantra_id].Name      
    }, 1000);
  },
  computed: {
    ...mapState("parameters", [
      "ce",
      "addItem",
      "addItemVisited",
      "authenticated",
      "chantTimesExplore",
      "searchSelect",
      "playONExplore",
      "myTime1Explore",
      "myItemTrackerExplore",
      "selectedMantraIndex",
      "chantTimesDoneExplore",
    ]),
    ...mapState("coretext", ["mantras"]),
    cssProps() {
      // console.log(this.myPlaylistLocal);
      return {
        "--offset":
          this.$vuetify.breakpoint.width < 1050
            ? "5px"
            : (this.$vuetify.breakpoint.width - 1050) / 2 + "px",
        "--center": this.$vuetify.breakpoint.width / 2 - 5 + "px",
      };
    },
    chantTimesOptions() {
      if (this.componentLoaded) {
        let temp = [1, 3, 11, 108, 1008];
        if (this.mantras[this.selectedMantraIndex].mantra.length < 3) {
          temp = [11, 54, 108, 1008];
        } else if (this.mantras[this.selectedMantraIndex].mantra.length < 6) {
          temp = [3, 11, 54, 108];
        } else if (this.mantras[this.selectedMantraIndex].mantra.length > 100) {
          temp = [1, 11];
        } else {
          temp = [1, 2, 3, 11];
        }
        if (this.chantTimesDoneExplore == 0) {
          return temp;
        } else {
          return temp.filter(a => 
            a > this.chantTimesDoneExplore
          );
        }
      } else {
        return [1, 3, 11, 108, 1008];
      }
    },
    chantTimesExplore: {
      get() {
        return this.$store.state.parameters.chantTimesExplore;
      },
      set(value) {
        this.SET_value({ list: value, id: "chantTimesExplore" });
      },
    },
    selectedMantra: {
      get() {           
        return this.$store.state.parameters.selectedMantra;
      },
      set(value) {
        this.SET_value({ list: 0, id: "myTime1OffsetExplore" });
        this.SET_value({ list: [], id: "myTimeExplore" });
        this.SET_value({ list: null, id: "myTime1Explore" });  
        this.SET_value({ list: 0, id: "chantTimesDoneExplore" });        
        this.SET_value({ list: false, id: "resultReadyExplore" });
        this.chantTimesExplore = this.chantTimesOptions[0]
        this.SET_value({ list: value, id: "selectedMantra" });              
      },
    },
  },
  methods: {
    ...mapMutations("parameters", ["SET_value"]),
    myMantraOptions() {
      // console.log("I am in myMantraOptions");
      if (this.componentLoaded) {
        let temp1 = this.searchSelect.map(
          (a) => this.mantras[a.mantra_id].Name
        );
        let temp2 = this.mantras.map((a) => a.Name);
        // this.selectedMantra = temp1[0]
        return temp1.concat({ divider: true }).concat(temp2);
        // this.selectedMantra = temp1[0]
        // return temp1.concat(temp2);
        // return  temp1.concat(temp2)
      } else {
        return this.selectedMantra;
      }
    },
    playDialogFun(val) {
      if (val == "restart") {
        this.SET_value({ list: 0, id: "currentChantIndexExplore" });
        let dt = new Date();
        this.$store.state.parameters.myTimeStartExplore = Math.ceil(
          dt.getTime()
        );
        this.$store.state.parameters.myTime0Explore = Math.ceil(dt.getTime());
        this.SET_value({ list: 0, id: "myTime1OffsetExplore" });
        this.SET_value({ list: [], id: "myTimeExplore" });              
        this.SET_value({ list: 0, id: "chantTimesDoneExplore" });
        this.SET_value({ list: true, id: "playONExplore" });
        this.SET_value({ list: false, id: "resultReadyExplore" });
        this.$store.state.parameters.speechAnalysisResults = []
      } else {
        let dt = new Date();
        this.$store.state.parameters.myTime0Explore = Math.ceil(dt.getTime());
        this.SET_value({ list: true, id: "playONExplore" });
      }
      this.playDialog = false;
      // console.log(this.currentChantIndex);
      // this.jumpToCurrentLocation("byElement");
      return;
    },
    setMyItemTracker() {
      // if (this.myItemTrackerExplore.length == 0) {
      // console.log("I am here");
      {
        let myNames = this.mantras.map((a) => a.Name);
        this.$store.state.parameters.selectedMantraIndex = myNames.findIndex(
          (b) => b == this.selectedMantra
        );
        if(this.searchSelect[0].mantra_id != this.selectedMantraIndex) {
        this.SET_value({
        list: [{ mantra_id: this.selectedMantraIndex, verse_id: 0 }],
        id: "searchSelect",
      });      
      // console.log(this.$route.path)
      let myTempPath = '/Explore/mn=' + this.selectedMantraIndex + '$0'
      if(this.$route.path != myTempPath) {
        this.$router.push(myTempPath)      
      }
        }      
        // console.log(this.selectedMantra);
        // console.log(this.selectedMantraIndex);
        let mlen = this.mantras[this.selectedMantraIndex].mantra.length;
        for (let index1 = 0; index1 < mlen; index1++) {
          this.$store.state.parameters.myItemTrackerExplore.push(
            "ID_" + "item" + 1 + "verse" + index1
            // "i" + (index + 1) + "v" + index1
          );
          this.$store.state.parameters.myItemTrackerSaveExplore.push(
            "m" + this.selectedMantraIndex + "v" + index1
          );
        }
      }
      // }
    },
    myPlayButtonFun() {
      if (!this.ce) {
        this.SET_value({ list: true, id: "ce" });
        this.SET_value({ list: false, id: "playONExplore" });
        // this.playON = false;
        return;
      } else if (!this.playONExplore) {        
        this.playDialog = true;        
      } else {
        this.SET_value({ list: false, id: "playONExplore" });
      }
      return;
    },
  },
  watch: {
    selectedMantra: function () {
      if (this.selectedMantra != "") {
        this.dialON = false
        this.SET_value({ list: [], id: "myItemTrackerExplore" });
        this.SET_value({ list: [], id: "myItemTrackerSaveExplore" });      
        this.SET_value({ list: 0, id: "chantTimesDoneExplore" });      
        this.SET_value({ list: 11, id: "chantTimesExplore" });    
        this.SET_value({ list: 0, id: "currentChantIndexExplore" });    
        this.setMyItemTracker();
        setTimeout(() => {
      this.dialON = true;   
    }, 100);
      
      }
    },
  },
};
</script>

 <style lang="scss" scoped>
.v-btn--fixed.v-btn--bottom {
  bottom: 70px;
}
// .v-btn--fixed.v-btn--right {
//   right: 16px;
// }
.v-btn--fixed.v-btn--right {
  right: var(--offset);
}
.v-btn--fixed.v-btn--left {
  left: var(--offset);
}
</style>