<template>
  <div>
    <!-- load the prgress bar if not loaded still
    shoudln't get stuck, but not tested thoroughly -->
    <!-- {{ ownedPlaylists }} -->
    <v-row
      v-if="!loaded"
      class="fill-height"
      align-content="center"
      justify="center"
    >
      <v-col class="subtitle-1 text-center" cols="12">
        Getting your mantras
      </v-col>
      <v-col cols="6">
        <v-progress-linear
          color="deep-purple accent-4"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </v-row>
    <div v-if="loaded">
      <div v-if="editPlaylistIndex != null">
        <editPlaylist></editPlaylist>
      </div>
      <div v-else>
        <v-alert
          v-model="alertSingIn"
          border="left"
          close-text="Close Alert"
          type="info"
          dark
          dismissible
          class="body-2"
          dense
          v-if="!authenticated"
          text
        >
          Sing In to create and share your own playlists.
        </v-alert>

        <v-snackbar
          v-model="alertFunctionality"
          :multi-line="true"
          :timeout="-1"
          v-if="isSnackBarOn"
          left          
        >
          Help: <br />
          To add items to a playlist, use
          <v-icon>mdi-plus-box-multiple</v-icon> <br />
          To share a playlist, use <v-icon>mdi-share</v-icon> <br />
          To view and edit or delete a playlist, use
          <v-icon>mdi-dots-vertical</v-icon>
          <template v-slot:action="{ attrs }">
            <v-btn
              color="red"
              text
              v-bind="attrs"
              @click="alertFunctionality = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>

        <renderPlaylists
          v-if="(pl == null) & authenticated"
          :ownedPlaylists="ownedPlaylists"
          :sharedPlaylists="sharedPlaylists"
          :mySharedList="mySharedList"
        >
        </renderPlaylists>
        <renderPlaylistsUnauth v-if="(pl == null) & !authenticated">
        </renderPlaylistsUnauth>
        <loadPlaylist
          v-if="(pl != null) & authenticated"
          :ownedPlaylists="ownedPlaylists"
          :sharedPlaylists="sharedPlaylists"
          :mySharedList="mySharedList"
        >
        </loadPlaylist>
        <loadPlaylistUnauth v-if="(pl != null) & !authenticated">
        </loadPlaylistUnauth>
      </div>
    </div>
  </div>
</template>

<script>
import renderPlaylists from "./render-playlists";
import renderPlaylistsUnauth from "./render-playlists-unauth";
import loadPlaylist from "./load-playlist";
import loadPlaylistUnauth from "./load-playlist-unauth";
import { db, auth } from "@/main.js";
import { mapState, mapMutations } from "vuex";
import editPlaylist from "./edit-playlist";
export default {
  data() {
    return {
      alertSingIn: true,
      alertFunctionality: true,
      loaded: false,
    };
  },
  components: {
    renderPlaylists,
    renderPlaylistsUnauth,
    loadPlaylist,
    loadPlaylistUnauth,
    editPlaylist,
  },
  mounted() {
    setTimeout(() => {
      auth.onAuthStateChanged((user) => {
        if (user) {
          console.log("user");
          //authenticated
          //bind owned playlists
          this.bindToFirestoreOwned("ownedPlaylists");
          //bind to the list of owned playlists
          this.bindToFirestoreMyOwnedList("myOwnedList");
          //bind to the list of shared playlists
          this.bindToFirestoreMySharedList("mySharedList");
          //removeDuplicates
          if ((this.mySharedList != null) & (this.ownedPlaylists != null)) {
            this.removeDuplicatesFromMySharedList();
          }
        } else {
          console.log("no user");
          //unauthenticated
          //if plUnauth is available then bind using it
          this.bindToFirestoreSharedUnauth("sharedPlaylists");
          this.loaded = true;
        }
      });
    }, 50);
  },
  computed: {
    ...mapState("firestore", [
      "ownedPlaylistsOnServer",
      "sharedPlaylists",
      "mySharedList",
      "myOwnedList"
    ]),
    ...mapState("firestoreSelf", [
      "ownedPlaylists"      
    ]),
    ...mapState("parameters", [
      "pl",
      "authenticated",
      "editPlaylist",
      "editPlaylistIndex",
      "cl"
    ]),
    isSnackBarOn() {      
      if(this.cl) {
        return false
      } else {
      let temp = true  
      if(!this.authenticated) {
        if(this.sharedPlaylists == null) {
          temp = false
        }
      } else {
        if(this.ownedPlaylists!=null) {
        if(this.ownedPlaylists.length > 1) {
          temp = Math.random() > 0.1 ? false : true       
        } 
        } else if(this.mySharedList!=null) {
          if(this.mySharedList.tag.length > 1) {
            temp = Math.random() > 0.2 ? false : true       
          }
        }           
        }
        return temp              
      }
    }            
  },
  methods: {
    ...mapMutations("parameters", ["SET_value"]),

    bindToFirestoreOwned(value) {
      let valueLocal = value
      value = value + 'OnServer'
      if (this[value] == null) {
        console.log("I am in bindToFireStore " + value);
        let myQuery = false
        // console.log(this[valueLocal])
        // console.log(this[valueLocal]!=undefined)
        if(this[valueLocal]!=undefined) {
          if(this[valueLocal].length>0) {            
            myQuery = ["timestamp", ">", this[valueLocal][this[valueLocal].length-1]['timestamp']]
          }
        }
        console.log(myQuery)
        this.$store
          .dispatch("firestore/bindUserdata", {
            path: "/userdata/" + auth.currentUser.uid + "/playlists",
            type: "collection",
            query: myQuery,
            whereToBind: value,
          })
          .then(() => {
            // console.log("fullfilled");
            // console.log(this.ownedPlaylistsOnServer);
            this.loaded = true;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        console.log(value + " already bound");
        this.loaded = true;
      }
    },

    bindToFirestoreMySharedList(value) {
      if (this[value] == null) {
        console.log("I am in bindToFireStore " + value);
        this.$store
          .dispatch("firestore/bindUserdata", {
            path: "/userdata/" + auth.currentUser.uid + "/track/mysharedlist",
            type: "document",
            whereToBind: value,
          })
          .then(() => {
            this.loaded = true;
            this.bindToFirestoreSharedAuth("sharedPlaylists");
          });
      } else {
        console.log(value + " already bound");
        this.loaded = true;
      }
    },

    bindToFirestoreMyOwnedList(value) {
      if (this[value] == null) {
        console.log("I am in bindToFireStore " + value);
        this.$store
          .dispatch("firestore/bindUserdata", {
            path: "/userdata/" + auth.currentUser.uid + "/track/myownedlist",
            type: "document",
            whereToBind: value,
          })
          .then(() => {
            this.loaded = true;                        
          });
      } else {
        console.log(value + " already bound");
        this.loaded = true;
      }
      //update ownedPlaylists based on myOwnedLists
            // console.log(this.ownedPlaylists)
            this.syncOwnedPlaylistsWithServerCopy()
    },
    syncOwnedPlaylistsWithServerCopy() {
      console.log('I am in syncOwnedPlaylistsWithServerCopy')
      if (this.myOwnedList != null) {        
        if (this.ownedPlaylists == null) {
          return;
        } else {
          if (this.ownedPlaylists.length > 0) {            
        //silly mistake by not including myOwnedList.tag 
        //myOwnedList cannot and should not work
        // console.log(this.myOwnedList)
            let myItemsToKeep = this.ownedPlaylists.filter((a) => {
              return this.myOwnedList.tag.includes(a.tag)              
            })                        
            this.$store.state.firestoreSelf.ownedPlaylists = myItemsToKeep;            
          }
        }
      } else {
        return;
      }
    },
    //this needs to be handled differently from the unauthenticated case
    bindToFirestoreSharedAuth(value) {
      if (this[value] == null) {
        if (this.mySharedList != null) {
          if (this.mySharedList.tag.length > 0) {
            console.log("I am in bindToFireStore " + value);
            this.$store
              .dispatch("firestore/bindUserdata", {
                path: "playlists",
                type: "collectionGroup",
                query: ["tag", "in", this.mySharedList.tag],
                whereToBind: value,
              })
              .then(() => {
                db.collection("userdata")
                  .doc(auth.currentUser.uid)
                  .collection("track")
                  .doc("mysharedlist")
                  .set({
                    tag: this.sharedPlaylists.map((a) => a.tag),
                  });
                // console.log(this.sharedPlaylists.map(a => a.tag))
                // console.log(this.mySharedList.tag)
              });
          } else {
            console.log(value + " nothing to bind yet");
          }
        } else {
          console.log(value + " mySharedList not bound yet");
        }
      } else {
        console.log(value + " already bound");
      }
    },

    bindToFirestoreSharedUnauth(value) {
      if (this[value] == null) {
        console.log("I am in bindToFireStoreSharedUnauth");
        // console.log(this.plUnauth);
        if (this.plUnauth != undefined) {
          this.$store.dispatch("firestore/bindUserdata", {
            path: "playlists",
            type: "collectionGroup",
            query: ["tag", "==", this.plUnauth],
            whereToBind: value,
          });
        }
        // console.log(this.sharedPlaylists);
      } else {
        console.log("sharedPlaylist already loaded");
      }
    },
    removeDuplicatesFromMySharedList() {
      //due to racing conditions between ownedplyalist and sharedplaylists vuexfire bindings
      //sometimes there are duplicates in the mySharedList that already exist in ownedPlaylists
      //if duplicates are found then update the remove them and update the userdata/track/mysharedlist
      //there might be a duplication of effort in handling this problem between render-playlists
      //load-playlists and library components as there might be repition of logic that catches this issue
      //but the computation load is minor and shouldn't effect the performance
      console.log("I am in removeDuplicatesFromMySharedList");
      let sharedPlaylistsNew = [];
      let ownedTemp = this.ownedPlaylists.map((a) => a.tag);
      for (let index = 0; index < this.mySharedList.tag.length; index++) {
        if (!ownedTemp.includes(this.mySharedList.tag[index])) {
          sharedPlaylistsNew.push(this.mySharedList.tag[index]);
        }
      }
      if (sharedPlaylistsNew.length < this.mySharedList.tag.length) {
        //update mySharedlist
        db.collection("userdata")
          .doc(auth.currentUser.uid)
          .collection("track")
          .doc("mysharedlist")
          .set({
            tag: sharedPlaylistsNew,
          });
        console.log("I updated mysharedlist");
      }
    },
  },
};
</script>

 <style lang="scss" scoped>
.v-btn--fixed.v-btn--bottom {
  bottom: 70px;
}
.v-btn--fixed.v-btn--right {
  right: 16px;
}
.v-btn--fixed.v-btn--left {
  left: 16px;
}
</style>