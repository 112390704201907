<template>
  <div :style="cssProps">
    <v-bottom-sheet v-model="sheet" max-width="920px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          dark
          :small="$vuetify.breakpoint.width < 800 ? true : false"        
          fixed
          bottom
          right
          v-bind="attrs"
          v-on="on"
          color="red darken-3"
        >
          <v-icon>mdi-gesture-tap</v-icon></v-btn
        >
      </template>



      <v-sheet class="text-center">
        <v-card outlined>
          <v-row justify="end">            
              <v-btn icon @click="sheet=false" class="mx-3 mt-3">
                <v-icon>mdi-close</v-icon>
              </v-btn>            
          </v-row>
          <v-row class="px-3 pb-3">                        
                    <v-col @click="SET_value({ list: !cl, id: 'cl' })" v-if="Playlist">
              <v-btn fab small text class="font-weight-bold text-none">
                <v-icon v-if="cl">mdi-book-open</v-icon>
                <v-icon v-if="!cl">mdi-account-voice</v-icon>                
              </v-btn>
              <br />
              <span v-if="cl">  Reading mode </span>
              <span v-if="!cl"> Chanting mode </span>
            </v-col>

<v-col @click="goBack(), sheet=false" v-if="Playlist">
              <v-btn fab small text class="font-weight-bold text-none">
                <v-icon>mdi-format-list-checkbox</v-icon>
              </v-btn>
              <br />
              My Playlists
            </v-col>

                                <v-col @click="SET_value({ list: false, id: 'ce' }), SET_value({ list: false, id: 'playONExplore'})" v-if="SingleItem">
              <v-btn fab small text class="font-weight-bold text-none">
                <v-icon v-if="ce">mdi-arrow-left</v-icon>
              </v-btn>
              <br />
              <span >  Exit Chanting </span>              
            </v-col>


            <v-col @click="SET_value({ list: !parallelViewON, id: 'parallelViewON' })">
              <v-btn fab small text class="font-weight-bold text-none">
                <v-icon v-if="!parallelViewON">mdi-view-sequential</v-icon>
                <v-icon v-if="parallelViewON">mdi-view-parallel</v-icon>                
              </v-btn>
              <br />
              Arrange View
            </v-col>

</v-row>
<v-divider></v-divider>
<v-row class="pa-3 pb-5">
              <v-col @click="cl || ce ? SET_value({ list: !transliterateON, id: 'transliterateON' }) : false">
              <v-btn fab small text class="font-weight-bold text-none" :disabled="Playlist && !cl">
                <v-icon v-if="transliterateON">mdi-translate</v-icon>
                <v-icon v-if="!transliterateON">mdi-translate-off</v-icon>                
              </v-btn>
              <br />
              <span class="text--disabled" v-if="Playlist && !cl"> Transliterate </span>
              <span v-else> Transliterate </span>
            </v-col>

            <v-col @click="cl || ce? SET_value({ list: !translateON, id: 'translateON' }) : false">
              <v-btn fab small text class="font-weight-bold text-none" :disabled="Playlist && !cl">
                <v-icon v-if="translateON">mdi-format-title</v-icon>
                <v-icon v-if="!translateON">mdi-format-clear</v-icon>                
              </v-btn>
              <br />
              <span class="text--disabled" v-if="Playlist && !cl"> Translate </span>
              <span v-else> Translate </span>              
            </v-col>

            <v-col @click="cl || ce? changeFontSize('increase') : false">
              <v-btn fab small text class="font-weight-bold text-none" :disabled="Playlist && !cl">
                <v-icon>mdi-format-font-size-increase</v-icon>                
              </v-btn>
              <br />
              <span class="text--disabled" v-if="Playlist && !cl"> Increase </span>
              <span v-else> Increase </span>              
            </v-col>

                        <v-col @click="cl || ce? changeFontSize('decrease') : false">
              <v-btn fab small text class="font-weight-bold text-none" :disabled="Playlist && !cl">
                <v-icon>mdi-format-font-size-decrease</v-icon>                
              </v-btn>
              <br />
              <span class="text--disabled" v-if="Playlist & !cl"> Decrease </span>
              <span v-else> Decrease </span>              
            </v-col>
            

          </v-row>
        </v-card>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<!-- 
    <v-fab-transition>
      <v-btn
        color="purple darken-3"
        fab
        dark
        small
        fixed
        bottom
        left        
        @click="SET_value({ list: !cl, id: 'cl' })"
      >
        <v-icon v-if="!cl">mdi-account-voice</v-icon>
        <v-icon v-if="cl">mdi-book-open</v-icon>
      </v-btn>
    </v-fab-transition> -->

<script>
import { mapState, mapMutations } from "vuex";
import { db, auth } from "@/main.js";

export default {
  props: {
    Playlist: Boolean,
    SingleItem: Boolean,
  },
  mounted(){
  },
  components: {},
  data() {
    return {
      sheet: false,
      deleteDialog: false,
      loaded: false
    };
  },
  computed: {
    ...mapState("parameters", [
      "transliterateON",
      "translateON",
      "zoomText",
      "parallelViewON",
      "cl",
      "ce"
    ]),
    cssProps() {      
      return {
        "--offset":
          this.$vuetify.breakpoint.width < 1050
            ? "5px"
            : (this.$vuetify.breakpoint.width - 1050) / 2 + "px",
      };
    },
  },
  methods: {
    ...mapMutations("parameters", ["SET_value"]),
    changeFontSize(val) {      
      if(val == "increase") {
      if(this.zoomText <= 2) this.SET_value({ list: this.zoomText + 0.1, id: 'zoomText' })
      return
      }
      if(val == "decrease") {
      if(this.zoomText >= 0.8) this.SET_value({ list: this.zoomText - 0.1, id: 'zoomText' })
      return
      }
    },
    goBack() {
      this.$router.push("/Library");
    },
    deleteMyList() {
      // console.log(this.item)
      db.collection("userdata")
        .doc(auth.currentUser.uid)
        .collection("playlists")
        .doc(this.item.id)
        .delete()
        .then(() => {
          console.log("Document successfully deleted!");
          (this.deleteDialog = false), (this.sheet = false);
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
        });
    },
    myMaxHeight() {
      return window.innerHeight - 400;
    },
    goEdit() {
      this.$store.state.parameters.localCopy = null;
      this.SET_value({ list: false, id: "inputErrorEditPlaylist1" });
      this.SET_value({ list: false, id: "inputErrorEditPlaylist2" });
      this.SET_value({ list: this.listIndex, id: "editPlaylistIndex" });
    },
    sharingInfo(item) {
      let playlistId = item.tag;
      let myTempPath =
        "https://powermantra.web.app" +
        "/" +
        "Library" +
        "/" +
        "pl=" +
        playlistId;
      return {
        url: myTempPath,
        title: item.name,
        description: item.description,
        quote:
          "Mantrams are collection of phrases, words and sounds which by virtue of rhythmic effect achieve results that would not be possible apart from them.. - Master Djwhal Khul",
        hashtags: "mantra, meditation, yoga",
      };
    },
    viewPlaylist(item) {
      let playlistId = item.tag;
      let myTempPath =
        // "https://powermantra.web.app" +
        "/" + "Library" + "/" + "pl=" + playlistId;
      this.$router.push(myTempPath);
    },
  },
};
</script>
 <style lang="scss" scoped>
.v-btn--fixed.v-btn--bottom {
  bottom: 60px;
}
.v-btn--fixed.v-btn--right {
  right: var(--offset);
}
.v-btn--fixed.v-btn--left {
  left: var(--offset);
}
</style>