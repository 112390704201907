<template>
  <div>
    <v-bottom-sheet v-model="sheet" max-width="960px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab text v-bind="attrs" v-on="on" small>
          <v-icon>mdi-dots-vertical</v-icon></v-btn
        >
      </template>

      <v-sheet
        class="text-center"
        height="400"
        :style="{ overflowY: 'scroll' }"
      >
        <v-card class="">
          <v-row justify="space-between" class="ma-1 mx-2 pa-1" align="center">
            <div class="text-body-1">{{ item.name }}</div>
            <div>
              <v-btn icon @click="sheet = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </v-row>
          <v-divider></v-divider>
          <v-row justify="center" class="pa-6" align="center">
            <!-- add the delete button with the dialog -->
            <div v-if="deleteON">
              <v-card-actions v-if="!sharedPlaylist">
                <v-dialog v-model="deleteDialog" max-width="250px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      text
                      small
                      @click="deleteDialog = true"
                    >
                      <v-icon>mdi-delete-forever</v-icon>delete
                    </v-btn>
                  </template>
                  <v-card outlined rounded ref="form">
                    <v-card-text>
                      <v-container align="center">
                        <div class="text-subtitle-1">
                          This will permanently delete the playlist
                          <span class="info--text"> {{ item.name }} </span>. Do
                          you still want to proceed?
                        </div>
                        <v-card-actions>
                          <v-btn
                            color="green darken-1"
                            @click="deleteDialog = false"
                            dark
                          >
                            NO
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="red darken-1"
                            @click="deleteMyList()"
                            dark
                          >
                            YES
                          </v-btn>
                        </v-card-actions>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-card-actions>
            </div>

            <div class="text-body-2 text--secondary" v-if="!sharedPlaylist">
              <v-btn text small @click="goEdit()">
                <v-icon>mdi-pencil</v-icon>edit
              </v-btn>
            </div>
            <div>
              <v-btn text small @click="viewPlaylist(item)">
                <v-icon>mdi-playlist-play</v-icon>view
              </v-btn>
            </div>
            <socialSharing :sharing="sharingInfo(item)"></socialSharing>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col>
              <div class="text-justify ma-4">{{ item.description }}</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="ma-4">
                Mantras
                <v-chip
                  class="ma-1 caption"
                  v-for="(midx, m) in item.mantras"
                  :key="m"
                >
                  {{ m + 1 }} {{ mantras[midx - 1].Name }}
                </v-chip>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>


<script>
import socialSharing from "./social-sharing.vue";
import { mapState, mapMutations } from "vuex";
import { db, auth } from "@/main.js";
import firebase from "firebase/app";
export default {
  props: {
    item: Object,
    mantras: Array,
    listIndex: Number,
    sharedPlaylist: Boolean,
    deleteON: Boolean,
  },
  components: {
    socialSharing,
  },
  data() {
    return {
      sheet: false,
      deleteDialog: false,
    };
  },
  computed: {
    ...mapState("firestoreSelf", [
      "ownedPlaylists"      
    ]),
    ...mapState("firestore", [
      "myOwnedList"      
    ]),
  },
  methods: {
    ...mapMutations("parameters", ["SET_value"]),
    deleteMyList() {
      // console.log(this.item)
      db.collection("userdata")
        .doc(auth.currentUser.uid)
        .collection("playlists")
        .doc(this.item.idLocal)
        .delete()
        .then(() => {
          console.log("Document successfully deleted!");
          db.collection("userdata")
            .doc(auth.currentUser.uid)
            .collection("track")
            .doc("myownedlist")
            .update({
              tag: firebase.firestore.FieldValue.arrayRemove(this.item.tag),
            }).then(()=>{
              this.syncOwnedPlaylistsWithServerCopy()
              this.deleteDialog = false
            this.sheet = false
            })            
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
        });
      // this.$store.state.firestoreSelf.ownedPlaylists.splice(this.listIndex+1, 1)
    },
    syncOwnedPlaylistsWithServerCopy() {
      if (this.myOwnedList != null) {        
        if (this.ownedPlaylists == null) {
          return;
        } else {
          if (this.ownedPlaylists.length > 0) {
            console.log('I am in syncOwnedPlaylistsWithServerCopy')
        //silly mistake by not including myOwnedList.tag 
        //myOwnedList cannot and should not work
        console.log(this.myOwnedList)
            let myItemsToKeep = this.ownedPlaylists.filter((a) => {
              return this.myOwnedList.tag.includes(a.tag)              
            })                        
            this.$store.state.firestoreSelf.ownedPlaylists = myItemsToKeep;            
          }
        }
      } else {
        return;
      }
    },
    myMaxHeight() {
      return window.innerHeight - 400;
    },
    goEdit() {
      this.$store.state.parameters.localCopy = null;
      this.SET_value({ list: false, id: "inputErrorEditPlaylist1" });
      this.SET_value({ list: false, id: "inputErrorEditPlaylist2" });
      this.SET_value({ list: this.listIndex, id: "editPlaylistIndex" });
    },
    sharingInfo(item) {
      let playlistId = item.tag;
      let myTempPath =
        "https://powermantra.app" + "/" + "Library" + "/" + "pl=" + playlistId;
      return {
        url: myTempPath,
        title: item.name,
        description: item.description,
        quote:
          "Mantrams are collection of phrases, words and sounds which by virtue of rhythmic effect achieve results that would not be possible apart from them.. - Master Djwhal Khul",
        hashtags: "mantra, meditation, yoga",
      };
    },
    viewPlaylist(item) {
      let playlistId = item.tag;
      let myTempPath =
        // "https://powermantra.app" +
        "/" + "Library" + "/" + "pl=" + playlistId;
      this.$router.push(myTempPath);
    },
  },
};
</script>
 <style lang="scss" scoped>
</style>