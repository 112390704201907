<template>
  <div >
    <!-- <v-card flat class="my-3"> -->
    <v-row>      
      <!-- total time -->
      <v-col cols="4">
        <v-card color="red darken-4" dark>
          <div class="justify-space-between">
              <v-card-title class="text-h6" id="totalTimeContainer">
                0 minutes
              </v-card-title>                            
              <v-card-subtitle v-if="$vuetify.breakpoint.width>600" class="text-body-1 ma-0 px-2">Total time spent chanting </v-card-subtitle>
              <v-card-subtitle v-else class="text-caption ma-0 px-2">Total time spent chanting </v-card-subtitle>
          </div>
        </v-card>
      </v-col>
      <!-- number of sessions -->
      <v-col cols="4">
        <v-card color="blue-grey darken-2" dark>
          <div class="justify-space-between">
              <v-card-title class="text-h6" id="totalSessionContainer">
                0
              </v-card-title>              
              <v-card-subtitle v-if="$vuetify.breakpoint.width>600" class="text-body-1 ma-0 px-2">Number of sessions </v-card-subtitle>
              <v-card-subtitle v-else class="text-caption ma-0 px-2">Number of sessions </v-card-subtitle>
          </div>
        </v-card>
      </v-col>
      <!-- score range  -->
      <v-col cols="4">
        <v-card color="pink darken-3" dark>
          <div class="justify-space-between">
              <v-card-title class="text-h6">
                <span id="totalScoreContainerLow"></span><span id="totalScoreContainerHigh"></span>
              </v-card-title>              
              <v-card-subtitle v-if="$vuetify.breakpoint.width>600" class="text-body-1 ma-0 px-2">Score range </v-card-subtitle>
              <v-card-subtitle v-else class="text-caption ma-0 px-2">Your score range </v-card-subtitle>
          </div>
        </v-card>
      </v-col>      
    </v-row>     
    <!-- </v-card> -->
    <v-card flat class="my-5">
    <showBystatsBarChartVue/> 
    </v-card>
  </div>
</template>


<script>
import showBystatsBarChartVue from './show-bystats-bar-chart.vue';

export default {
  components: {
    showBystatsBarChartVue
  },
  props: {
    totalTime: Number,
    totalSession: Number,
    scoreHigh: Number,
    scoreLow: Number
  },
  data() {
    return {};
  },
  mounted() {
    this.callAnimateValue();
  },
  methods: {
     millisToHoursAndMinutes(millis) {       
      var hours = Math.floor(millis / 3600000);      
      var minutes = ((millis % 3600000) / 60000).toFixed(0);      
      return (hours>0? hours +'h:' :'') + (minutes + (hours>0 ? 'm' : 'min'));
    },
    callAnimateValue() {
      let obj = document.getElementById("totalTimeContainer");
      this.animateValue(obj, 0, this.totalTime, 2000, "totalTimeContainer");
      obj = document.getElementById("totalSessionContainer");
      this.animateValue(obj, 0, this.totalSession, 2000, "totalSessionContainer");
      obj = document.getElementById("totalScoreContainerHigh");
      this.animateValue(obj, 0, this.scoreHigh, 2000, "totalScoreContainerHigh");
      obj = document.getElementById("totalScoreContainerLow");
      this.animateValue(obj, 0, this.scoreLow, 2000, "totalScoreContainerLow");
    },
    animateValue(obj, start, end, duration, type) {
      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        if(type=="totalTimeContainer") {          
        obj.innerHTML =  this.millisToHoursAndMinutes(Math.ceil(progress * (end - start) + start));
        } else if(type=="totalSessionContainer") {
obj.innerHTML =  Math.ceil(progress * (end - start) + start)
        } else if(type=="totalScoreContainerLow") {
obj.innerHTML =  Math.ceil(progress * (end - start) + start) + '-'
        } else if(type=="totalScoreContainerHigh") {
obj.innerHTML =  Math.ceil(progress * (end - start) + start) + '%'
        } 
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    },
  },
};
</script>
 <style lang="scss" scoped>
</style>