<template>
  <v-container>
    <v-row>
      <v-col md="12">
        <v-text-field
          dense
          v-model="myQuery"
          hide-details
          prepend-inner-icon="mdi-magnify"
          filled
          clear-icon="mdi-close-circle"
          clearable
          label="Search Power Mantra"
          type="text"
          @input="focus = true"
          @click:clear="myQuery = ''"
          :rounded="!focus"
          :shaped="focus"
        >
        </v-text-field>
        <v-card v-if="focus" class="ma-0">
          <v-card max-height="100vh" class="overflow-y-auto">
            <div             
              v-ripple
              class="pa-2"
              flat
              v-for="(item, i) in searchResults"
              :key="i"
              @click="goToSelectedItem(item)"              
            >
              <span  :class="myNameStyle(i)"> {{ item.name }} </span> |
              <span  :class="myVerseTextStyle(i)"> {{ convert(item.mantra, 'primary') }} 
                <!-- <span  :class="myVerseTextStyle(i)"> {{ item.mantra }}  -->
              <span   v-if="item.verse_id>=item.skipStart">  (v-{{item.verse_id+1-item.skipStart}})</span></span>
              <v-divider></v-divider>
            </div>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center text--primary">
        <div class="body-1">Browse by</div>
        <v-btn
         :active-class="$vuetify.theme.dark ? 'success darken-2' : 'success lighten-3'"
          outlined
          rounded
          text
          v-for="(item1, i) in filterByOptions"
          :key="item1 + i"
          :input-value="browseBy ? filterSelected == item1 : false"                    
          class="text-none text--primary ma-2"                    
          @click="findUnique(item1)"
        >
          {{ item1 }}</v-btn
        >
      </v-col>
    </v-row>
    <v-divider  class="my-4" v-if="browseBy"> </v-divider>
    <v-row v-if="browseBy">
      <v-col class="text-center text--secondary button">
        <div>
          <v-chip
          :active-class="$vuetify.theme.dark ? 'success darken-2' : 'success lighten-3'"
            outlined
            v-for="(item2, j) in nextFilterOptions"
            :key="item2 + j"
            :input-value="nextFilter == item2"
            filter
            class="ma-2"
            @click="setSearchSelectPreview(item2)"
          >
            {{ item2 }}
          </v-chip>
        </div>
      </v-col>
    </v-row>
    <v-divider class="my-4" v-if="browseBy"> </v-divider>
    <v-row v-if="browseBy">
      <v-col class="text-center text--secondary button">
        <div>
          <v-chip
          :active-class="$vuetify.theme.dark ? 'success darken-2' : 'success lighten-3'"
            outlined
            v-for="(item3, k) in nextNextFilterOptions"
            :key="item3 + k"
            :input-value="nextNextFilter == item3"
            filter
            class="ma-2"
            @click="setSearchSelect(item3)"
          >
            {{ item3 }}
          </v-chip>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import { mapMutations, mapState } from "vuex";
var FlexSearch = require("flexsearch");
import Sanscript from "sanscript";
export default {
  data() {
    return {
      filterByOptions: [
        "Deity",
        "Quality",
        "Category",
        //"Popularity",
      ],
      // filterSelected: null,
      // nextFilterOptions: [],
      // nextFilter: null,
      password: "Password",
      show: false,
      myQuery: "",
      marker: true,
      focus: false,
      index: null,
      searchResults: [],
    };
  },
  computed: {
    ...mapState("coretext", ["mantras", "indexMantras", "copyMantras"]),
    ...mapState("parameters", ["mn", "script", "filterSelected", "nextFilterOptions", 
    "nextFilter", "nextNextFilterOptions", "nextNextFilter", "browseBy"]),
  },
  watch: {
    myQuery(val) {
      // console.log(val);
      // console.log(this.indexMantras)
      if (this.indexMantras === null) {
        this.createSearch();
        // console.log("I created index");
      }
      this.searchQuery(val);
    },
    mn: function(){
      if(this.mn!=null) {
      if(this.mn.includes('$')) {
      this.$store.state.parameters.nextFilter = null
      this.$store.state.parameters.nextFilterOptions = []
      }
      }
    }
  },
  methods: {
    ...mapMutations("coretext", ["SET_indexMantras"]),
    ...mapMutations("parameters", ["SET_value"]),
    convert(myinput, option) {
      if (option === "primary") {
        return Sanscript.t(myinput, "itrans", this.script);
      } else {
        let secondScript = "iast";
        if (this.script === "iast" || this.script === "itrans") {
          secondScript = "devanagari";
        }
        return Sanscript.t(myinput, "itrans", secondScript);
      }
    },
    myNameStyle(i){
      if(i < 2) 
      { 
        return "body-2 success--text" }
         else if (i < 5) {
           return "body-2 warning--text"
           } else {
             return "caption error--text"
    }
    },
    myVerseTextStyle(i){
      if(i < 2) {
        return "caption text--primary"
        }else if(i <5) {
          return "caption text--secondary" } else 
          {
      return "caption text--disabled" }
    },
    goToSelectedItem(item) {      
      this.$store.state.parameters.browseBy = false
      this.SET_value({
        list: [{ mantra_id: item.id, verse_id: item.verse_id }],
        id: "searchSelect",
      });            
      this.$store.state.parameters.selectedMantra = this.mantras[item.id].Name
      this.SET_value({ list: 0, id: "chantTimesDoneExplore" });      
      this.SET_value({ list: 11, id: "chantTimesExplore" });
      this.SET_value({ list: 0, id: "currentChantIndexExplore" });
      this.SET_value({ list: null, id: "myTime1Explore" });
      this.focus = false;
      setTimeout(() => {
        let temp = '#ID_mantra' + item.id + 'verse' + item.verse_id
        this.$vuetify.goTo(temp, {
          duration: 300,
          offset: 0,
          easing: "easeInOutCubic",
        });
      }, 100);      
      let myTempPath = '/Explore/mn=' + item.id + '$' + item.verse_id
      if(this.$route.path != myTempPath) {
        this.$router.push(myTempPath)      
      }
    },
    createSearch() {
      //I messed up in naming the id instead of mantra_id in temp.push below
      //doesn't make a difference but not a good practice
      //same with naming the verse mantra
      //again a bad and potentially confusing practice

      //make a local copy
      let temp = [];
      // add id to every entry
      let ix = 0;
      for (var j = 0; j < this.mantras.length; j++) {
        let myCopy = [...this.mantras[j].mantra];
        // console.log(myCopy)
        for (var k = 0; k < myCopy.length; k++) {                    
          temp.push({
            id: j,
            verse_id: k,
            ix_id: ix,
            name: this.mantras[j].Name.slice(),
            mantra: myCopy[k],
            skipStart: ('skipStart' in this.mantras[j]) ? this.mantras[j].skipStart : 0
          });
          ix = ix + 1;
        }
      }

      // setup the index
      let temp1;
      temp1 = new FlexSearch({
        tokenize: 'reverse',
         encode: 'icase',
    threshold: 1,
    resolution: 3,
        doc: {
          id: "ix_id",
          field:  ["name" ,"mantra"],
        },
      });
      // console.log(temp);
      //create the actual index
      temp1.add(temp);
      //console.log(temp1.info)
      this.SET_indexMantras(temp1);
      // console.log(temp);
    },
    searchQuery(val) {
      let searchResultsAll = this.indexMantras.search({
        // query:this.convertItrans(word),
        query: val,
        // limit: 5,
        // threshold: 10,
        // resolution: 12,
      //  tokenize: "strict",
      //   depth: 3, 
      //   resolution: 9,
        // encode: "false",
      });
      // console.log('search results')
      // console.log(searchResultsAll);
      this.searchResults = [];
      let searchResultsTemp = [];
      for (var i = 0; i < (searchResultsAll.length > 6 ? 6 : searchResultsAll.length); i++) {
        // console.log(i)
        if (i === 0) {
          searchResultsTemp.push({
            mantra_id: searchResultsAll[i].id,
            verse_id: searchResultsAll[i].verse_id,
          });
          this.searchResults.push(searchResultsAll[i]);
        } else {
          // console.log('in else')
          
          if (searchResultsAll[i - 1].id != searchResultsAll[i].id) {
            // console.log('I am here')
            searchResultsTemp.push({
              mantra_id: searchResultsAll[i].id,
              verse_id: searchResultsAll[i].id,
            });
            this.searchResults.push(searchResultsAll[i]);
          }
        }
      }
      // console.log('search results tmep')
      // console.log(searchResultsTemp)
      // if(searchResultsTemp.length > 0){
      // this.SET_value({ list: searchResultsTemp, id: "searchSelect" });     
      // this.$store.state.parameters.selectedMantra = this.mantras[searchResultsTemp[0].mantra_id].Name      
      // this.SET_value({ list: 0, id: "chantTimesDoneExplore" });      
      // this.SET_value({ list: 11, id: "chantTimesExplore" }); 
      // this.SET_value({ list: 0, id: "currentChantIndexExplore" });
      // this.SET_value({ list: null, id: "myTime1Explore" });
      // }
    },
    findUnique(property) {
      this.$store.state.parameters.browseBy = true
      this.$store.state.parameters.filterSelected = property;
      this.$store.state.parameters.nextFilterOptions = [
        ...new Set(this.mantras.map((a) => a[property])),
      ];
      this.setSearchSelectPreview(this.nextFilterOptions[0])
    },
    setSearchSelectPreview(item) {
      this.$store.state.parameters.nextFilter = item;
      let myIndices = this.mantras
        .filter((a) => a[this.filterSelected] === item)
        .map((b) => {
          return { mantra_id: b.id - 1, verse_id: 0 };
        });
      this.$store.state.parameters.nextNextFilterOptions = myIndices.map(a => this.mantras[a.mantra_id].Name)
      this.setSearchSelect(this.nextNextFilterOptions[0])
    },
    setSearchSelect(item) {
      this.$store.state.parameters.nextNextFilter = item;    
      let myIndices = [{ mantra_id: this.mantras.findIndex(a => a.Name==item), verse_id: 0 }] 
      // let myIndices = this.mantras
      //   .filter((a) => a[this.filterSelected] === item)
      //   .map((b) => {
      //     return { mantra_id: b.id - 1, verse_id: 0 };
      //   });
      // myIndices.map(a => this.mantras[a.mantra_id].Name)
      this.SET_value({ list: myIndices, id: "searchSelect" });
      this.$store.state.parameters.selectedMantra = this.mantras[myIndices[0].mantra_id].Name
      console.log('I was here')
      this.SET_value({ list: 0, id: "chantTimesDoneExplore" });
      this.SET_value({ list: 11, id: "chantTimesExplore" });
      this.SET_value({ list: 0, id: "currentChantIndexExplore" });
      this.SET_value({ list: null, id: "myTime1Explore" });
      // if(this.$router.history.current.path != '/Explore/') this.$router.push('/Explore/')
      if(this.$route.path!="/Explore" && this.$route.path!="/Explore/") {this.$router.push("/Explore/")}
      // console.log(myIndices);
      // console.log(this.mantras)
    },
  }
};
</script>
 <style lang="scss" scoped>
</style>
