<template>
  <div>
    <!-- load the prgress bar if not loaded still
    shoudln't get stuck, but not tested thoroughly -->
    <v-row
      v-if="!loaded & !noDocExists"
      class="fill-height"
      align-content="center"
      justify="center"
    >
      <v-col class="subtitle-1 text-center" cols="12">
        Getting your mantras
      </v-col>
      <v-col cols="6">
        <v-progress-linear
          color="deep-purple accent-4"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </v-row>

    <div v-if="!loaded & noDocExists" class="ma-5">
      <v-row>
        <v-toolbar
          dense
          @click="
            (currentChantIndex > 5) & (chantTimesDone > 0)
              ? (returnDialog = true)
              : goBack()
          "
          v-if="!playON"
        >
          <v-btn icon>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title class="text--secondary"
            >My Playlists</v-toolbar-title
          >
        </v-toolbar>
      </v-row>
      <v-row
        align="center"
        align-content="center"
        class="ma-5"
        justify="center"
      >
        <v-icon x-large> mdi-google-downasaur </v-icon> <br />
        Oops! The playlist doesn't exist. Ask the sender to share the correct
        link.
      </v-row>
    </div>

    <div :style="cssProps" v-if="loaded">
      <!-- explore: Coming soon... -->
      <!-- <recognizeSpeech> </recognizeSpeech> -->

      <!-- load the toolbar with functionality to return back
only show toolbar when not in active play mode -->
      <v-toolbar
        dense
        @click="
          (currentChantIndex > 5) & (chantTimesDone > 0)
            ? (returnDialog = true)
            : goBack()
        "
        v-if="!playON"
      >
        <v-btn icon>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title class="text--secondary">My Playlists</v-toolbar-title>
      </v-toolbar>

      <mediaRecorder
        :currentVerse="currentChantIndex"
        :doRecord="playON"
      ></mediaRecorder>
      <!-- alert when chanting is complete -->
      <v-alert
        v-model="resultProcessed"
        border="left"
        close-text="Close Alert"
        type="success"
        dark
        dismissible
        text
      >
        You chanted <strong> {{ myPlaylistLocal.name }} </strong>
        {{ chantTimes == 1 ? "once." : chantTimes + " times." }}
        {{ resultProcessedMsg }}
      </v-alert>

      <!-- rendering of playlist starts here
      two modes based on cl  -->

      <v-alert
        class="ma-5 px-5"
        color="primary"
        text
        border="top"
        :style="{ whiteSpace: 'pre-line' }"
      >
        <v-row justify="center" class="my-2">
          <span id="myNameTag" class="text-h6">
            {{ myPlaylistLocal.name }}
          </span>
          <v-btn text small rounded>
            <v-icon @click="descriptionOpen = false" v-if="descriptionOpen"
              >mdi-chevron-down</v-icon
            >
            <v-icon @click="descriptionOpen = true" v-if="!descriptionOpen">
              mdi-chevron-up</v-icon
            >
          </v-btn>
        </v-row>

        <v-fade-transition leave-absolute>
          <div v-if="descriptionOpen" class="text-body-2">
            {{ myPlaylistLocal.description }}
          </div>
        </v-fade-transition>
      </v-alert>

      <div v-if="myPlaylistLocal.opening">
        <v-row align-content="center" justify="center">
          <v-alert
            v-if="myPlaylistLocal.opening != ''"
            class="ma-5 px-5"
            color="teal"
            text
            border="top"
            :style="{ whiteSpace: 'pre-line' }"
          >
            {{ myPlaylistLocal.opening }}
          </v-alert>
        </v-row>
      </div>

      <!-- cl=false is for reading mode -->
      <div v-if="!cl">
        <div v-for="(item, i) in myPlaylistLocal.mantras" :key="i">
          <div v-if="myPlaylistLocal.commentsON">
            <v-row
              align-content="center"
              justify="center"
              v-if="myPlaylistLocal.commentsON[i] !== 0"
            >
              <!-- <v-card class="ma-5 pa-2" :style="{ whiteSpace: 'pre-line' }" color="#385F73"
            dark>
                {{ myPlaylistLocal.comments[i] }}
              </v-card> -->

              <v-alert
                class="ma-5 px-5"
                border="left"
                close-text="Close Alert"
                color="info"
                dark
                text
                :style="{ whiteSpace: 'pre-line' }"
              >
                {{ myPlaylistLocal.comments[i] }}
              </v-alert>
            </v-row>
          </div>
          <renderItem
            :mantra="mantras[item - 1]"
            :mantra_id="item"
            :script="script"
            :mantras="mantras"
            skipLinkto
          >
          </renderItem>
        </div>
      </div>

      <!-- cl=true is for chanting mode  -->
      <div v-if="cl">
        <div
          v-for="(item, i) in myPlaylistLocal.mantras"
          :key="i"
          class="wrapper"
        >
          <div v-if="myPlaylistLocal.commentsON" :id="`comment${i + 1}`">
            <v-row
              align-content="center"
              justify="center"
              v-if="myPlaylistLocal.commentsON[i] !== 0"
            >
              <v-alert
                class="ma-5 px-5"
                border="left"
                close-text="Close Alert"
                color="info"
                dark
                text
                :style="{ whiteSpace: 'pre-line' }"
              >
                {{ myPlaylistLocal.comments[i] }}
              </v-alert>
            </v-row>
          </div>
          <renderItem
            :id="`chant${i + 1}`"
            :mantra="mantras[item - 1]"
            :mantra_id="item"
            :script="script"
            :itemIndex="i + 1"
            skipInfo
            secondScriptON
            translationON
            zoomON
            parallelON
            :mantras="mantras"
          >
          </renderItem>
        </div>
      </div>

      <!-- closing comments -->
      <div v-if="myPlaylistLocal.closing">
        <v-row align-content="center" justify="center">
          <v-alert
            v-if="myPlaylistLocal.closing != ''"
            class="ma-5 px-5"
            border="bottom"
            color="teal"
            text
            :style="{ whiteSpace: 'pre-line' }"
          >
            {{ myPlaylistLocal.closing }}
          </v-alert>
        </v-row>
      </div>

      <!-- add the play/pause/chantIcon button -->
      <v-fab-transition>
        <chantingSettings Playlist v-if="!playON"></chantingSettings>
      </v-fab-transition>
      <v-fab-transition>
        <v-btn
          color="purple darken-3"
          fab
          dark
          :small="$vuetify.breakpoint.width < 800 ? true : false"
          fixed
          bottom
          left
          @click="myPlayButtonFun()"
        >
          <v-icon v-if="!cl">mdi-account-voice</v-icon>
          <v-icon v-if="cl && !playON">mdi-play</v-icon>
          <v-icon v-if="cl && playON">mdi-pause</v-icon>
        </v-btn>
      </v-fab-transition>

      <!-- the start/restart/resume dialog with
the option to chant the number of times option
do conditional rendering of start/restart/resume -->
      <v-dialog v-model="playDialog" max-width="375px">
        <v-card outlined rounded ref="form">
          <v-card-text>
            <v-container>
              <v-row justify="space-between" align="center" class="mb-2">
                <span class="text--disabled"> Power Mantra </span>
                <v-btn icon @click="playDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-row>

              <v-row justify="space-between" align="center">
                <span class="text-subtitle-1">
                  Chant this playlist
                  <span class="info--text">{{
                    chantTimes == 1 ? "once" : chantTimes + " times"
                  }}</span
                  >:</span
                >
                <v-radio-group v-model="chantTimes" row class="tighter">
                  <v-radio
                    v-for="(nn, n) in chantTimesOptions"
                    :key="n"
                    :label="nn + ''"
                    :value="nn"
                  ></v-radio>
                </v-radio-group>
              </v-row>
              <v-divider></v-divider>
              <v-row class="mt-4">
                <span class="text-subtitle-1" v-if="myTime1 != null">
                  Resume or Restart from beginning?
                </span>
              </v-row>
              <v-row justify="end" class="mt-5">
                <v-card-actions>
                  <v-btn
                    class="mr-5"
                    color="green darken-3"
                    @click="playDialogFun('restart')"
                    dark
                  >
                    {{ myTime1 == null ? "Start" : "Restart" }}
                  </v-btn>
                  <v-btn
                    color="red darken-3"
                    dark
                    @click="playDialogFun('resume')"
                    v-if="myTime1 != null"
                  >
                    Resume
                  </v-btn>
                </v-card-actions>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- exit screen dialog -->
      <v-dialog v-model="returnDialog" max-width="250px">
        <v-card outlined rounded ref="form">
          <v-card-text>
            <v-container>
              <v-row justify="space-between" align="center" class="mb-2">
                <span class="text--disabled"> Power Mantra </span>
                <v-btn icon @click="returnDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-row>

              <v-row class="mt-4">
                <span class="text-subtitle-1"> Done with this session? </span>
              </v-row>
              <v-row justify="end" class="mt-5">
                <v-card-actions>
                  <v-btn
                    class="mr-5"
                    outlined
                    @click="returnDialog = false"
                    dark
                  >
                    Cancel
                  </v-btn>
                  <v-btn color="info darken-5" dark @click="goBack()">
                    Yes
                  </v-btn>
                </v-card-actions>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
// import MusicTempo from 'music-tempo'
import renderItem from "@/components/explore/render-item";
// import chantingTimer from "@/components/explore/chanting-timer";
import chantingSettings from "./subcomponents/chanting-settings";
import { mapState, mapMutations } from "vuex";
import { db, auth } from "@/main.js";
import firebase from "firebase/app";
import "firebase/firestore";
import mediaRecorder from "./subcomponents/media-recorder";
// var Statistics = require("statistics.js");
export default {
  props: {
    ownedPlaylists: Array,
    sharedPlaylists: Array,
    mySharedList: Object,
  },
  data() {
    return {
      myPlaylistLocal: null,
      loaded: false,
      myEndHere: false,
      myTimer: null,
      playDialog: false,
      returnDialog: false,
      chantTimesOptions: [1, 3, 11, 108, 1008], //needs fixing with computed property (also in unauth version)
      resultProcessed: false,
      resultProcessedMsg: "",
      noDocExists: false,
      descriptionOpen: true,
    };
  },
  components: {
    renderItem,
    // chantingTimer,
    chantingSettings,
    mediaRecorder,
  },
  mounted() {
    // console.log(MusicTempo)
    setTimeout(() => {
      this.jumpToCurrentLocation("byElement");
    }, 500);
    setTimeout(() => {
      // this.$store.state.firestore.selfURL = false

      //check for pl in owned lists
      //if success then return the playlist
      if (this.ownedPlaylists != null) {
        if (this.ownedPlaylists.length > 0) {
          let ownedTemp = this.ownedPlaylists.find((a) => a.tag == this.pl);
          if (ownedTemp !== undefined) {
            this.myPlaylistLocal = ownedTemp;
            this.loaded = true;
            // console.log('I got it from ownedPlaylists')
          }
        }
      }

      if (!this.loaded) {
        //check for pl in sharedlist
        //if success then return the playlist
        if (this.sharedPlaylists != null) {
          if (this.sharedPlaylists.length > 0) {
            let sharedTemp = this.sharedPlaylists.find((a) => a.tag == this.pl);
            if (sharedTemp !== undefined) {
              this.myPlaylistLocal = sharedTemp;
              this.loaded = true;
              // console.log('I got it from sharedPlaylists')
            }
          }
        }
        // console.log(this.mySharedList)

        if (!this.loaded) {
          //get pl once through the collectiongroup query
          //if success then
          db.collectionGroup("playlists")
            .where("tag", "==", this.pl)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                // console.log(doc.id, " => ", doc.data());
                this.myPlaylistLocal = doc.data();
                // console.log('I got it from querySnapshot')
                // console.log(this.myPlaylistLocal)
              });
              if (this.myPlaylistLocal.tag == this.pl) {
                if (this.mySharedList == null) {
                  if (
                    auth.currentUser.displayName != this.myPlaylistLocal.owner
                  ) {
                    //update mySharedlist
                    db.collection("userdata")
                      .doc(auth.currentUser.uid)
                      .collection("track")
                      .doc("mysharedlist")
                      .set({
                        tag: [this.pl],
                      })
                      .then(() => {
                        //fire a new collectiongroup query to sync sharedPlaylists
                        // console.log(this.mySharedList + " add")
                        this.bindToFirestoreSharedAuth("sharedPlaylists");
                        this.loaded = true;
                      });
                  } else {
                    this.loaded = true;
                  }
                } else {
                  if (
                    auth.currentUser.displayName != this.myPlaylistLocal.owner
                  ) {
                    //update mySharedlist
                    db.collection("userdata")
                      .doc(auth.currentUser.uid)
                      .collection("track")
                      .doc("mysharedlist")
                      .update({
                        tag: firebase.firestore.FieldValue.arrayUnion(this.pl),
                      })
                      .then(() => {
                        //fire a new collectiongroup query to sync sharedPlaylists
                        // console.log(this.mySharedList +  " update")
                        this.bindToFirestoreSharedAuth("sharedPlaylists");
                        this.loaded = true;
                      });
                  } else {
                    this.loaded = true;
                  }
                }
              }
              //return the pl obtained through the first collection group query
              //return querySnapshot[0].data();
            })
            .catch(() => {
              this.noDocExists = true;
            });
        }
      }
    }, 50);
  },
  computed: {
    ...mapState("parameters", [
      "pl",
      "authenticated",
      "cl",
      "script",
      "playON",
      "currentChantIndex",
      "myTimeStart",
      "myTime0",
      "myTime",
      "myTime1",
      "myTime1Offset",
      "myItemTracker",
      "myItemTrackerSave",
      "chantTimes",
      "chantTimesDone",
      "resultReady",
    ]),
    ...mapState("coretext", ["mantras"]),
    cssProps() {
      // console.log(this.myPlaylistLocal);
      return {
        "--offset":
          this.$vuetify.breakpoint.width < 1050
            ? "5px"
            : (this.$vuetify.breakpoint.width - 1050) / 2 + "px",
        "--center": this.$vuetify.breakpoint.width / 2 - 5 + "px",
      };
    },
    chantTimes: {
      get() {
        return this.$store.state.parameters.chantTimes;
      },
      set(value) {
        this.SET_value({ list: value, id: "chantTimes" });
      },
    },
  },
  methods: {
    ...mapMutations("parameters", ["SET_value"]),
    playDialogFun(val) {
      if (val == "restart") {
        this.SET_value({ list: 0, id: "currentChantIndex" });
        let dt = new Date();
        this.$store.state.parameters.myTimeStart = Math.ceil(dt.getTime());
        this.$store.state.parameters.myTime0 = Math.ceil(dt.getTime());
        this.SET_value({ list: 0, id: "myTime1Offset" });
        this.SET_value({ list: [], id: "myTime" });
        this.SET_value({ list: 0, id: "chantTimesDone" });
        this.SET_value({ list: true, id: "playON" });
        this.SET_value({ list: false, id: "resultReady" });
      } else {
        let dt = new Date();
        this.$store.state.parameters.myTime0 = Math.ceil(dt.getTime());
        this.SET_value({ list: true, id: "playON" });
      }
      this.playDialog = false;
      // console.log(this.currentChantIndex);
      this.jumpToCurrentLocation("byElement");
      return;
    },
    myPlayButtonFun() {
      if (this.myItemTracker.length == 0) {
        console.log("I am here");
        for (
          let index = 0;
          index < this.myPlaylistLocal.mantras.length;
          index++
        ) {
          let mlen =
            this.mantras[this.myPlaylistLocal.mantras[index] - 1].mantra.length;
          for (let index1 = 0; index1 < mlen; index1++) {
            this.$store.state.parameters.myItemTracker.push(
              "ID_" + "item" + (index + 1) + "verse" + index1
              // "i" + (index + 1) + "v" + index1
            );
            this.$store.state.parameters.myItemTrackerSave.push(
              "m" + this.myPlaylistLocal.mantras[index] + "v" + index1
            );
          }
        }
      }
      if (!this.cl) {
        this.SET_value({ list: true, id: "cl" });
        this.SET_value({ list: false, id: "playON" });
        // this.playON = false;
        return;
      } else if (!this.playON) {
        this.playDialog = true;
      } else {
        this.SET_value({ list: false, id: "playON" });
      }
      return;
    },
    goBack() {
      this.SET_value({ list: false, id: "playON" });
      this.SET_value({ list: 0, id: "currentChantIndex" });
      this.SET_value({ list: null, id: "myTime0" });
      this.SET_value({ list: null, id: "myTime1" });
      this.SET_value({ list: 0, id: "myTime1Offset" });
      this.SET_value({ list: [], id: "myTime" });
      this.SET_value({ list: [], id: "myItemTracker" });
      this.SET_value({ list: [], id: "myItemTrackerSave" });
      this.SET_value({ list: 1, id: "chantTimes" });
      this.SET_value({ list: 0, id: "chantTimesDone" });
      this.SET_value({ list: false, id: "resultReady" });
      this.$router.push("/Library");
    },
    //this needs to be handled differently from the unauthenticated case
    bindToFirestoreSharedAuth(value) {
      // if (this[value] == null) {
      if (this.mySharedList != null) {
        if (this.mySharedList.tag.length > 0) {
          // console.log("I am in bindToFireStore " + value);
          this.$store.dispatch("firestore/bindUserdata", {
            path: "playlists",
            type: "collectionGroup",
            query: ["tag", "in", this.mySharedList.tag],
            whereToBind: value,
          });
          // console.log('I will bind'+this.mySharedList.tag)
        } else {
          console.log(value + " nothing to bind yet");
        }
      } else {
        console.log(value + " mySharedList not bound yet");
      }
      // console.log(value + " already bound");
    },
    bindToFirestoreOwned(value) {
      if (this[value] == null) {
        // console.log("I am in bindToFireStore " + value);
        this.$store.dispatch("firestore/bindUserdata", {
          path: "/userdata/" + auth.currentUser.uid + "/playlists",
          type: "collection",
          whereToBind: value,
        });
      } else {
        console.log(value + " already bound");
      }
    },
    jumpToCurrentLocation(val) {
      //we don't want to jump if index is 0
      //we jump in two modes: by element or by caculating the px numbers
      //in one-by-one clicking the latter is preffered for a smooth scrolling
      //when we are at the top of the page the former is preffered
      if (this.currentChantIndex >= 1) {
        if (val == "byElement") {
          if (
            //if the scrollHeight is too large then the later elements are not rendered/identified
            //scroll to the bottom of the page in that case
            document.getElementById(
              this.myItemTracker[this.currentChantIndex]
            ) == null
          ) {
            this.$vuetify.goTo(document.body.scrollHeight - 500, {
              duration: 300,
              offset: 0,
              easing: "linear",
            });
          } else {
            this.$vuetify.goTo(
              "#" + this.myItemTracker[this.currentChantIndex],
              {
                duration: 300,
                offset: 100,
                easing: "linear",
              }
            );
          }
          return;
        } else {
          // if (this.currentChantIndex < this.myPlaylistLocal.mantras.length) {
          if (this.currentChantIndex < this.myItemTracker.length) {
            //addOffset is the px we are not counting
            //48 is the dense toolbar height in px
            //in verse mode offset of 68 is needed
            //this was identified through "select an element" in the developers tool
            let addOffset =
              this.myItemTracker[this.currentChantIndex].split("verse")[1] ==
              "0"
                ? 68
                : 0;
            let myTop = window.pageYOffset;
            let el = document.getElementById(
              this.myItemTracker[this.currentChantIndex]
            );
            // console.log(el)
            // console.log(this.myItemTracker)
            // console.log(this.myItemTracker[this.currentChantIndex].split('verse')[1])
            let style = window.getComputedStyle(el);
            //this was the earlier dimension requirement, but now just height is enough
            //with the addOffset
            //  let height = ["height", "padding-top", "padding-bottom", "margin-top"]
            let height = ["height"]
              .map((key) => parseInt(style.getPropertyValue(key), 10))
              .reduce((prev, cur) => prev + cur);
            //check for comment and determine the comment height
            let heightComment = 0;
            if (
              this.myItemTracker[this.currentChantIndex].split("verse")[1] ==
              "0"
            ) {
              let tempelIdx = this.myItemTracker[this.currentChantIndex]
                .split("verse")[0]
                .split("item")[1];
              el = document.getElementById("comment" + parseInt(tempelIdx));
              style = window.getComputedStyle(el);
              heightComment = ["height"]
                .map((key) => parseInt(style.getPropertyValue(key), 10))
                .reduce((prev, cur) => prev + cur);
              //  if(heightComment>0) {
              //  heightComment = heightComment - 8
              //  }
              // console.log(el)
              // console.log(heightComment)
            }
            this.$vuetify.goTo(
              myTop + (height + 48 + addOffset) + heightComment,
              {
                duration: 500,
                offset: 0,
                easing: "easeInOutCubic",
              }
            );
            return;
          }
        }
      } else {
        // console.log("I am here");
        this.$vuetify.goTo(0, {
          duration: 100,
          offset: 0,
          easing: "linear",
        });
        return;
      }
    },
    saveMyResult() {
      let dt = new Date();
      let endTime = Math.ceil(dt.getTime());
      db.collection("userdata")
        .doc(auth.currentUser.uid)
        .collection("records")
        .add({
          name: this.myPlaylistLocal.name,
          tag: this.myPlaylistLocal.tag,
          verses: this.myItemTrackerSave,
          owner: this.myPlaylistLocal.owner,
          timeline: this.myTime,
          start: this.myTimeStart,
          end: endTime,
        });
      this.processMyResults();
      // this.nameBox = false;
      // this.dialog = false;
      // this.$emit("saveSuccess", this.playlistName.trim());
    },
    processMyResults() {
      this.resultProcessed = true;
      this.resultProcessedMsg = "Score=x, Speed=y.";
      // let dataArray = [];
      // let tempArray = [];
      // for (let index = 0; index < this.myItemTrackerSave.length; index++) {
      //   tempArray = [];
      //   for (
      //     let index1 = 0;
      //     index1 < this.myTime.length / this.myItemTrackerSave.length;
      //     index1++
      //   ) {
      //     tempArray.push(
      //       this.myTime[index + this.myItemTrackerSave.length * index1]
      //     );
      //   }
      //   dataArray.push({ ...tempArray });
      // }
      // console.log(dataArray);
      // console.log(this.myTime);

      // var data = [
      //   { ID: 1, age: 33 },
      //   { ID: 2, age: 42 },
      //   { ID: 3, age: 27 },
      // ];

      // var columns = {
      //   ID: "ordinal",
      //   age: "interval",
      // };

      // var settings = {};

      // var stats = new Statistics(data, columns, settings);

      // var meanAge = stats.arithmeticMean("age");
      // var stdDevAge = stats.standardDeviation("age");
      // var tmp = stats.sum("age");
      // console.log(meanAge, stdDevAge, tmp)
      // columns = {...Array(this.myTime.length / this.myItemTrackerSave.length).fill('metric')}
      // data = dataArray
      // stats = new Statistics(data, columns, settings);
      // console.log(stats.correlationCoefficient('0', '1').correlationCoefficient)
    },
  },
  watch: {
    currentChantIndex: function () {
      this.jumpToCurrentLocation();
    },
    resultReady: function () {
      if (this.resultReady) {
        console.log("I am here");
        this.saveMyResult();
        this.SET_value({ list: 0, id: "myTimeStart" });
        this.SET_value({ list: false, id: "resultReady" });
      }
    },
  },
};
</script>

 <style lang="scss" scoped>
.v-btn--fixed.v-btn--top {
  top: 48px;
}
.v-btn--fixed.v-btn--bottom {
  bottom: 60px;
}
.v-btn--fixed.v-btn--bottom.moveUp1 {
  bottom: 105px;
}
.v-btn--fixed.v-btn--right {
  right: var(--offset);
}
.v-btn--fixed.v-btn--left {
  left: var(--offset);
}
.wrapper {
  position: relative;
}
.overlap {
  position: absolute;
  bottom: 15px;
  right: 15px;
}
html {
  scroll-behavior: smooth;
}
.fixed {
  position: fixed;
  top: 60px;
  right: 100px;
  width: 100px;
}
.tighter .v-radio {
  padding: 0px;
  margin: 4px;
}
.tighter [class*="__ripple"] {
  left: 0;
}
</style>