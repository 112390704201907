<template>
  <div>
              <v-dialog v-model="deleteDialog" max-width="250px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"                                        
                    icon x-small                    
                    @click="deleteDialog = true"
                  >
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </template>
                <v-card outlined rounded ref="form">
                  <v-card-text>
                    <v-container>
                      <v-row justify="space-between" align="center">
                        <span class="text-subtitle-1"> Power Mantra </span> <v-btn
                          icon
                          @click="deleteDialog = false"
                          dark
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-row> 
                      <div class="text-subtitle-1 mt-5">
                        Remove this mantra from the playlist? 
                      </div>
                      <v-card-actions>                        
                        <v-btn
                          color="green darken-1"
                          @click="deleteDialog = false"
                          dark
                        >
                          NO
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="red darken-1"                          
                          dark
                          v-on:click="$emit('deleteConfirmed', index), deleteDialog=false"
                        >
                          YES
                        </v-btn>                        
                      </v-card-actions>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-dialog>
  </div>
</template>


<script>
export default {
  props: {
    index: Number,
  },
  data() {
    return {
      deleteDialog: false
  }
  },
  components: {},
  computed: {},
  methods: {},
}
</script>
 <style lang="scss" scoped>
 
</style>