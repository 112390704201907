const state = {  
  ownedPlaylists: null,  
  ownedRecords: null,  
  ownedRecordsUnauth: null,  
  ownedPlaylistsAuth: null,  
  ownedRecordsAuth: null,    
};

export default {
  namespaced: true,
  state,
  }
  